import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import {
  CustomNextArrow,
  CustomPrevArrow,
} from "../../common_component/testimonial/testimonial";
import ViewAll from "./viewAll_Student";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setShowCurrentStudentList } from "../../../redux/reducers/collage_dashboard_reducer";
import ClipLoader from "react-spinners/ClipLoader";
import dummy_logo from "../../../utils/landingpage/images/user-icon-dummy.png";

export default function CampusManagerStudent() {
  const token = sessionStorage.getItem("accessToken");
  const [showAll, setShowAll] = useState(false); // State to toggle between showing all profiles or just a few
  const [showAllStudentData, setShowAllStudentData] = useState(false); // State to toggle between showing all profiles or just a few
  const [showCreateGroup, setShowCreateGroup] = useState(false); // State to toggle between components
  const [profiles, setProfiles] = useState([]);
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch();
  const ShowCurrentStudentListState = useSelector((state) => state.CollageDashboard.showCurrentStudentList);

  useEffect(() => {
    const getuserdetails = async () => {
      try {
        setLoading(true)
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/show_student_campus_by_college/?is_approved=true`,
          {
            headers: { Authorization: `Bearer ${token}` }, // Pass the token correctly
          }
        );

        if (response.data) {
          setProfiles(response.data);
        } else {
          console.error("Unexpected response format", response);
          toast.error("Failed to fetch profiles");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch user details");
      }
      finally {
        setLoading(false)
      }
    };

    getuserdetails();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7, // Adjust based on needs
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    appendDots: (dots) => (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "20px" }}
      >
        <button
          className="view-all-button"
          onClick={() => setShowAll(!showAll)}
        >
          {"View All"}
        </button>
      </div>
    ),
  };

  const handleCreateGroupClick = () => {
    setShowCreateGroup(true);
  };

  const handleBackClick = () => {
    setShowCreateGroup(false);
  };

  if (showCreateGroup) {
    return <CreateGroup onBack={handleBackClick} />;
  }

  return (
    <>
      {ShowCurrentStudentListState === false && (
        <div className="_main_content_inner_screen">
          <div className="_main_content_inner_screen_conatiner">
            <div className="csd">
              <div className="csd-left">
                <h5>Create Group of Student and Alumni</h5>

                <div className="csd-buttons-container">
                  <button>Organization</button>
                  <button>Networking</button>
                </div>
                <p>Organize candidates for specific roles</p>
              </div>

              <div className="csd-right">
                <button
                  className="csd-create-group"
                  onClick={handleCreateGroupClick}
                >
                  Create Group
                </button>
              </div>
            </div>

            {loading ? (
              <div className="custom_loader">
                <ClipLoader color="#F38D00" size={50} />
              </div>
            ) : (<div className="carousel-container">
              <h5 style={{ padding: "1rem" }}>Current Students</h5>

              {profiles && profiles?.length > 0 ? (
                <Slider {...settings}>
                  {profiles.map((item) => (
                    <div
                      key={item.id}
                      id="pofile-card-new-id-uniqe"
                      style={{ display: "grid", alignItems: "center" }}
                      className="profile-card"
                    >
                      <img
                        src={
                          item.studentcampus_profile?.student_photo_url || dummy_logo
                        } // Fallback if no photo
                        alt={item.name}
                        className="profile-image"
                      />
                      <div className="profile-info">
                        <p className="profile-name">{item.name + " " + item.student_last_name}</p>
                        <p className="profile-designation">
                          {item.designation}
                        </p>
                      </div>
                    </div>
                  ))}
                </Slider>
              )
                : (
                  <b className="no_data_found">No Current Students</b>
                )
              }

              {profiles?.length > 0 && (<div className="view-all-container">
                <button
                  className="view-all-button"
                  onClick={() => dispatch(setShowCurrentStudentList(true))}
                >
                  View All
                </button>
              </div>)}
            </div>)}

            <div className="group-images-section">
              <div className="alumni-network">
                <h5>Alumni Groups</h5>
                <p>Join alumni network to stay connected</p>
              </div>

              <div className="group-images-container">
                <div className="group-image-item">
                  <h6>Marketing</h6>
                  <a href=".." className="group-image-link">
                    <img
                      src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                      alt="Marketing Group"
                    />
                  </a>
                  <p className="group-image-date">
                    {" "}
                    <p>Tech Reunion</p> 01/08/2024
                  </p>
                </div>
                <div className="group-image-item">
                  <h6>Marketing</h6>
                  <a href=".." className="group-image-link">
                    <img
                      src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                      alt="Marketing Group"
                    />
                  </a>
                  <p className="group-image-date">
                    {" "}
                    <p>Tech Reunion</p> 01/08/2024
                  </p>
                </div>
                <div className="group-image-item">
                  <h6>Marketing</h6>
                  <a href="..." className="group-image-link">
                    <img
                      src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                      alt="Marketing Group"
                    />
                  </a>
                  <p className="group-image-date">
                    {" "}
                    <p>Tech Reunion</p> 01/08/2024
                  </p>
                </div>
                {/* ... (more group images) */}
              </div>
            </div>
          </div>
        </div>
      )}

      {ShowCurrentStudentListState === true && <ViewAll />}
    </>
  );
}

function CreateGroup({ onBack }) {
  const [groupName, setGroupName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [manager, setManager] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    // Logic to handle form submission, such as API call
    console.log("Group Created:", {
      groupName,
      description,
      selectedMembers,
      manager,
    });
  };

  const handleCancel = () => {
    // Logic to handle form cancellation, such as navigating away or clearing form fields
    console.log("Form Cancelled");
    setGroupName("");
    setDescription("");
    setSelectedMembers([]);
    setManager("");
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="create-group-page">
        <div className="group-header">
          <button onClick={onBack} className="back-arrow">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M19 12H5m7-7l-7 7 7 7" />
            </svg>
          </button>
          <h5>Create a New Group</h5>
        </div>

        <div className="create-group-container">
          <form onSubmit={handleSubmit} className="create-group-form">
            <div className="form-sections">
              <div className="form-left">
                <div className="form-group">
                  <input
                    type="text"
                    id="group-name"
                    value={groupName}
                    placeholder="Group Name"
                    onChange={(e) => setGroupName(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <select
                    id="members"
                    value={selectedMembers}
                    onChange={(e) => setSelectedMembers(e.target.value)}
                    required
                  >
                    <option value="Member1">Select Group Member</option>
                    <option value="Member2">Ram</option>
                    <option value="Member3">Shyam</option>
                    <option value="Member4">Rahul</option>
                    {/* Add more options as needed */}
                  </select>
                </div>
              </div>

              <div className="form-right">
                <div className="form-group">
                  <input
                    id="description"
                    value={description}
                    placeholder="Group Description"
                    onChange={(e) => setDescription(e.target.value)}
                    required
                  />
                </div>

                <div className="form-group">
                  <input
                    type="text"
                    id="manager"
                    value={manager}
                    placeholder="Designate Group Manager"
                    onChange={(e) => setManager(e.target.value)}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-actions">
              <button type="submit" className="submit-button">
                Create Group
              </button>
              <button
                type="button"
                className="csd-cancel-button"
                onClick={handleCancel}
              >
                Cancel
              </button>
            </div>
          </form>
        </div>

        <div className="gallery-container">
          <h5>Alumni Network Showcase</h5>
          <div className="gallery-items">
            <div className="gallery-items">
              <div className="gallery-item">
                <a href="..." className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Senior Software</p>
              </div>
              <div className="gallery-item">
                <a href="..." className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Data Analyst</p>
              </div>
              <div className="gallery-item">
                <a href="..." className="gallery-link">
                  <img
                    src="https://i.postimg.cc/pLyfQ369/eimg.jpg"
                    alt="Marketing Group"
                  />
                </a>
                <p className="gallery-date">Developer at TCS</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}