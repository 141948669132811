import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import "../../../styles/dashboards/clgdashboard/campusmanagerinvitation.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import flterIcon from "../../../utils/dashboards/filterIcon.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-spinners/ClipLoader";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";

export default function CampusManagerJobs() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const token = sessionStorage.getItem("accessToken");
  const [jobs, setJobs] = useState([]);
  const [rejectedJobs, setRejectedJobs] = useState([]);
  const [accepting, setAccepting] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingJobId, setLoadingJobId] = useState(null); // Track which job is loading
  const [filter, setFilter] = useState("all"); 

  const jobsPerPage = 6;
  const navigate = useNavigate();

  const handleAccept = async (jobID) => {
    setAccepting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/accept_invitation/`,
        { job_id: jobID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchinvitationjobs();
      toast.success("Successfully accepted the invitation!");
    } catch (error) {
      console.error("Error accepting invitation:", error);
      toast.error("Failed to accept the invitation.");
    } finally {
      setAccepting(false);
    }
  };

  const handleReApprove = async (jobID) => {
    setAccepting(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api//jobs/reaccept_invitation/`,
        { job_id: jobID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchRejectedinvitationjobs();
      toast.success("Successfully accepted the invitation!");
    } catch (error) {
      console.error("Error accepting invitation:", error);
      toast.error("Failed to accept the invitation.");
    } finally {
      setAccepting(false);
    }
  }

  const handleReject = async (jobID) => {
    setLoadingJobId(jobID);
    try {
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/reject_invitation/`,
        { job_id: jobID },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      fetchinvitationjobs();
      fetchRejectedinvitationjobs()
      toast.success("Successfully rejected the invitation!");
    } catch (error) {
      console.error("Error rejecting invitation:", error);
      toast.error("Failed to reject the invitation.");
    } finally {
      setLoadingJobId(null);
    }
  };

  const fetchinvitationjobs = async () => {
    try {
      setLoadingJobId(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/my_invitations/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setJobs(response.data);
      console.log("---jobs data--", response.data);
    } catch (error) {
      console.error("Error fetching jobs:", error);
    } finally {
      setLoadingJobId(null);
    }
  };

  const fetchRejectedinvitationjobs = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/rejected_invitations_list/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRejectedJobs(response.data?.rejected_invitations);
    } catch (error) {
      toast.error("Error fetching rejected jobs");
    }
  };

  useEffect(() => {
    fetchinvitationjobs();
    fetchRejectedinvitationjobs();
  }, []);

  // const filteredJobs =
  //   filter === "all"
  //     ? jobs
  //     : filter === "rejected"
  //       ? rejectedJobs
  //       : jobs;
  const filteredJobs =
    filter === "all"
      ? [...jobs, ...rejectedJobs]
      : filter === "rejected"
        ? rejectedJobs
        : jobs;


  const searchedJobs = filteredJobs?.filter(
    (job) =>
      job.company.toLowerCase().includes(search.toLowerCase()) ||
      job.job_title.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastJob = page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = searchedJobs?.slice(indexOfFirstJob, indexOfLastJob);

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
    setPage(1);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleJobClick = (job) => {
    navigate(`/dashboard/sidebar-page/clg-manager/job-details/${job}`);
  };

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
    setPage(1); // Reset pagination
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="all-job-header">
          <h5>{`All Invitations[${filteredJobs?.length || 0}]`}</h5>
          <div className="all-job-header-right">
            <div className="all-jobs-search-input-div">
              <img src={searchIconDash} alt="Search Icon" />
              <input
                type="text"
                placeholder="Search"
                value={search}
                onChange={handleSearchChange}
                className="all-job-search-input"
              />
            </div>
            <div className="campuse_student_request_dropdown">
              <img src={flterIcon} alt="Filter Icon" />
              <select
                className="campuse_student_request_dropdown_section"
                value={filter}
                onChange={handleFilterChange}
              >
                <option value="all">All ▾</option>
                <option value="pending">Pending</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
          </div>
        </div>
        {loading ? (
          <div className="custom_loader">
            <Loader color="#F38D00" size={50} />
          </div>
        ) : (
          <div className="job-list">
            {currentJobs?.length === 0 ? (
              <h3 className="nojobsfound">No Invitations Found</h3>
            ) : (
              currentJobs?.map((job, index) => (
                <div key={index} className="job-item">
                  <div
                    className="job-left"
                    onClick={() => handleJobClick(job?.job_id)}
                  >
                    <img
                      src={
                        dummy_logo
                      }
                      alt={job.company}
                      className="company-logo"
                    />
                    <div className="job-info">
                      <h6>{job.company_name || "Not Mentioned"}</h6>
                      <p>
                        {job.job_title ? job.job_title : "Not Mentioned"}
                      </p>
                    </div>
                  </div>
                  {job?.rejected === false && (<div className="job-right_invitations">
                    <button
                      onClick={() => handleAccept(job.job_id)}
                      className="accept_accept_invitations"
                      disabled={accepting || loadingJobId}
                    >
                      {loadingJobId === job.job_id ? <div className="spinner"></div> : "Accept"}
                    </button>
                    <button
                      onClick={() => handleReject(job.job_id)}
                      className="accept_reject_invitations"
                      disabled={accepting || loadingJobId}
                    >
                      {loadingJobId === job.job_id ? <div className="spinner"></div> : "Reject"}
                    </button>
                  </div>)}

                  {job?.rejected === true && (<div className="job-right_invitations">
                    <button
                      onClick={() => handleReApprove(job.job_id)}
                      className="accept_accept_invitations"
                      disabled={accepting || loadingJobId}
                    >
                      {loadingJobId === job.job_id ? <div className="spinner"></div> : "Re-Approve"}
                    </button>
                  </div>)}

                </div>
              ))
            )}
          </div>
        )}
        <div className="pagination-container pagination_custom">
          <Pagination
            count={Math.ceil(searchedJobs?.length / jobsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
            className="mt-3"
            renderItem={(item) => (
              <PaginationItem
                components={{
                  previous: () => <img src={leftArrowIcon} alt="prev" />,
                  next: () => <img src={rightIconArrow} alt="next" />,
                }}
                {...item}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
