import { useState, useEffect } from "react";
import { Pagination } from "@mui/material";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import searchIconDash from "../../../../utils/dashboards/searchIconDash.svg";
import leftArrow from "../../../../utils/dashboards/leftarrow.svg";
import "../../../../styles/dashboards/hrdashboard/circulateJob.css";
import "../../../../styles/dashboards/hrdashboard/postjobs.css";
export default function JobsCirculate() {
  const [collegeList, setCollegeList] = useState([]);
  const [circulateJobData, setCirculateJobData] = useState(null);
  const [isFocused, setIsFocused] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedColleges, setSelectedColleges] = useState([]);
  const [loading, setLoading] = useState(false); // Loader state
  const [searchQuery, setSearchQuery] = useState(""); // New state for search query
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const clgPerPage = 4;
  const base_Url = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    const fetchColleges = async () => {
      try {
        const response = await axios.get(`${base_Url}/api/show_college/`);
        console.log(response.data);
        setCollegeList(response.data);
      } catch (error) {
        console.error("Error fetching college data:", error);
      }
    };

    const storedData = localStorage.getItem("circulateJobData");
    if (storedData) {
      setCirculateJobData(JSON.parse(storedData));
    }

    fetchColleges();
  }, [base_Url]);

  // Filter colleges based on search query
  const filteredColleges = collegeList.filter(
    (college) =>
      college.college_api && // Check if college_api exists
      college.college_api.college_name && // Check if college_name exists
      college.college_api.college_name
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  // Pagination Logic
  const indexOfLastClg = currentPage * clgPerPage;
  const indexOfFirstClg = indexOfLastClg - clgPerPage;
  const currentColleges = filteredColleges.slice(
    indexOfFirstClg,
    indexOfLastClg
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleNavigate = () => {
    navigate(-1);
  };

  const handleCheckboxChange = (email) => {
    setSelectedColleges((prevSelected) =>
      prevSelected.includes(email)
        ? prevSelected.filter((col) => col !== email)
        : [...prevSelected, email]
    );
  };

  const handleSelectAll = () => {
    const allSelected = selectedColleges.length === currentColleges.length;

    if (allSelected) {
      setSelectedColleges((prevSelected) =>
        prevSelected.filter(
          (col) => !currentColleges.some((college) => college.email === col)
        )
      );
    } else {
      const newSelected = currentColleges
        .filter((college) => !selectedColleges.includes(college.email))
        .map((college) => college.email);

      setSelectedColleges((prevSelected) => [...prevSelected, ...newSelected]);
    }
  };

  const handleCirculateJob = async () => {
    if (selectedColleges.length === 0) {
      alert("Please select at least one college to circulate the job.");
      return;
    }

    setLoading(true); // Start loader

    const jobData = {
      id: circulateJobData.id,
      colleges: selectedColleges,
    };
    try {
      const response = await axios.post(
        `${base_Url}/api/jobs/${jobData.id}/circulate/`,
        jobData,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const swalConfig = {
        text: response.data.detail,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      };

      if (response.status === 201) {
        await Swal.fire({
          ...swalConfig,
          title: "Success!",
          icon: "success",
        });
      } else if ([200, 401, 403].includes(response.status)) {
        await Swal.fire({
          ...swalConfig,
          title: "Info",
          icon: "info",
        });
      }

      setSelectedColleges([]);
    } catch (error) {
      console.error("Error circulating job:", error);
      await Swal.fire({
        title: "Error",
        text: "Failed to circulate job. Please try again.",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="d-flex flex-column p-4 gap-4">
          <div className="d-flex align-items-center">
            <img
              onClick={handleNavigate}
              src={leftArrow}
              style={{
                paddingRight: "1rem",
                height: "18px",
                cursor: "pointer",
              }}
              alt="Back"
            />
            <p>Jobs / Circulate</p>
          </div>
          <CirculateJobDetails circulateJobData={circulateJobData} />
          <div className="circulate_job_list_container">
            <div className="d-flex justify-content-between align-items-center ">
              <h6>Select Colleges</h6>
              <div className="job_circulate_search_container">
                {!isFocused && (
                  <img
                    style={{ paddingLeft: "10px" }}
                    height={27}
                    width={27}
                    src={searchIconDash}
                    alt="Search"
                  />
                )}
                <input
                  type="search"
                  placeholder="Search"
                  className="job_circulate_search"
                  name="search"
                  id="search"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                />
              </div>
            </div>

            <div className="circulate_job_container">
              {/* All Select Checkbox */}
              <div className="circulate_job_select_all p-2 d-flex align-items-center">
                <input
                  className="circulate_job_checkbox"
                  type="checkbox"
                  name="all-select"
                  id="all-select"
                  checked={selectedColleges.length === currentColleges.length}
                  onChange={handleSelectAll}
                />
                <label htmlFor="all-select" className="circulate_job_label">
                  All Select
                </label>
              </div>

              {/* College List */}
              <div className="circulate_job_list_container">
                {currentColleges.map((college) => (
                  <div className="circulate_job_card" key={college.id}>
                    <input
                      type="checkbox"
                      className="circulate_job_checkbox"
                      name={`select-${college.id}`}
                      id={`select-${college.id}`}
                      checked={selectedColleges.includes(college.email)}
                      onChange={() => handleCheckboxChange(college.email)}
                    />

                    <div className="circulate_job_card_content">
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="circulate_job_image_container">
                          <img
                            src={
                              college.college_logo ||
                              "https://via.placeholder.com/150"
                            }
                            alt={`${college.collegename || "College"} Logo`}
                            className="circulate_job_image"
                          />
                        </div>

                        <div className="circulate_job_details">
                          <h4 className="circulate_job_college_name">
                            <span>
                              {college.college_api.college_name?.slice(0, 35) ||
                                "Unnamed College"}
                              {college.college_api.college_name?.length > 35
                                ? "..."
                                : ""}
                            </span>
                          </h4>
                          <p className="circulate_job_location">
                            {college?.college_api?.district_name}{" "}
                            {college?.college_api?.state_name}
                          </p>
                        </div>
                      </div>

                      <div className="circulate_job_info_link">
                        <Link
                          to={`/dashboard/sidebar-page/${userData.roleName}/college-details/${college.id}`}
                        >
                          More Info
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Pagination */}
                <div className="d-flex justify-content-center align-items-center">
                  <Pagination
                    count={Math.ceil(filteredColleges.length / clgPerPage)}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    className="mt-3"
                  />
                </div>

                {/* Circulate Job Button */}
                {selectedColleges.length > 0 && (
                  <div className="" style={{ paddingRight: "0.5rem" }}>
                    <button
                      className="float-end circulate_job_btn"
                      onClick={handleCirculateJob}
                    >
                      Circulate Job
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const CirculateJobDetails = ({ circulateJobData }) => {
  return (
    <div className="circulate_job_detail">
      <ul>
        <li>{circulateJobData?.title || "No Job Title"}</li>
        <li>
          <span className={`status ${circulateJobData?.status.toLowerCase()}`}>
            {circulateJobData?.status}
            <svg width="14px" height="14px" viewBox="0 0 24 24">
              <rect x="0" fill="none" width="24" height="24" />
              <g>
                <path d="M7 10l5 5 5-5" />
              </g>
            </svg>
          </span>
        </li>
        <li>
          {new Date(circulateJobData?.created_at).toLocaleDateString() || "N/A"}
        </li>
        <li>
          {new Date(circulateJobData?.end_date).toLocaleDateString() || "N/A"}
        </li>
        <li>
          <p className="circulate_job_type">
            {circulateJobData?.type || "N/A"}{" "}
          </p>
        </li>

        <li>
          {circulateJobData?.min_salary} to {circulateJobData?.max_salary}
        </li>
        <li>{circulateJobData?.vacancy}</li>
      </ul>
    </div>
  );
};
