import React from "react";
import "../../../styles/dashboards/clgstudent/studentjobs.css";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import experienceIcon from "../../../utils/landingpage/images/experienceIcon.svg";
import dollerIcon from "../../../utils/landingpage/images/dollerIcon.svg";
import locationIcon from "../../../utils/landingpage/images/locationIconNew.png";
import saveIcon from "../../../utils/landingpage/images/saveIcon.svg";
import savedIcon from "../../../utils/landingpage/images/savedIocn.svg";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import searchIconStudent from "../../../utils/dashboards/searchIconStudent.png";
import axios from "axios";
import Loader from "react-spinners/ClipLoader";
import { useParams } from "react-router-dom";


const StudentJobs = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [studentJobData, setStudentJobData] = useState([]);
  const [totalStudentJobData, setTotalStudentJobData] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [save, setSave] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);


  const { id } = useParams();
  useEffect(() => {
    setJobId(id);
  }, [id]);

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    if (value !== "") {
      const filtered = studentJobData?.filter((job) =>
        job.title?.toLowerCase().includes(value)
      );
      setStudentJobData(filtered?.length > 0 ? filtered : null);
    } else {
      setStudentJobData(totalStudentJobData);
    }
  };

  function handleSave(index) {
    const userDetails = {
      job_id: index,
    };
    const accessToken = sessionStorage.getItem("accessToken");

    axios
      .post(`${process.env.REACT_APP_BASE_URL}/api/job/save/`, userDetails, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        setSave(response.data.detail); // Set the saved message if needed
        toast.success(response.data.detail); // Use the API response message in Toastify
        fetchStudentAlljobs();
      })
      .catch((error) => {
        toast.error("Failed to save the job. Please try again.");
      });
  }

  function handleUnSave(index) {
    const accessToken = sessionStorage.getItem("accessToken");
    axios
      .delete(`${process.env.REACT_APP_BASE_URL}/api/job/unsave/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          job_id: index,
        },
      })
      .then((response) => {
        toast.success(response.data.detail);
        fetchStudentAlljobs();
      })
      .catch((error) => {
        toast.success("Something Getting Wrong");
      });
  }

  const itemsPerPage = 6;
  const totalPages = Math.ceil(studentJobData?.length / itemsPerPage);

  const currentItems = studentJobData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getPostedDate = (date) => {
    const postDate = new Date(date);
    const today = new Date();
    const diffTime = Math.abs(today - postDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return `${diffDays} days ago`;
  };

  const fetchStudentAlljobs = async () => {
    const accessToken = sessionStorage.getItem("accessToken");
    setLoading(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/jobs_for_student_campus/`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        setStudentJobData(response.data);
        setTotalStudentJobData(response.data);
      })
      .catch((error) => {
        toast.error("Error occurred", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStudentAlljobs();
  }, []);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_jobs_dropdown_container">
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                paddingBottom: "2rem",
                paddingTop: "1rem",
              }}
            >
              <div style={{ fontSize: "1.2rem", fontWeight: "300" }}>
                <b>{`All Jobs [${studentJobData?.length || 0}]`} </b>
              </div>
              <div
                style={{
                  boxShadow: "rgba(0, 0, 0, 0.2) 0px 0px 2px 0px",
                  padding: "10px 10px 10px 20px",
                  borderRadius: "30px",
                  background: "white",
                }}
              >
                <img src={searchIconStudent} alt="..." />
                <input
                  style={{
                    border: "none",
                    background: "none",
                    paddingLeft: "10px",
                  }}
                  type="Search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </div>
            </div>

            {loading ? (
              <div className="custom_loader">
                <Loader color="#F38D00" size={50} />
              </div>
            ) : (<div className="job-listings">
              {studentJobData ? (
                currentItems?.map((job, index) => (
                  <div key={index} className="job-listing">
                    <div className="company-info">
                      <Link
                        to={`/dashboard/sidebar-page/student/job-details/${job.id}`}
                      >
                        <img
                          src={
                            job.company_profile?.company_logo_url || dummy_logo
                          }
                          alt={"logo"}
                          className="company-logo"
                        />
                      </Link>
                      <div style={{ width: "100%" }}>
                        <div className="campus_jobDetails_flex">
                          <div className="d-flex align-items-center gap-4">
                            <Link
                              to={`/dashboard/sidebar-page/student/job-details/${job.id}`}
                            >
                              <h2>{job.title || "Not mentioned"}</h2>
                            </Link>
                            {job?.status === "LIVE" ? (<button className="job_list_job_active">Live</button>) :  (<button className="job_list_job_active job_list_job_closed">Expired</button>) }
                          </div>
                          <Link
                            to={`/dashboard/sidebar-page/student/job-details/${job.id}`}
                            style={{ textDecoration: "none", color: "unset" }}
                          >
                            <div>
                              <b>Job Details</b>
                            </div>
                          </Link>
                        </div>
                        <div className="d-flex gap-2">
                          <div className="company-name">
                            {job.company_profile?.company_name ||
                              "Not Mentioned"}
                          </div>
                          <div className="rating">
                            <span className="star">★</span>{" "}
                            {job.company_profile?.company_rating || "0"} |
                            {job.company_profile?.company_review || "0"} Reviews
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="job-details mb-0">
                      <span className="detail">
                        <span className="details_job_icon">
                          <img src={experienceIcon} alt="..." />
                        </span>{" "}
                        {job.min_experience || "Not mentioned"} -{" "}
                        {job.max_experience} yrs{" "}
                        <span className="stand_icon">|</span>{" "}
                      </span>
                      <span className="detail">
                        <span className="details_job_icon">
                          <img src={dollerIcon} alt="..." />
                        </span>{" "}
                        {job.min_salary || "Not mentioned"} - {job.max_salary}{" "}
                        LPA <span className="stand_icon">|</span>{" "}
                      </span>
                      <span className="detail">
                        <span className="details_job_icon">
                          <img src={locationIcon} alt="..." />
                        </span>{" "}
                        {job.job_location || "Not mentioned"}
                      </span>
                    </div>
                    <div className="listing-footer">
                      <span className="posted-date">
                        {getPostedDate(job.created_at)}
                      </span>
                      <button
                        className="save-button student_campus_save_button"
                        style={{
                          background: "transparent",
                          color: "#000000de",
                          fontWeight: "400",
                        }}
                      >
                        {job.is_already_saved ? (
                          <>
                            <img src={savedIcon} alt="saved" />
                            <span onClick={() => handleUnSave(job.id)}>
                              Saved
                            </span>
                          </>
                        ) : (
                          <>
                            <img src={saveIcon} alt="save" />
                            <span onClick={() => handleSave(job.id)}>Save</span>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <h1 style={{ fontSize: 16, textAlign: "center" }}>
                  No Jobs Found
                </h1>
              )}

              <div className="pagination-controls">
                <Stack spacing={2} alignItems="center">
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    siblingCount={1}
                    boundaryCount={1}
                    renderItem={(item) => (
                      <PaginationItem
                        components={{
                          previous: () => <img src={leftArrowIcon} alt="prev" />,
                          next: () => <img src={rightIconArrow} alt="next" />,
                        }}
                        {...item}
                      />
                    )}
                  />
                </Stack>
              </div>

            </div>)}

          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentJobs;