import React, { useState, useRef, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import '../.../../../../../styles/dashboards/clgstudent/studentprofile.css'
import { fetchStudentProfileData } from '../../../../redux/actions/student_profile_action';
import { useDispatch, useSelector } from 'react-redux';
import { setGetStudentProfileData } from '../../../../redux/reducers/student_profile_reducer';
import { fetchStudentProfileCompletion } from '../../../../redux/actions/student_profilePercentage';
import { setIsEditModelOpen } from '../../../../redux/reducers/student_profile_reducer';
import { setIsSubmitted } from '../../../../redux/reducers/student_profile_reducer';

const PersonalInformation = () => {

    const NewupdatedData = useSelector((state) => state.studentProfile.getStudentProfileData);
    const isSubmitted = useSelector((state) => state.studentProfile.isSubmitted);

    const [submittedData, setSubmittedData] = useState(null);
    const [updatedData, setUpdatedData] = useState([]);
    // const [isSubmitted, setIsSubmitted] = useState(false);
    const [isEditModelOpen, setIsEditModelOpen] = useState(false);
    const [profileImage, setProfileImage] = useState("");
    const [profileCompletion, setProfileCompletion] = useState("");
    const [resumeFile, setResumeFile] = useState(NewupdatedData?.student_resume_url || null);
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        dob: "",
        gender: "",
        currentLocation: "",
        bio: "",
        preferredLocation: "",
        homeState: "",
        homeCity: "",
        expectedSalary: "",
        degree: "",
        institute: "",
        courseName: "",
        courseType: "",
        startingYear: "",
        endingYear: "",
        school: "",
        board: "",
        passingYear: ""
    });

    const [initialValuesSet, setInitialValuesSet] = useState({
        firstName: false,
        lastName: false,
        email: false,
        phone: false,
        dob: false,
        gender: false,
        currentLocation: false,
        bio: false,
        preferredLocation: false,
        homeState: false,
        homeCity: false,
        expectedSalary: false,
        degree: false,
        institute: false,
        courseName: false,
        courseType: false,
        startingYear: false,
        endingYear: false,
        school: false,
        board: false,
        passingYear: false
    });

    const [errors, setErrors] = useState({});

    const fileInputRef = useRef(null);
    const resumeInputRef = useRef(null);

    const capitalizeFirstLetter = (string) => {
        if (!string) return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    useEffect(() => {
        dispatch(fetchStudentProfileData());
        dispatch(fetchStudentProfileCompletion())
    }, [dispatch]);

    useEffect(() => {
        setUpdatedData(NewupdatedData)
        setProfileImage(NewupdatedData?.student_photo_url)
        setResumeFile(NewupdatedData?.student_resume_url)
    }, [NewupdatedData])

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => setProfileImage(e.target.result); // Preview the selected image
            reader.readAsDataURL(file);
            handleImageUploadSubmit();
        }
    };

    const handleResumeUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const fileExtension = file.name.split(".").pop().toLowerCase();
            const validExtensions = ["docx", "doc", "pdf"];
            const maxSize = 2 * 1024 * 1024; // 2MB
            const minSize = 10 * 1024; // 10KB

            if (
                validExtensions.includes(fileExtension) &&
                file.size <= maxSize &&
                file.size >= minSize
            ) {
                setResumeFile(file);
                handleSubmitResume(file);
            } else {
                let errorMessage = "Please upload a valid file (DOCX, DOC, PDF)";
                if (file.size < minSize) {
                    errorMessage += " of at least 10KB.";
                } else {
                    errorMessage += " up to 2MB in size.";
                }
                toast.error(errorMessage); // Display error message using Toastify
            }
        }
    };

    const handleSubmitResume = async (file) => {
        if (!file) {
            alert("Please upload a resume.");
            return;
        }

        const userSessionData = sessionStorage.getItem("userDetails");

        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;

        const formData = new FormData();
        formData.append("student_resume", file);

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                toast.success("Resume uploaded successfully!");
                dispatch(setGetStudentProfileData(response.data));
                dispatch(fetchStudentProfileCompletion())
                // setUpdatedData(response.data);
                // fetchProfileCompletion();
            } else {
                toast.error("Failed to upload resume.");
            }
        } catch (error) {
            toast.error("Failed to upload resume.");
        }
    };

    const handleImageUploadSubmit = async () => {
        const userSessionData = sessionStorage.getItem("userDetails");
        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;

        try {
            const file = fileInputRef.current.files[0];
            if (!file) return alert("Please select an image to upload.");

            const formData = new FormData();
            formData.append("student_photo", file);

            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
                formData,
                {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
                    },
                }
            );

            if (response.data) {
                // setUpdatedData(response.data);
                // fetchProfileCompletion();
                dispatch(setGetStudentProfileData(response.data));
                dispatch(fetchStudentProfileCompletion())
                toast.success("Added Successfully");
            }
        } catch (error) {
            console.error("Error uploading image:", error);
            toast.error(error);
        }
    };

    const handleInputChangeWithValidation = (e) => {
        const { name, value } = e.target;

        if (
            name === "firstName" &&
            value.charAt(0) !== value.charAt(0).toUpperCase()
        ) {
            setErrors((prevErrors) => ({
                ...prevErrors,
                firstName: "First letter should be capitalized",
            }));
        } else {
            setErrors((prevErrors) => ({
                ...prevErrors,
                firstName: "",
            }));
        }

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const today = new Date();
    const maxDate = new Date(today.setFullYear(today.getFullYear() - 10)).toISOString().split("T")[0];

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        setErrors({ ...errors, [name]: "" });
    };

    const handleInputEditChange = (field, value) => {
        if (!initialValuesSet[field]) {
            setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
        }
        // placementOfficerEditFormik.setFieldValue(field, value);
        setFormData({
            ...formData,
            [field]: value,
        });
    };

    const validate = () => {
        let tempErrors = {};
        let isValid = true;

        // if (!formData.phone) {
        //     tempErrors.phone = "Phone number is required";
        //     isValid = false;
        // } else if (formData.phone.length < 10) {
        //     tempErrors.phone = "Phone number must be at least 10 digits";
        //     isValid = false;
        // }
        if (!formData.dob) {
            tempErrors.dob = "Date of Birth is required";
            isValid = false;
        }
        if (!formData.gender) {
            tempErrors.gender = "Gender is required";
            isValid = false;
        }
        if (!formData.currentLocation) {
            tempErrors.currentLocation = "Current Location is required";
            isValid = false;
        }
        if (!formData.bio) {
            tempErrors.bio = "Bio is required";
            isValid = false;
        }
        if (!formData.preferredLocation) {
            tempErrors.preferredLocation = "Preferred Location is required";
            isValid = false;
        }
        if (!formData.homeState) {
            tempErrors.homeState = "Home State is required";
            isValid = false;
        }
        if (!formData.homeCity) {
            tempErrors.homeCity = "Home City is required";
            isValid = false;
        }
        if (!formData.expectedSalary) {
            tempErrors.expectedSalary = "Expected Salary is required";
            isValid = false;
        }

        setErrors(tempErrors);
        return isValid;
    };

    const handlePersonalSubmit = async (event) => {
        event.preventDefault();

        if (!validate()) return;

        const accessToken = sessionStorage.getItem("accessToken");

        const userSessionData = sessionStorage.getItem("userDetails");
        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;

        const payload = {
            first_name: formData.firstName || updatedData.first_name || "",
            last_name: formData.lastName || updatedData.last_name || "",
            phone_no: formData.phone || updatedData.phone_no || null,
            dob: formData.dob || updatedData.dob || null,
            gender: formData.gender || updatedData.gender || null,
            current_location:
                formData.currentLocation || updatedData.current_location || null,
            about: formData.bio || updatedData.bio || null,
            preferred_location:
                formData.preferredLocation || updatedData.preferred_location || null,
            state: formData.homeState || updatedData.state || null,
            city: formData.homeCity || updatedData.city || null,
            expected_salary:
                formData.expectedSalary || updatedData.expected_salary || null,
        };

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
                payload,
                config
            );
            if (response.status === 200) {
                // setIsSubmitted(true);
                dispatch(setIsSubmitted(true))
                setSubmittedData(response.data);
                // fetchProfileCompletion();
                toast.success("Added Successfully");
                // setUpdatedData(response.data);
                dispatch(setGetStudentProfileData(response.data));
                dispatch(fetchStudentProfileCompletion())
                setFormData({
                    firstName: "",
                    lastName: "",
                    email: "",
                    phone: "",
                    dob: "",
                    gender: "",
                    currentLocation: "",
                    bio: "",
                    preferredLocation: "",
                    homeState: "",
                    homeCity: "",
                    certificateCourseName: "",
                    certificateInstitute: "",
                    certificateType: "",
                    specialization: "",
                    certificateStartingYear: "",
                    certificateEndingYear: "",
                    certificateValidity: "",
                    skills: "",
                    qualifications: [],
                    employments: [],
                    certificates: [],
                });
            } else {
                toast.error(errors);
            }
        } catch (error) {
            toast.error(errors);
        }
    };

    const editPersonalDetails = async (event) => {
        event.preventDefault();

        const accessToken = sessionStorage.getItem("accessToken");
        const userSessionData = sessionStorage.getItem("userDetails");
        const parsedData = JSON.parse(userSessionData);
        const userId = parsedData.id;

        const payload = {
            first_name: formData.firstName || updatedData.first_name || "",
            last_name: formData.lastName || updatedData.last_name || "",
            phone_no: formData.phone || updatedData.phone_no || null,
            dob: formData.dob || updatedData.dob || null,
            gender: formData.gender || updatedData?.gender || null,
            current_location:
                formData.currentLocation || updatedData.current_location || null,
            about: formData.bio || updatedData.about || null,
            preferred_location:
                formData.preferredLocation || updatedData.preferred_location || null,
            state: formData.homeState || updatedData.state || null,
            city: formData.homeCity || updatedData.city || null,
            expected_salary:
                formData.expectedSalary || updatedData.expected_salary || null,
        };

        if (Object.keys(payload).length === 0) {
            console.log("No changes made, nothing to update.");
            return;
        }

        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };

        try {
            const response = await axios.put(
                `${process.env.REACT_APP_BASE_URL}/api/student_profile/${userId}/`,
                payload,
                config
            );
            if (response.status === 200) {
                // setIsSubmitted(true);
                dispatch(setIsSubmitted(true))
                setSubmittedData(response.data);
                // fetchProfileCompletion();
                dispatch(setGetStudentProfileData(response.data));
                setUpdatedData(response.data);
                setIsEditModelOpen(false);
                toast.success("Added Successfully");
                // setFormData({
                //     firstName: "",
                //     lastName: "",
                //     email: "",
                //     phone: "",
                //     dob: "",
                //     gender: "",
                //     currentLocation: "",
                //     bio: "",
                //     preferredLocation: "",
                //     homeState: "",
                //     homeCity: "",
                //     expectedSalary: "",
                // });
            } else {
                toast.error(errors);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    const handleEditClick = () => {
        // setIsSubmitted(false);
        dispatch(setIsSubmitted(false))
        setIsEditModelOpen(!isEditModelOpen);
    };

    const handleEditCancel = () => {
        dispatch(setIsSubmitted(true))
        setIsEditModelOpen(false);
    };

    return (<>
        <div className="epf-header student_epf_header flex items-center justify-between p-6 bg-white rounded-lg shadow-md pb-2">
            <div className="epf-image-wrapper relative">
                {/* Image Preview */}
                <img
                    src={profileImage || "default-image.jpg"}
                    alt="ProfilePhoto"
                    className="epf-profile-pic student_epf_profile_pic w-20 h-20 rounded-full object-cover"
                />
                <button
                    type="button"
                    className="epf-upload-trigger bg-blue-500 rounded-full p-2 flex items-center justify-center cursor-pointer"
                    onClick={() => fileInputRef.current.click()}
                    aria-label="Upload Profile Picture"
                >
                    <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M17.6294 11.2078H1.98252C1.18062 11.2078 0.515625 10.5428 0.515625 9.74094C0.515625 8.93904 1.18062 8.27405 1.98252 8.27405H17.6294C18.4313 8.27405 19.0963 8.93904 19.0963 9.74094C19.0963 10.5428 18.4313 11.2078 17.6294 11.2078Z"
                            fill="white"
                        />
                        <path
                            d="M9.80771 19.0313C9.00581 19.0313 8.34082 18.3663 8.34082 17.5644V1.91752C8.34082 1.11561 9.00581 0.450623 9.80771 0.450623C10.6096 0.450623 11.2746 1.11561 11.2746 1.91752V17.5644C11.2746 18.3663 10.6096 19.0313 9.80771 19.0313Z"
                            fill="white"
                        />
                    </svg>
                </button>

                <input
                    type="file"
                    ref={fileInputRef}
                    className="hidden"
                    onChange={handleImageUpload} // Handle file selection
                    accept="image/*"
                />
            </div>

            <div className="epf-resume-block flex flex-col items-start">
                <div className="d-flex justify-content-between align-items-center">
                    <div style={{ fontSize: "13px" }} className="epf-resume-prompt">Upload Your Resume</div>
                    <div
                        onClick={() => resumeInputRef.current.click()}
                        className="add_resume_text text-sm text-red-600 mb-2"
                        style={{ fontSize: "13px" }}
                    >
                        {resumeFile ? "Resume Added" : "Add Resume"}
                    </div>
                </div>
                <div
                    className="epf-format-info flex items-center text-xs text-gray-500 mb-2 student_epf_format_info"
                    onClick={() => resumeInputRef.current.click()}
                >
                    <span className="epf-format-icon mr-2">
                        <svg
                            width="30"
                            height="33"
                            viewBox="0 0 30 33"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14.5431 12.0712C14.3942 12.0712 14.2603 12.0414 14.1114 11.9819C13.6947 11.8181 13.4268 11.4014 13.4268 10.9549V2.02445C13.4268 1.4142 13.9328 0.908142 14.5431 0.908142C15.1533 0.908142 15.6594 1.4142 15.6594 2.02445V8.26086L16.731 7.18921C17.1627 6.75757 17.8771 6.75757 18.3087 7.18921C18.7404 7.62085 18.7404 8.33528 18.3087 8.76692L15.3319 11.7437C15.1235 11.9521 14.8259 12.0712 14.5431 12.0712Z"
                                fill="#F38D00"
                            />
                            <path
                                d="M14.5415 12.0731C14.2587 12.0731 13.9759 11.9689 13.7527 11.7457L10.7759 8.76887C10.3442 8.33723 10.3442 7.6228 10.7759 7.19116C11.2075 6.75952 11.9219 6.75952 12.3536 7.19116L15.3304 10.168C15.762 10.5996 15.762 11.314 15.3304 11.7457C15.1071 11.9689 14.8243 12.0731 14.5415 12.0731Z"
                                fill="#F38D00"
                            />
                            <path
                                d="M20.4968 32.9082H8.58958C0.03125 32.9082 0.03125 28.3685 0.03125 24.3498V22.8614C0.03125 19.5423 0.03125 15.7915 7.10117 15.7915C8.87237 15.7915 9.52727 16.2231 10.4501 16.9078C10.4947 16.9525 10.5543 16.9822 10.5989 17.0418L12.1171 18.6492C13.3971 20.0037 15.719 20.0037 16.9991 18.6492L18.5172 17.0418C18.5619 16.9971 18.6065 16.9525 18.6661 16.9078C19.5889 16.2083 20.2438 15.7915 22.015 15.7915C29.0849 15.7915 29.0849 19.5423 29.0849 22.8614V24.3498C29.0551 30.0355 26.1825 32.9082 20.4968 32.9082ZM7.10117 18.0241C2.26386 18.0241 2.26386 19.5423 2.26386 22.8614V24.3498C2.26386 28.4281 2.26386 30.6755 8.58958 30.6755H20.4968C24.9323 30.6755 26.8225 28.7853 26.8225 24.3498V22.8614C26.8225 19.5423 26.8225 18.0241 21.9852 18.0241C20.9136 18.0241 20.6903 18.1581 20.0503 18.6344L18.6065 20.1674C17.5349 21.2986 16.0911 21.9237 14.5432 21.9237C12.9953 21.9237 11.5515 21.2986 10.4799 20.1674L9.0361 18.6344C8.39608 18.1581 8.17282 18.0241 7.10117 18.0241Z"
                                fill="#F38D00"
                            />
                            <path
                                d="M4.129 18.0244C3.51875 18.0244 3.0127 17.5183 3.0127 16.9081V10.9544C3.0127 8.06693 3.01269 4.47988 8.49002 3.95894C9.10027 3.8994 9.65098 4.34592 9.71052 4.97105C9.77005 5.5813 9.32353 6.13201 8.6984 6.19154C5.2453 6.50411 5.2453 7.90321 5.2453 10.9544V16.9081C5.2453 17.5183 4.73924 18.0244 4.129 18.0244Z"
                                fill="#F38D00"
                            />
                            <path
                                d="M24.9616 18.025C24.3514 18.025 23.8453 17.5189 23.8453 16.9087V10.955C23.8453 7.90382 23.8453 6.50472 20.3922 6.17727C19.782 6.11773 19.3354 5.56702 19.395 4.95678C19.4545 4.34653 19.9903 3.88513 20.6155 3.95955C26.0928 4.48049 26.0928 8.06754 26.0928 10.955V16.9087C26.0779 17.5189 25.5719 18.025 24.9616 18.025Z"
                                fill="#F38D00"
                            />
                        </svg>
                    </span>
                    <span className="epf-format-text">
                        Supported Formats: DOCX, DOC, PDF
                        <br />
                        Max Size: 2MB
                    </span>
                </div>
                <input
                    type="file"
                    ref={resumeInputRef}
                    className="hidden"
                    onChange={handleResumeUpload}
                    accept=".docx,.doc,.pdf"
                />
            </div>

        </div>
        <div className="pt-4 pb-4">
            <div
                className="student_epf-form-container"
                style={isSubmitted ? { position: "relative" } : { display: "block" }}
            >
                {isSubmitted && isEditModelOpen === false && (
                    <div className="epf_edit_Details_button student_epf_details_button" onClick={handleEditClick}>
                        <b>
                            <svg
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                                    fill="#F38D00"
                                />
                            </svg>
                            <span className="pl-4"> Edit Details</span>{" "}
                        </b>
                    </div>
                )}
                {isEditModelOpen ? (
                    <>
                        <div className='student_my_profile_summary' style={{ width: "100%" }} >
                            <p>
                                <b>Edit Profile Summary:</b>
                            </p>
                            <textarea
                                name='bio'
                                // value={updatedData.about || ""}
                                placeholder="Edit Your Profile Summary"
                                value={
                                    !initialValuesSet.bio
                                        ? updatedData?.about || ""
                                        : formData.bio
                                }
                                onChange={(e) => handleInputEditChange("bio", e.target.value)}
                                rows={3}
                                style={{ width: '100%', resize: 'vertical' }}
                            />
                        </div> <br />

                        <form className="epf-form-layout" onSubmit={editPersonalDetails}>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.firstName ? "input-error" : ""
                                        }`}
                                    placeholder="First Name"
                                    name="firstName"
                                    // value={
                                    //     // capitalizeFirstLetter(formData.firstName) ||
                                    //     updatedData?.first_name || ""
                                    // }
                                    // onChange={(e) => handleInputChangeWithValidation(e)}
                                    value={
                                        !initialValuesSet.firstName
                                            ? updatedData?.first_name || ""
                                            : formData.lastName
                                    }
                                    onChange={(e) => {
                                        handleInputEditChange("firstName", e.target.value);
                                        // handleInputChangeWithValidation(e);
                                    }}
                                />
                                {errors.firstName && (
                                    <span className="error-message">
                                        First letter should be capitalized
                                    </span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Last Name"
                                    name="lastName"
                                    // value={
                                    //     // capitalizeFirstLetter(formData.lastName) || ""
                                    // }
                                    // onChange={(e) => handleInputChangeWithValidation(e)}
                                    value={
                                        !initialValuesSet.lastName
                                            ? updatedData?.last_name || ""
                                            : formData.lastName
                                    }
                                    onChange={(e) => {
                                        handleInputEditChange("lastName", e.target.value);
                                        // handleInputChangeWithValidation(e); 
                                    }}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Enter your Email"
                                    name="email"
                                    value={updatedData.email || formData.email}
                                    disabled
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    type="text"
                                    // value={formData.phone || updatedData?.phone_no || ''}
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.phone
                                            ? updatedData?.phone_no || ""
                                            : formData.phone
                                    }
                                    onChange={(e) => handleInputEditChange("phone", e.target.value)}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Date of Birth"
                                    name="dob"
                                    type="date"
                                    // value={formData.dob || updatedData?.dob}
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.dob
                                            ? updatedData?.dob || ""
                                            : formData.dob
                                    }
                                    onChange={(e) => handleInputEditChange("dob", e.target.value)}
                                    onFocus={(e) => {
                                        e.target.type = "date";
                                        e.target.max = maxDate;
                                    }}
                                    onBlur={(e) => (e.target.type = "text")}
                                />
                            </div>

                            <div className="epf-input-wrapper epf-input-gap">
                                <select
                                    name="gender"
                                    // value={formData.gender || updatedData?.gender}
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.gender
                                            ? updatedData?.gender || ""
                                            : formData.gender
                                    }
                                    onChange={(e) => handleInputEditChange("gender", e.target.value)}
                                    className={`epf-input-field ${errors.gender ? "input-error" : ""
                                        }`}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                                {/* {errors.gender && (
                                    <span className="error-text">{errors.gender}</span>
                                )} */}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Current Location"
                                    name="currentLocation"
                                    // value={
                                    //     formData.currentLocation || updatedData?.current_location
                                    // }
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.currentLocation
                                            ? updatedData?.current_location || ""
                                            : formData.currentLocation
                                    }
                                    onChange={(e) => handleInputEditChange("currentLocation", e.target.value)}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Preferred Location"
                                    name="preferredLocation"
                                    // value={
                                    //     formData.preferredLocation ||
                                    //     updatedData?.preferred_location
                                    // }
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.preferredLocation
                                            ? updatedData?.preferred_location || ""
                                            : formData.preferredLocation
                                    }
                                    onChange={(e) => handleInputEditChange("preferredLocation", e.target.value)}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Home State"
                                    name="homeState"
                                    // value={formData.homeState || updatedData?.state}
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.homeState
                                            ? updatedData?.state || ""
                                            : formData.homeState
                                    }
                                    onChange={(e) => handleInputEditChange("homeState", e.target.value)}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Home City"
                                    name="homeCity"
                                    // value={formData.homeCity || updatedData?.city}
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.homeCity
                                            ? updatedData?.city || ""
                                            : formData.homeCity
                                    }
                                    onChange={(e) => handleInputEditChange("homeCity", e.target.value)}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className="epf-input-field"
                                    placeholder="Expected Salary"
                                    name="expectedSalary"
                                    // value={
                                    //     formData.expectedSalary || updatedData?.expected_salary
                                    // }
                                    // onChange={handleInputChange}
                                    value={
                                        !initialValuesSet.expectedSalary
                                            ? updatedData?.expected_salary || ""
                                            : formData.expectedSalary
                                    }
                                    onChange={(e) => handleInputEditChange("expectedSalary", e.target.value)}
                                />
                            </div>

                            <div className="d-flex gap-4">
                                <button type="submit" className="epf-submit-btn mt-0">
                                    Save
                                </button>
                                <button
                                    onClick={handleEditCancel}
                                    className="epf-submit-btn mt-0"
                                    style={{
                                        background: "transparent",
                                        border: "1px solid rgba(243, 141, 0, 1)",
                                        color: "rgba(243, 141, 0, 1)",
                                    }}
                                >
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </>
                ) : null}
                {isSubmitted === true && isEditModelOpen === false ? (<>

                    <div className="submitted-data-container student_submitted_data_contianer">

                        <div className="submitted-data-column">
                            <p>
                                <b>First Name:</b> {updatedData?.first_name || "N/A"}
                            </p>
                            <p>
                                <b>Last Name:</b> {updatedData?.last_name || "N/A"}
                            </p>
                            <p>
                                <b>Email:</b> {updatedData?.email || "N/A"}
                            </p>
                            <p>
                                <b>Phone:</b> {updatedData?.phone_no || "N/A"}
                            </p>
                        </div>
                        <div className="submitted-data-column">
                            <p>
                                <b>DOB:</b> {updatedData?.dob || "N/A"}
                            </p>
                            <p>
                                <b>Gender:</b> {updatedData?.gender || "N/A"}
                            </p>
                            <p>
                                <b>Current Location:</b>{" "}
                                {updatedData?.current_location || "N/A"}
                            </p>
                            <p>
                                <b>Preferred Location:</b>{" "}
                                {updatedData?.preferred_location || "N/A"}
                            </p>

                        </div>
                        <div className="submitted-data-column">
                            <p>
                                <b>Home State:</b> {updatedData?.state || "N/A"}
                            </p>
                            <p>
                                <b>Home City:</b> {updatedData?.city || "N/A"}
                            </p>
                            <p>
                                <b>Expected Salary:</b>{" "}
                                {`${updatedData?.expected_salary || "N/A"} LPA`}
                            </p>
                        </div>
                    </div>
                    <div className='student_my_profile_summary' >
                        <p>
                            <b>My Profile Summary:</b>
                        </p>
                        <textarea
                            // name='bio'
                            value={formData.bio}
                            placeholder={updatedData?.about}
                            // onChange={handleInputChange}
                            disabled
                            rows={3}
                            style={{ width: '100%', resize: 'vertical' }}
                        />
                    </div>
                </>) : (
                    isEditModelOpen === false &&
                    isSubmitted === false && (<>
                        <div className='student_my_profile_summary' style={{ width: "100%" }} >
                            <p>
                                <b>Add Profile Summary:</b>
                            </p>
                            <textarea
                                name='bio'
                                value={formData.bio}
                                placeholder='Add Your Proifle Summary'
                                onChange={handleInputChange}
                                rows={3}
                                style={{ width: '100%', resize: 'vertical' }}
                                className={errors.bio ? "input-error" : ""}
                            />
                            {errors.bio && (
                                <span className="error-text">{errors.bio}</span>
                            )}
                        </div> <br />
                        <form className="epf-form-layout" onSubmit={handlePersonalSubmit}>
                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.firstName ? "input-error" : ""
                                        }`}
                                    placeholder="First Name"
                                    name="firstName"
                                    value={
                                        capitalizeFirstLetter(formData.firstName) ||
                                        capitalizeFirstLetter(updatedData?.first_name) ||
                                        ""
                                    }
                                    onChange={handleInputChange}
                                />
                                {errors.firstName && (
                                    <span className="error-message">
                                        First letter should be capitalized
                                    </span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.lastName ? "input-error" : ""
                                        }`}
                                    placeholder="Last Name"
                                    name="lastName"
                                    value={formData.lastName || updatedData?.last_name || ""}
                                    onChange={handleInputChange}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field`}
                                    placeholder="Enter your Email"
                                    name="email"
                                    value={updatedData?.email || ""}
                                    readOnly
                                    style={{ cursor: "no-drop" }}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.phone ? "input-error" : ""
                                        }`}
                                    placeholder="Enter your phone number"
                                    name="phone"
                                    type="number"
                                    value={
                                        formData.phone ||
                                        updatedData?.phone_no?.replace("+91", "")
                                    }
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        if (value.length <= 10) {
                                            handleInputChange(e);
                                        }
                                    }}
                                />
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.dob ? "input-error" : ""
                                        }`}
                                    placeholder="Enter your DOB"
                                    name="dob"
                                    type="text"
                                    value={formData.dob}
                                    onChange={handleInputChange}
                                    onFocus={(e) => {
                                        e.target.type = "date";
                                        e.target.max = maxDate;
                                    }}
                                    onBlur={(e) => (e.target.type = "text")}
                                />
                                {errors.dob && (
                                    <span className="error-text">{errors.dob}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper epf-input-gap">
                                <select
                                    name="gender"
                                    value={formData.gender}
                                    onChange={handleInputChange}
                                    className={`epf-input-field ${errors.gender ? "input-error" : ""
                                        }`}
                                >
                                    <option value="">Select Gender</option>
                                    <option value="male">Male</option>
                                    <option value="female">Female</option>
                                    <option value="others">Others</option>
                                </select>
                                {errors.gender && (
                                    <span className="error-text">{errors.gender}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.currentLocation ? "input-error" : ""
                                        }`}
                                    placeholder="Current Location"
                                    name="currentLocation"
                                    value={formData.currentLocation}
                                    onChange={handleInputChange}
                                />
                                {errors.currentLocation && (
                                    <span className="error-text">{errors.currentLocation}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.preferredLocation ? "input-error" : ""
                                        }`}
                                    placeholder="Preferred Location"
                                    name="preferredLocation"
                                    value={formData.preferredLocation}
                                    onChange={handleInputChange}
                                />
                                {errors.preferredLocation && (
                                    <span className="error-text">
                                        {errors.preferredLocation}
                                    </span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.homeState ? "input-error" : ""
                                        }`}
                                    placeholder="Home State"
                                    name="homeState"
                                    value={formData.homeState}
                                    onChange={handleInputChange}
                                />
                                {errors.homeState && (
                                    <span className="error-text">{errors.homeState}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.homeCity ? "input-error" : ""
                                        }`}
                                    placeholder="Home City"
                                    name="homeCity"
                                    value={formData.homeCity}
                                    onChange={handleInputChange}
                                />
                                {errors.homeCity && (
                                    <span className="error-text">{errors.homeCity}</span>
                                )}
                            </div>

                            <div className="epf-input-wrapper">
                                <input
                                    className={`epf-input-field ${errors.expectedSalary ? "input-error" : ""
                                        }`}
                                    placeholder="Expected Salary (in Lakhs)"
                                    name="expectedSalary"
                                    value={formData.expectedSalary}
                                    onChange={handleInputChange}
                                />
                                {errors.expectedSalary && (
                                    <span className="error-text">{errors.expectedSalary}</span>
                                )}
                            </div>

                            <button type="submit" className="epf-submit-btn mt-0">
                                Save
                            </button>
                        </form>
                    </>)
                )}
            </div>
        </div>
    </>)
};

export default PersonalInformation;