import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/dashboard.css";
import "../../../styles/dashboards/hrdashboard/hrhome.css";
import "chart.js/auto";
import applicationIcon from "../../../utils/dashboards/applicationIcon.svg";
import serculateIcon from "../../../utils/dashboards/serculateIcon.svg";
import studentAppIcon from "../../../utils/dashboards/cross.svg";
import inInterviewIcon from "../../../utils/dashboards/inInterview.svg";
import joboffered from "../../../utils/dashboards/joboffered.svg";
import axios from "axios";
import API from "../../../Api";
import CampusWiseBarChart from "../../reusablecomponents/dashboardComponents/CampusWiseBarChart";
import ResumeRecivedCircleChart from "../../reusablecomponents/dashboardComponents/ResumeRecivedCircleChart";

import iim from "../../../utils/dashboards/iim.png";
import { Link } from "react-router-dom";

const colleges = [
  {
    date: "10 August",
    name: "IIM Mumbai",
    image: "https://via.placeholder.com/100?text=IIM",
    daysRemaining: "10 Days Remaining",
  },
  {
    date: "12 August",
    name: "IIT Delhi",
    image: "https://via.placeholder.com/100?text=IIT",
    daysRemaining: "12 Days Remaining",
  },
  {
    date: "15 August",
    name: "XLRI Jamshedpur",
    image: "https://via.placeholder.com/100?text=XLRI",
    daysRemaining: "15 Days Remaining",
  },
  {
    date: "20 August",
    name: "ISB Hyderabad",
    image: "https://via.placeholder.com/100?text=ISB",
    daysRemaining: "20 Days Remaining",
  },
  {
    date: "25 August",
    name: "IIT Bombay",
    image: "https://via.placeholder.com/100?text=IIT+Bombay",
    daysRemaining: "25 Days Remaining",
  },
];

function HRManagerHome() {
  const [hrCounter1, setHRCounter1] = useState({});
  const [recentJobs, setRecentJobs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchDashboardCounters = async () => {
    try {
      const response1 = await axios.get(
        `${baseUrl}/api/jobs/company_lateralandcampus_jobs/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      setHRCounter1(response1.data); // This should be outside the axios call.
    } catch (err) {
      console.log("Error fetching dashboard counters", err);
    }
  };

  // Fetch the recent jobs from the API
  // Fetch the recent jobs from the API
  const fetchRecentJobs = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${baseUrl}/api/jobs/company_lateralcampus_jobs/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const sortedJobs = response.data.jobs
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
        .slice(0, 6); // Only take the first 6 jobs

      setRecentJobs(sortedJobs);
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch jobs");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardCounters();
    fetchRecentJobs(); // Fetch jobs when the component mounts
  }, []);
  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="hr-stat-container">
            <div className="hr-stat-card">
              <div className="stat-icon">
                <img src={applicationIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Total Job Posted</h3>
                <p>{hrCounter1.total_jobs_posted}</p>
              </div>
            </div>

            <div className="hr-stat-card">
              <div className="stat-icon">
                <img src={serculateIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Circulated Colleges</h3>
                <p>{hrCounter1.total_circulated_colleges}</p>
              </div>
            </div>

            <div className="hr-stat-card">
              <div className="stat-icon">
                <img src={studentAppIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Students Applied</h3>
                <p>{hrCounter1.total_students_applied}</p>
              </div>
            </div>

            <div className="hr-stat-card">
              <div className="stat-icon">
                <img src={inInterviewIcon} alt="..." />
              </div>
              <div className="stat-info">
                <h3>In Interview</h3>
                <p>{hrCounter1.total_interviewed}</p>
              </div>
            </div>

            <div className="hr-stat-card">
              <div className="stat-icon">
                <img src={joboffered} alt="..." />
              </div>
              <div className="stat-info">
                <h3>Offered</h3>
                <p>{hrCounter1.total_offered}</p>
              </div>
            </div>
          </div>

          <div className="_main_content_hr">
            <div className="hr_home_first_section">
              <div className="_chart_section">
                <div className="_chart_section_b">
                  <b>Campuswise Student Application</b>
                </div>
                <div className="campus_main_container">
                  <CampusWiseBarChart />
                </div>
              </div>
              {/* table */}

              <div className="ht_home_received_resume">
                <h6>Received Resumes</h6>
                <hr />
                <div>
                  {/* implement circle chart here  */}
                  <ResumeRecivedCircleChart />
                </div>
              </div>
            </div>

            <div className="hr_dashboard_second_container">
              <div className="hr_dashboard_recent_jobs_table">
                <div className="_recent_jobs_header">Recent Jobs</div>
                <hr />
                <div className="container">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr className="recent-job-header">
                          <th>Title</th>
                          <th>Vacancies</th>
                          <th>Job Type</th>
                          <th>Work Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td colSpan="5">Loading...</td>
                          </tr>
                        ) : error ? (
                          <tr>
                            <td colSpan="5">{error}</td>
                          </tr>
                        ) : (
                          recentJobs.map((job, index) => (
                            <tr className="recent-job-body" key={index}>
                              <td>{job.title}</td>

                              <td className="text-left">{job.vacancy}</td>
                              <td>{job.type}</td>
                              <td>
                                {job.job_location
                                  ? job.job_location.length > 13
                                    ? job.job_location.slice(0, 13) + "..."
                                    : job.job_location
                                  : "not mention"}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                    {/* <div className="recent_job_view_all">
                    <Link
                      style={{
                        textDecoration: "none",
                        color: " color: #f38d00;",
                      }}
                    >
                      View All
                    </Link>
                  </div> */}
                  </div>
                </div>
              </div>
              {/* upcoming drive */}
              <div className="hr_dashboard_upcoming_drives">
                <h6>Upcoming Drives</h6>
                <hr />
                <div className="hr_dashboard_upcoming_drives_container">
                  <div className="hr_dashboard_upcoming_drives_sub_container">
                    {colleges.map((college, index) => (
                      <div
                        className="hr_dashboard_upcoming_drives_card"
                        key={index}
                      >
                        <div
                          className={`hr_dashboard_upcoming_drives_date_container ${
                            index === 0
                              ? "id_hr_dashboard_upcoming_drives_date_container"
                              : ""
                          } ${
                            index === colleges.length - 1
                              ? "last_id_hr_dashboard_upcoming_drives_date_container"
                              : ""
                          }`}
                        >
                          <p>{college.date.split(" ")[0]}</p>
                          <p>{college.date.split(" ")[1]}</p>
                        </div>
                        <div className="hr_dashboard_upcoming_drives_clg_details">
                          <div className="hr_dashboard_upcoming_drives_clg_img_container">
                            <img
                              src={college.image}
                              alt={`${college.name} Logo`}
                            />
                          </div>
                          <div className="hr_dashboard_upcoming_drives_text_container">
                            <p className="upcoming_drive_clg_title">
                              {college.name}
                            </p>
                            <p className="text-muted">
                              {college.daysRemaining}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HRManagerHome;
