import React, { useEffect, useState, useRef } from "react";
import "../../../styles/dashboards/clgdashboard/campusmanagerprofile.css";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import { Pie } from "react-chartjs-2";
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import Instagram from "../../../utils/landingpage/images/blackinstgram.svg"
import Twitter from "../../../utils/landingpage/images/blacktwiter.svg";
import LinkedIn from "../../../utils/landingpage/images/blacklinkedin.svg";
import editIcon from "../../../utils/dashboards/edit-icon.svg";
import orange_delete_icon from "../../../utils/dashboards/orange_delete_icon.svg";
import dummy_logo from "../../../utils/landingpage/images/user-icon-dummy.png";

function CampusManagerProfile() {
  const [activeForm, setActiveForm] = useState("campusManager");
  const [profileImage, setProfileImage] = useState();
  const [myProfieData, setMyProfileData] = useState([]);
  const [accessToken, setAccessToken] = useState('');
  const [collageId, setCollageId] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAlreadyProfile, setIsAlreadyProfile] = useState(false);
  const [myProfilePercentage, setMyProfilePercentage] = useState(0)
  const [isProfileEdit, setIsProfileEdit] = useState(false);
  const [isAlreadyAdminDetails, setIsAlreadyAdminDetails] = useState(false);
  const [isAdminEdit, setIsAdminEdit] = useState(false);
  const [isAlreadyPlacementDetails, setIsAlreadyPlacementDetails] = useState(false);
  const [isPlacementEdit, setIsPlacementEdit] = useState(false);
  const [isAlreadyPlacementStudentDetails, setIsAlreadyPlacementStudentDetails] = useState(false);
  const [isPlacementStudentEdit, setIsPlacementStudentEdit] = useState(false);
  const [isSocialMediaOpenModel, setIsSocialMediaOpenModel] = useState(false)
  const [isAlreadySocialDataAvailable, setisAlreadySocialDataAvailable] = useState(false);
  const [showSocialMediaRedux, setShowSocialMediaRedux] = useState(false)
  const [showSocialMedia, setShowSocialMedia] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  const [editingValue, setEditingValue] = useState([]);

  const [initialPersonalValuesSet, setInitialPersonalValuesSet] = useState({
    collegeName: false,
    Type: false,
    location: false,
    state: false,
    contactNumber: false,
    establishedYear: false,
    affiliatedUniversity: false,
    city: false,
    country: false,
    websiteUrl: false,
  });

  const [initialValuesSet, setInitialValuesSet] = useState({
    placementOfficerName: false,
    contactNumber: false,
    emailAddress: false,
  });

  const [initialStudentValuesSet, setInitialStudentValuesSet] = useState({
    placementRecord: false,
    internshipRecord: false,
    numberofStudent: false,
    currentYearStudent: false,
  });

  const [initialAdminValuesSet, setInitialAdminValuesSet] = useState({
    principalDeanName: false,
    designation: false,
    adminEmail: false,
    adminContactName: false,
    admincontactNumber: false,
  });

  const [initialSocialValuesSet, setInitialSocialValuesSet] = useState({
    instagram: false,
    twitter: false,
    linkedin: false,
  });

  const [socialErrors, setSocialErrors] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  });

  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  })
  const [editSocialMediaDataRedux, setEditSocialMediaDataRedux] = useState({
    instagram: "",
    twitter: "",
    linkedin: "",
  })

  const handlePersonalFieldChange = (fieldName, value) => {
    if (!initialPersonalValuesSet[fieldName]) {
      setInitialPersonalValuesSet((prev) => ({ ...prev, [fieldName]: true }));
    }
    campusManagerEditFormik.setFieldValue(fieldName, value);
  };

  const validateURL = (url) => {
    const urlRegex = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*\/?$/;
    return urlRegex.test(url);
  };

  const handleSocialFieldChange = (fieldName, value) => {
    if (!initialSocialValuesSet[fieldName]) {
      setInitialSocialValuesSet((prev) => ({ ...prev, [fieldName]: true }));
    }
    setFormData((prev) => ({ ...prev, [fieldName]: value }))

    if (value && !validateURL(value)) {
      setSocialErrors((prev) => ({
        ...prev,
        [fieldName]: "Invalid URL format",
      }));
    } else {
      setSocialErrors((prev) => ({
        ...prev,
        [fieldName]: "",
      }));
    }
  };

  const handleAdminEditChange = (field, value) => {
    if (!initialAdminValuesSet[field]) {
      setInitialAdminValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    administrativeEditFormik.setFieldValue(field, value);
  };

  const handleInputEditChange = (field, value) => {
    if (!initialValuesSet[field]) {
      setInitialValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    placementOfficerEditFormik.setFieldValue(field, value);
  };

  const handleStudentInfoInputChange = (field, value) => {
    if (!initialStudentValuesSet[field]) {
      setInitialStudentValuesSet((prev) => ({ ...prev, [field]: true }));
    }
    placementCellDownEditFormik.setFieldValue(field, value);
  };

  const editSocialMediaDataReduxs = useSelector((state) => state.studentProfile.editSocialMediaDataRedux);

  useEffect(() => {
    const token = sessionStorage.getItem('accessToken');
    const userDetails = sessionStorage.getItem('userDetails');

    const parsedDetails = JSON.parse(userDetails);
    const collegeProfileId = parsedDetails?.college_profile?.user;

    setAccessToken(token)
    setCollageId(collegeProfileId)
  }, [accessToken])

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const handleFormSwitch = (formName) => {
    setActiveForm(formName);
  };

  const placementCellUpValidationSchema = Yup.object().shape({
    placementOfficerName: Yup.string()
      .required(
        "Placement Officer Name is required"
      )
      .matches(/^[A-Za-z\s]+$/, "Placement Officer Name must be alphabetic"),
    contactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
    emailAddress: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const placementCellDownValidationSchema = Yup.object({
    numberofStudent: Yup.string()
      .required("Number of students is required")
      .matches(/^[0-9]+$/, "Total students must be a number"),
    currentYearStudent: Yup.string()
      .required("Current Year students is required")
      .matches(/^[0-9]+$/, "Total students must be a number"),
    placementRecord: Yup.string().required("Placement record is required"),
    internshipRecord: Yup.string().required("Internship record is required"),
    // male: Yup.string()
    //   .required("Male record is required")
    //   .matches(/^[0-9]+$/, "Male record must be a number"),
    // female: Yup.string()
    //   .required("Female record is required")
    //   .matches(/^[0-9]+$/, "Female record must be a number"),
    // others: Yup.string()
    //   .required("Others record is required")
    //   .matches(/^[0-9]+$/, "Others record must be a number"),
  })

  // .test(
  //   "gender-sum-validation",
  //   "The sum of Male, Female, and Others must equal Total Students",
  //   function (values) {
  //     const { numberofStudent, male, female, others } = values;

  //     // Parse values
  //     const totalStudents = parseInt(numberofStudent || "0", 10);
  //     const maleCount = parseInt(male || "0", 10);
  //     const femaleCount = parseInt(female || "0", 10);
  //     const othersCount = parseInt(others || "0", 10);

  //     const totalGender = maleCount + femaleCount + othersCount;

  //     if (totalStudents !== totalGender) {
  //       return this.createError({
  //         path: "male", // Error will show under 'male' field
  //         message: "The sum of Male, Female, and Others must equal Total Students",
  //       });
  //     }

  //     return true; // Pass validation
  //   }
  // );

  const campusManagerValidationSchema = Yup.object().shape({
    establishedYear: Yup.number()
      .required("Established Year is required")
      .min(1800, "Year must be after 1800")
      .max(new Date().getFullYear(), "Year cannot be in the future"),
    Type: Yup.string().required("Type is required"),
    affiliatedUniversity: Yup.string().required(
      "Affiliated University is required"
    ),
    location: Yup.string().required("location is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("state is required"),
    country: Yup.string().required("Country is required"),
    contactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
    // emailAddress: Yup.string()
    //   .required("Email Address is required")
    //   .email("Invalid Email Address")
    //   .matches(
    //     /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //     "Email Address must end with a valid domain like .com, .in"
    //   ),
    websiteUrl: Yup.string().url("Invalid URL"),
  });

  const campusManagerFormik = useFormik({
    initialValues: {
      collegeName: myProfieData?.college_name,
      establishedYear: "",
      Type: "",
      affiliatedUniversity: "",
      location: "",
      city: "",
      state: "",
      country: "",
      contactNumber: "",
      emailAddress: "",
      websiteUrl: "",
    },
    validationSchema: campusManagerValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        college_name: myProfieData?.college_name,
        established_year: values.establishedYear,
        college_type: values.Type.toLowerCase(),
        affiliated_by: values.affiliatedUniversity,
        college_website: values.websiteUrl,
        location: values.location,
        address: values.location,
        city: values.city,
        state: values.state,
        country: values.country,
        college_contact: values.contactNumber || myProfieData?.college_contact,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyProfile(true)
          setMyProfileData(response?.data)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const EditProfilevalidationSchema = Yup.object({
    establishedYear: Yup.number()
      .max(new Date().getFullYear(), "Established year cannot be greater than the current year"),
    websiteUrl: Yup.string()
      .url("Please enter a valid URL"),
    contactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number")
  });

  const campusManagerEditFormik = useFormik({
    initialValues: {
      collegeName: myProfieData?.college_name || "",
      establishedYear: myProfieData?.established_year || "",
      Type: myProfieData?.college_type || "",
      affiliatedUniversity: myProfieData?.affiliated_by || "",
      location: myProfieData?.location || "",
      city: myProfieData?.city || "",
      state: myProfieData?.state || "",
      country: myProfieData?.country || "",
      contactNumber: myProfieData?.college_contact || "",
      emailAddress: myProfieData?.college_email || "",
      websiteUrl: myProfieData?.college_website || "",
    },
    validationSchema: EditProfilevalidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        college_name: values.collegeName || myProfieData?.college_name,
        established_year: values.establishedYear || myProfieData?.established_year,
        college_type: values.Type.toLowerCase() || myProfieData?.college_type,
        affiliated_by: values.affiliatedUniversity || myProfieData?.affiliated_by,
        college_website: values.websiteUrl || myProfieData?.college_website,
        location: values.location || myProfieData?.location,
        address: values.location || myProfieData?.location,
        city: values.city || myProfieData?.city,
        state: values.state || myProfieData?.state,
        country: values.country || myProfieData?.country,
        college_contact: values.contactNumber || myProfieData?.college_contact,
        college_email: myProfieData?.college_email || "",
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyProfile(true);
          setIsProfileEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handleEditClick = () => {
    setIsAlreadyProfile(false);
    setIsProfileEdit(true)
  }

  const handleEditAdminClick = () => {
    setIsAlreadyAdminDetails(false);
    setIsAdminEdit(true);
  }

  const handleEditPlacementOfficerClick = () => {
    setIsAlreadyPlacementDetails(false);
    setIsPlacementEdit(true);
  }

  const handleEditPlacementStudentClick = () => {
    setIsAlreadyPlacementStudentDetails(false);
    setIsPlacementStudentEdit(true)
  }

  const handleEditAdminCancel = () => {
    setIsAlreadyAdminDetails(true);
    setIsAdminEdit(false);
  }

  const handleAdminDelete = useFormik({
    initialValues: {
      principal_name: '',
      administrative_name: '',
      administrative_designation: '',
      administrative_email: '',
      administrative_contact: '',
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        principal_name: null,
        administrative_name: null,
        administrative_designation: null,
        administrative_email: null,
        administrative_contact: null,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("Administrative deleted successfully!");
          setMyProfileData(response.data);
          setIsAlreadyAdminDetails(false);
          setIsAdminEdit(false);
          fetchCollegeProfile()
          administrativeDetailsFormik.resetForm();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  const handlePlacementOfficerDelete = useFormik({
    initialValues: {
      placementOfficerName: "",
      contactNumber: "",
      emailAddress: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        placement_officer: null,
        officer_contact: null,
        officer_email: null
      }

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("Placement Deleted successfully!");
          setIsAlreadyPlacementDetails(false)
          setIsPlacementEdit(false)
          fetchCollegeProfile()
          placementCellUpFormik.resetForm()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const handlePlacementRecordDelete = useFormik({
    initialValues: {
      numberofStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
      male: "",
      female: "",
      others: "",
    },
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        total_student: null,
        gender_ratio: null,
        placement_record: null,
        internship_record: null,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("Placement Deleted successfully!");
          setIsAlreadyPlacementStudentDetails(false)
          setIsPlacementStudentEdit(false)
          fetchCollegeProfile()
          placementCellDownFormik.resetForm()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const handleEditProfileCancel = () => {
    setIsAlreadyProfile(true);
    setIsProfileEdit(false);
  }

  const handleEditPlacementOfficerCancel = () => {
    setIsAlreadyPlacementDetails(true);
    setIsPlacementEdit(false);
  }

  const handleEditPlacementStudentCancel = () => {
    setIsAlreadyPlacementStudentDetails(true);
    setIsPlacementStudentEdit(false)
  }

  const administrativeEditValidationSchema = Yup.object().shape({
    principalDeanName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Principal/Dean Name must be alphabetic"),
    designation: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Designation must be alphabetic"),
    adminContactName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Administrative Contact Person must be alphabetic"),
    admincontactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
    adminEmail: Yup.string()
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const administrativeEditFormik = useFormik({
    initialValues: {
      principalDeanName: myProfieData?.principal_name || "",
      designation: myProfieData?.administrative_designation || "",
      adminContactName: myProfieData?.administrative_name || "",
      admincontactNumber: myProfieData?.administrative_contact || "",
      adminEmail: myProfieData?.administrative_email || "",
    },
    validationSchema: administrativeEditValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        principal_name: values.principalDeanName || myProfieData?.principal_name,
        administrative_name: values.adminContactName || myProfieData?.administrative_name,
        administrative_designation: values.designation || myProfieData?.administrative_designation,
        administrative_email: values.adminEmail || myProfieData?.administrative_email,
        administrative_contact: values.admincontactNumber || myProfieData?.administrative_contact,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyAdminDetails(true);
          setIsAdminEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });

  const administrativeDetailsValidationSchema = Yup.object().shape({
    principalDeanName: Yup.string()
      .required("Principal/Dean Name is required")
      .matches(/^[A-Za-z\s]+$/, "Principal/Dean Name must be alphabetic"),
    designation: Yup.string()
      .required("Designation is required")
      .matches(/^[A-Za-z\s]+$/, "Designation must be alphabetic"),
    adminContactName: Yup.string()
      .required("Administrative Contact Person is required")
      .matches(/^[A-Za-z\s]+$/, "Administrative Contact Person must be alphabetic"),
    admincontactNumber: Yup.string()
      .required("Contact Number is required")
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
    adminEmail: Yup.string()
      .required("Email Address is required")
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const administrativeDetailsFormik = useFormik({
    initialValues: {
      principalDeanName: "",
      designation: "",
      adminContactName: "",
      admincontactNumber: "",
      adminEmail: "",
    },
    validationSchema: administrativeDetailsValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        principal_name: values.principalDeanName,
        administrative_name: values.adminContactName,
        administrative_designation: values.designation,
        administrative_email: values.adminEmail,
        administrative_contact: values.admincontactNumber,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyAdminDetails(true)
          fetchCollegeProfile()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const placementCellUpFormik = useFormik({
    initialValues: {
      placementOfficerName: "",
      contactNumber: "",
      emailAddress: "",
    },
    validationSchema: placementCellUpValidationSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        placement_officer: values.placementOfficerName,
        officer_contact: values.contactNumber,
        officer_email: values.emailAddress
      }

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile created successfully!");
          setIsAlreadyPlacementDetails(true)
          fetchCollegeProfile()
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
        toast.error(error || "Failed to create college profile. Please try again.");
      }
    },
  });

  const placementCellUpEditValidationSchema = Yup.object().shape({
    placementOfficerName: Yup.string()
      .matches(/^[A-Za-z\s]+$/, "Placement Officer Name must be alphabetic"),
    contactNumber: Yup.string()
      .matches(/^[6-9]\d{9}$/, "Please enter a valid Indian phone number"),
    emailAddress: Yup.string()
      .email("Invalid Email Address")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Email Address must end with a valid domain like .com, .in"
      ),
  });

  const placementOfficerEditFormik = useFormik({
    initialValues: {
      placementOfficerName: myProfieData?.placement_officer || "",
      contactNumber: myProfieData?.officer_contact || "",
      emailAddress: myProfieData?.officer_email || "",
    },
    validationSchema: placementCellUpEditValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        placement_officer: values.placementOfficerName || myProfieData?.placement_officer,
        officer_contact: values.contactNumber || myProfieData?.officer_contact,
        officer_email: values.emailAddress || myProfieData?.officer_email,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementDetails(true);
          setIsPlacementEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });

  const placementCellDownFormik = useFormik({
    initialValues: {
      numberofStudent: "",
      currentYearStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
      male: "",
      female: "",
      others: "",
    },
    validationSchema: placementCellDownValidationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        previous_total_student: values.numberofStudent || myProfieData?.previous_total_student,
        current_total_student: values.currentYearStudent || myProfieData?.current_total_student,
        gender_ratio: null,
        previous_placement_record: values.placementRecord || myProfieData?.placement_record,
        previous_internship_record: values.internshipRecord || myProfieData?.internship_record,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementStudentDetails(true);
          setIsPlacementStudentEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });

  // const parseGenderRatio = (genderRatio) => {
  //   const maleMatch = genderRatio?.match(/male(\d+)/);
  //   const femaleMatch = genderRatio?.match(/female(\d+)/);
  //   const othersMatch = genderRatio?.match(/others(\d+)/);

  //   return {
  //     male: maleMatch ? maleMatch[1] : "",
  //     female: femaleMatch ? femaleMatch[1] : "",
  //     others: othersMatch ? othersMatch[1] : "",
  //   };
  // };

  // const genderValues = parseGenderRatio(myProfieData?.gender_ratio);

  const placementCellDownEditFormik = useFormik({
    initialValues: {
      numberofStudent: "",
      currentYearStudent: "",
      genderRatio: "",
      placementRecord: "",
      internshipRecord: "",
      male: "",
      female: "",
      others: "",
    },
    onSubmit: async (values) => {
      setLoading(true);
      if (!accessToken) {
        toast.error("Access token is missing. Please log in again.");
        setLoading(false);
        return;
      }

      const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`;

      const payload = {
        gender_ratio: null,
        previous_internship_record: values.internshipRecord || myProfieData?.previous_internship_record,
        previous_placement_record: values.placementRecord || myProfieData?.previous_placement_record,
        previous_total_student: values.numberofStudent || myProfieData?.previous_total_student,
        current_total_student: values.currentYearStudent || myProfieData?.current_total_student,
      };

      try {
        const response = await axios.put(apiUrl, payload, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (response?.data) {
          toast.success("College profile updated successfully!");
          setMyProfileData(response.data);
          setIsAlreadyPlacementStudentDetails(true);
          setIsPlacementStudentEdit(false)
        }
      } catch (error) {
        toast.error(error?.response?.data?.message || "Failed to update college profile. Please try again.");
      } finally {
        setLoading(false); // Ensure loading state is turned off
      }
    },
  });

  const fileInputRef = useRef(null);

  const handleImageUploadSubmit = async () => {
    const userDetails = sessionStorage.getItem('userDetails');
    if (!userDetails) {
      toast.error('User details not found in sessionStorage.');
      return;
    }

    const parsedDetails = JSON.parse(userDetails);
    const collegeProfileId = parsedDetails?.college_profile?.user;

    try {
      const file = fileInputRef.current.files[0];
      if (!file) return alert("Please select an image to upload.");

      const maxSizeInBytes = 2 * 1024 * 1024;
      if (file.size > maxSizeInBytes) {
        toast.error("File size exceeds the maximum limit of 2MB.");
        return;
      }

      const formData = new FormData();
      formData.append("college_logo", file);

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collegeProfileId}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response.data) {
        FetchProfilePercentage();
        fetchCollegeProfile();
        toast.success("Added Successfully");
        sessionStorage.setItem("userImage", response.data?.college_logo_url)
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Failed to upload image");
      fetchCollegeProfile()
    }
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    if (file) {
      const maxSizeInBytes = 2 * 1024 * 1024;

      if (file.size > maxSizeInBytes) {
        toast.error("File size exceeds the maximum limit of 2MB.");
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => setProfileImage(e.target.result);
      reader.readAsDataURL(file);
      handleImageUploadSubmit();
    }
  };

  const handleRemoveImage = async () => {
    const userDetails = sessionStorage.getItem('userDetails');
    if (!userDetails) {
      toast.error('User details not found in sessionStorage.');
      return;
    }

    const parsedDetails = JSON.parse(userDetails);
    const collegeProfileId = parsedDetails?.college_profile?.user;

    try {

      const paylaod = {
        college_logo: null,
        college_logo_url: null
      }

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collegeProfileId}/`,
        paylaod,
        {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
          },
        }
      );

      if (response) {
        fetchCollegeProfile()
        FetchProfilePercentage();
        setProfileImage(null);
        toast.success("Image removed successfully");
      }
      console.log('----stateses resoose', response)
    } catch (error) {
      console.error("Error removing image:", error);
      toast.error("Failed to remove image");
    }
  };

  const fetchCollegeProfile = async () => {
    const userDetails = sessionStorage.getItem('userDetails');
    if (!userDetails) {
      toast.error('User details not found in sessionStorage.');
      return;
    }

    const parsedDetails = JSON.parse(userDetails);
    const accessToken = sessionStorage.getItem('accessToken');

    const collegeProfileId = parsedDetails?.college_profile?.user;

    if (!collegeProfileId) {
      toast.error('College profile ID not found in userDetails.');
      return;
    }

    const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collegeProfileId}/`;

    if (accessToken) {
      try {
        const response = await axios.get(apiUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        setMyProfileData(response.data);

        sessionStorage.setItem("userImage", response.data?.college_logo_url)

        if (
          response?.data?.established_year &&
          response?.data?.location &&
          response?.data?.state
        ) {
          setIsAlreadyProfile(true);
        }
        if (
          response?.data?.principal_name &&
          response?.data?.administrative_designation &&
          response?.data?.administrative_contact &&
          response?.data?.administrative_email &&
          response?.data?.administrative_name
        ) {
          setIsAlreadyAdminDetails(true);
        }

        if (response.data?.placement_officer && response.data?.officer_contact && response.data?.officer_email) {
          setIsAlreadyPlacementDetails(true)
        }
        if (response.data?.total_student && response.data?.placement_record && response.data?.internship_record) {
          setIsAlreadyPlacementStudentDetails(true)
        }
        if (response.data?.social_media_links?.length > 0) {
          setisAlreadySocialDataAvailable(true);
          setShowSocialMediaRedux(true);

          const socialMediaData = {
            instagram: response.data?.social_media_links.find(link => link.platform === 'instagram')?.url || '',
            twitter: response.data?.social_media_links.find(link => link.platform === 'twitter')?.url || '',
            linkedin: response.data?.social_media_links.find(link => link.platform === 'linkedin')?.url || '',
          };
          setEditSocialMediaDataRedux(socialMediaData)
        }
        if (response.data?.social_media_links?.length < 1) {
          setIsSocialMediaOpenModel(false)
          setisAlreadySocialDataAvailable(false)
          setFormData({
            ...formData,
            twitter: "",
            instagram: "",
            linkedin: "",
          })
        }

        FetchProfilePercentage()

      } catch (error) {
        toast.error(error.message || 'Something Went Wrong.');
      }
    } else {
      toast.error('Access token not found.');
    }
  };

  useEffect(() => {
    fetchCollegeProfile();
  }, []);

  const handleEditSocialMedia = (link) => {
    setIsSocialMediaOpenModel(true);
    // setIsAlreadySocialData(true);
    // dispatch(setIsAlreadySocialDataAvailable(true))
    setisAlreadySocialDataAvailable(true)
    setEditingValue(link)
    // setShowSocialMedia(false);

    // dispatch(setShowSocialMediaRedux(false))
    setShowSocialMediaRedux(false)
  }

  const socialMediavalidationSchema = Yup.object({
    instagram: Yup.string()
      .url("Please enter a valid Instagram URL")
      .nullable(),
    twitter: Yup.string()
      .url("Please enter a valid Twitter URL")
      .nullable(),
    linkedin: Yup.string()
      .url("Please enter a valid LinkedIn URL")
      .nullable(),
  });

  const handleAddSocialMedia = async (e) => {
    e.preventDefault();

    try {
      await socialMediavalidationSchema.validate(formData, { abortEarly: false });

      if (!formData.instagram && !formData.twitter && !formData.linkedin) {
        toast.error("Please add at least one social media link");
        return;
      }

      const accessToken = sessionStorage.getItem("accessToken");

      const payload = {
        social_media_links: [
          { platform: "instagram", url: formData.instagram || '' },
          { platform: "twitter", url: formData.twitter || '' },
          { platform: "linkedin", url: formData.linkedin || '' },
        ].filter((link) => link.url),
      };

      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Added Successfully");
        fetchCollegeProfile();
        setisAlreadySocialDataAvailable(true);
        setIsSocialMediaOpenModel(false);
        setShowSocialMediaRedux(true);
      }
    } catch (error) {
      if (error.inner) {
        error.inner.forEach((err) => toast.error(err.message));
      } else {
        console.error("Error in API:", error);
      }
    }
  };


  // const handleAddSocialMedia = async (e) => {
  //   e.preventDefault();

  //   const accessToken = sessionStorage.getItem("accessToken");

  //   validationSchema: socialMediavalidationSchema,

  //   if (!formData.instagram && !formData.twitter && !formData.linkedin) {
  //     toast.error("Please add at least one social media link");
  //     return;
  //   }

  //   const payload = {
  //     social_media_links: [
  //       { platform: "instagram", url: formData.instagram || '' },
  //       { platform: "twitter", url: formData.twitter || '' },
  //       { platform: "linkedin", url: formData.linkedin || '' },
  //     ].filter((link) => link.url)
  //   };

  //   const config = {
  //     headers: {
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //   };

  //   try {
  //     const response = await axios.put(
  //       `${process.env.REACT_APP_BASE_URL}/api/college_profile/${collageId}/`,
  //       payload,
  //       config
  //     );

  //     if (response.status === 200) {
  //       toast.success("Added Successfully");
  //       fetchCollegeProfile()
  //       setisAlreadySocialDataAvailable(true)
  //       setIsSocialMediaOpenModel(false);
  //       setShowSocialMediaRedux(true)
  //     }
  //   } catch (error) {
  //     console.error("Error in API:", error);
  //   }
  // };

  const handleEditSocialMediaCancel = () => {
    // dispatch(setIsAlreadySocialDataAvailable(true));
    setisAlreadySocialDataAvailable(true)
    setIsSocialMediaOpenModel(false);
    // dispatch(setShowSocialMediaRedux(true))
    setShowSocialMediaRedux(true)
  }


  const handleEditSocialMediaSubmit = async () => {

    const accessToken = sessionStorage.getItem("accessToken");
    const userSessionData = sessionStorage.getItem("userDetails");
    const parsedData = JSON.parse(userSessionData);
    const userId = parsedData?.college_profile?.user;

    const socialMediaLinks = [
      {
        platform: "instagram",
        url: formData.instagram || myProfieData.social_media_links?.find(link => link.platform === "instagram")?.url || "",
      },
      {
        platform: "twitter",
        url: formData.twitter || myProfieData.social_media_links?.find(link => link.platform === "twitter")?.url || "",
      },
      {
        platform: "linkedin",
        url: formData.linkedin || myProfieData.social_media_links?.find(link => link.platform === "linkedin")?.url || "",
      }
    ].filter(link => link.url);

    const payload = {
      social_media_links: socialMediaLinks
    };

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json'
      }
    };

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/api/college_profile/${userId}/`,
        payload,
        config
      );

      if (response.status === 200) {
        toast.success("Social media links updated successfully");
        // fetchData();
        // dispatch(fetchStudentProfileData())
        fetchCollegeProfile()
        setIsSocialMediaOpenModel(false);
        // setIsAlreadySocialData(true);
        // dispatch(setIsAlreadySocialDataAvailable(true))
        setisAlreadySocialDataAvailable(true)
        // showSocialMedia(true);
        // dispatch(setShowSocialMediaRedux(false))
        setShowSocialMediaRedux(false)
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const handleDeleteSocialMedia = async (id) => {
    const accessToken = sessionStorage.getItem("accessToken");

    const config = {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };

    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/all_profile/social-media-links/${id}/`,
        config
      );

      if (response.status) {
        toast.success("Deleted Successfully");
        // fetchData();
        fetchCollegeProfile()
        // setIsAlreadySocialData(true);
        // setIsSocialMediaOpenModel(false)
      }
    } catch (error) {
      console.error("Error in API:", error);
      toast.error(error.response?.data?.message || "Failed to delete social media link");
    }
  };

  const FetchProfilePercentage = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
        }
      }

      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/college_profile_completion/`, config);

      if (response.status === 200) {
        const completionValue = parseInt(
          response.data?.profile_completion?.replace("%", "")
        );
        setMyProfilePercentage(completionValue);
      }
    }
    catch (err) {
      toast.error(err)
    }
  }

  useEffect(() => {
    FetchProfilePercentage()
  }, [])

  const remainingCompletion = 100 - (myProfilePercentage || 0);

  const pieChartData = {
    datasets: [
      {
        data: [myProfilePercentage || 0, remainingCompletion],
        backgroundColor: ["#4CAF50", "#E0E0E0"],
        borderWidth: 0,
      },
    ],
  };

  const pieChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",
    plugins: {
      legend: { display: false },
      tooltip: { enabled: false },
    },
  };

  const renderCampusManagerForm = () => (
    <div className="clg-profile-creation-left-section">
      <form onSubmit={campusManagerFormik.handleSubmit}>
        <div className="clg-profile-creation-profile-section">
          <img
            src={profileImage || myProfieData?.college_logo_url || dummy_logo}
            alt="Profile"
            className="clg-profile-creation-profile-picture"
          />
          <div className="profile-section-sometext">
            <h6>Upload Your College Logo</h6>
            <p>Your photo should be in PNG or JPG format</p>
            <input
              type="file"
              ref={fileInputRef}
              className="hidden"
              onChange={handleImageUpload}
              accept="image/*"
            />
            <div className="d-flex gap-4">
              <label onClick={() => fileInputRef.current.click()} htmlFor="fileInput" className="clg-custom-file-upload">
                Choose Image
              </label>
              <label onClick={handleRemoveImage} className="clg-custom-file-upload clg-remove-button-profile">
                Remove Image
              </label>
            </div>
          </div>
        </div>

        {isAlreadyProfile === false && isProfileEdit === false && (<div>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="collegeName"
                placeholder="College Name"
                className="clg-profile-input"
                value={myProfieData?.college_name}
                disabled
                style={{ cursor: "not-allowed" }}
              />

              <div className="epf-input-wrapper pt-2">
                <select
                  name="Type"
                  value={campusManagerFormik.values.Type}
                  onChange={campusManagerFormik.handleChange}
                  className={`epf-input-field ${campusManagerFormik.errors.Type ? "input-error" : ""
                    }`}
                  style={{ border: '1px solid rgb(173 168 168)' }}
                >
                  <option value="">Type</option>
                  <option value="government">Government</option>
                  <option value="private">Private</option>
                </select>
              </div>
              {campusManagerFormik.touched.Type &&
                campusManagerFormik.errors.Type ? (
                <div className="errorprofile">{campusManagerFormik.errors.Type} </div>
              ) : null}
              <input
                type="text"
                name="location"
                placeholder="location"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.location}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.location &&
                campusManagerFormik.errors.location ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.location}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="state"
                placeholder="State"
                className="clg-profile-input"
                value={campusManagerFormik.values.state}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.state &&
                campusManagerFormik.errors.state ? (
                <div className="errorprofile">{campusManagerFormik.errors.state} </div>
              ) : null}
              <input
                type="text"
                name="contactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.contactNumber || myProfieData?.college_contact}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.contactNumber &&
                campusManagerFormik.errors.contactNumber ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.contactNumber}{" "}
                </div>
              ) : null}

            </div>
            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="establishedYear"
                placeholder="Established Year"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.establishedYear}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.establishedYear &&
                campusManagerFormik.errors.establishedYear ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.establishedYear}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="affiliatedUniversity"
                placeholder="Affiliated University"
                className="clg-profile-input"
                onBlur={campusManagerFormik.handleBlur}
                value={campusManagerFormik.values.affiliatedUniversity}
                onChange={campusManagerFormik.handleChange}
              />
              {campusManagerFormik.touched.affiliatedUniversity &&
                campusManagerFormik.errors.affiliatedUniversity ? (
                <div className="errorprofile">
                  {campusManagerFormik.errors.affiliatedUniversity}
                </div>
              ) : null}
              <input
                type="text"
                name="city"
                placeholder="City"
                className="clg-profile-input"
                value={campusManagerFormik.values.city}
                onBlur={campusManagerFormik.handleBlur}
                onChange={campusManagerFormik.handleChange}
              />{" "}
              {campusManagerFormik.touched.city &&
                campusManagerFormik.errors.city ? (
                <div className="errorprofile">{campusManagerFormik.errors.city} </div>
              ) : null}
              <input
                type="text"
                name="country"
                placeholder="Country"
                className="clg-profile-input"
                value={campusManagerFormik.values.country}
                onChange={campusManagerFormik.handleChange}
                onBlur={campusManagerFormik.handleBlur}
              />{" "}
              {campusManagerFormik.touched.country &&
                campusManagerFormik.errors.country ? (
                <div className="errorprofile">{campusManagerFormik.errors.country} </div>
              ) : null}
              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="clg-profile-input"
                value={myProfieData?.college_email}
                style={{ cursor: "not-allowed" }}
              />{" "}
            </div>
          </div>
          <div className="pt-2">
            <input
              type="text"
              name="websiteUrl"
              placeholder="Website Url"
              className="clg-profile-input"
              onBlur={campusManagerFormik.handleBlur}
              value={campusManagerFormik.values.websiteUrl}
              onChange={campusManagerFormik.handleChange}
            />{" "}
            {campusManagerFormik.touched.websiteUrl &&
              campusManagerFormik.errors.websiteUrl ? (
              <div className="errorprofile">
                {campusManagerFormik.errors.websiteUrl}{" "}
              </div>
            ) : null}
          </div>

          {loading ?
            (<button
              className="profile-creation-save-continue-button"
            >
              <div className="spinner"></div>
            </button>) :
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
              onClick={() => campusManagerFormik.handleSubmit}
            >
              Save
            </button>)}
        </div>)}

        {isAlreadyProfile === true && isProfileEdit === false && (<div style={{ position: 'relative' }}>
          <div className="epf_edit_Details_button student_epf_details_button pt-4" onClick={handleEditClick}>
            <b>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                  fill="#F38D00"
                />
              </svg>
              <span className="pl-4"> Edit Details</span>{" "}
            </b>
          </div>
          <div className="pt-4">
            <div className="submitted-data-container student_submitted_data_contianer">

              <div className="submitted-data-column">
                <p>
                  <b>College Name:</b> {myProfieData?.college_name || "N/A"}
                </p>
                <p>
                  <b>Affiliated by:</b> {myProfieData?.affiliated_by || "N/A"}
                </p>
                <p>
                  <b>Established Year:</b> {myProfieData?.established_year || "N/A"}
                </p>
                <p>
                  <b>Type:</b> {myProfieData?.college_type || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Location:</b> {myProfieData?.location || "N/A"}
                </p>
                <p>
                  <b>Address:</b> {myProfieData?.address || "N/A"}
                </p>
                <p>
                  <b>city:</b> {myProfieData?.city || "N/A"}
                </p>
                <p>
                  <b>state:</b>{" "}
                  {myProfieData?.state || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Email:</b> {myProfieData?.college_email || "N/A"}
                </p>
                <p>
                  <b>Contact:</b> {myProfieData?.college_contact || "N/A"}
                </p>
                <p>
                  <b>College UID:</b>{" "}
                  {`${myProfieData?.college_uid || "N/A"} LPA`}
                </p>
              </div>
            </div>
          </div>
        </div>)}

        {isProfileEdit === true && (<div>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="collegeName"
                placeholder="College Name"
                className="clg-profile-input"
                value={myProfieData?.college_name || ""}
                disabled
                style={{ cursor: "not-allowed" }}
              />

              <div className="epf-input-wrapper pt-2">
                <select
                  name="Type"
                  value={
                    !initialPersonalValuesSet.Type
                      ? myProfieData?.college_type || ""
                      : campusManagerEditFormik.values.Type
                  }
                  onChange={(e) => handlePersonalFieldChange("Type", e.target.value)}
                  className={`epf-input-field ${campusManagerEditFormik.errors.Type ? "input-error" : ""
                    }`}
                  style={{ border: '1px solid rgb(173 168 168)' }}
                >
                  <option value="">Type</option>
                  <option value="government">Government</option>
                  <option value="private">Private</option>
                </select>
              </div>

              <input
                type="text"
                name="location"
                placeholder="location"
                className="clg-profile-input"
                onBlur={campusManagerEditFormik.handleBlur}
                value={
                  !initialPersonalValuesSet.location
                    ? myProfieData?.location || ""
                    : campusManagerEditFormik.values.location
                }
                onChange={(e) => handlePersonalFieldChange("location", e.target.value)}
              />

              <input
                type="text"
                name="state"
                placeholder="State"
                className="clg-profile-input"
                onBlur={campusManagerEditFormik.handleBlur}
                value={
                  !initialPersonalValuesSet.state
                    ? myProfieData?.state || ""
                    : campusManagerEditFormik.values.state
                }
                onChange={(e) => handlePersonalFieldChange("state", e.target.value)}
              />

              <div>
                <input
                  type="text"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="clg-profile-input"
                  onBlur={campusManagerEditFormik.handleBlur}
                  value={
                    !initialPersonalValuesSet.contactNumber
                      ? myProfieData?.college_contact || ""
                      : campusManagerEditFormik.values.contactNumber
                  }
                  onChange={(e) => handlePersonalFieldChange("contactNumber", e.target.value)}
                />
                {campusManagerEditFormik.touched.contactNumber && campusManagerEditFormik.errors.contactNumber && (
                  <div className="error-message pt-4">{campusManagerEditFormik.errors.contactNumber}</div>
                )}
              </div>

            </div>
            <div className="clg-profile-creation-right-inputs">

              <div>
                <input
                  type="number"
                  name="establishedYear"
                  placeholder="Established Year"
                  className="clg-profile-input"
                  onBlur={campusManagerEditFormik.handleBlur}
                  value={
                    !initialPersonalValuesSet.establishedYear
                      ? myProfieData?.established_year || ""
                      : campusManagerEditFormik.values.establishedYear
                  }
                  onChange={(e) => handlePersonalFieldChange("establishedYear", e.target.value)}
                />
                {campusManagerEditFormik.touched.establishedYear && campusManagerEditFormik.errors.establishedYear && (
                  <div className="error-message pt-4">{campusManagerEditFormik.errors.establishedYear}</div>
                )}
              </div>

              <input
                type="text"
                name="affiliatedUniversity"
                placeholder="Affiliated University"
                className="clg-profile-input"
                onBlur={campusManagerEditFormik.handleBlur}
                value={
                  !initialPersonalValuesSet.affiliatedUniversity
                    ? myProfieData?.affiliated_by || ""
                    : campusManagerEditFormik.values.affiliatedUniversity
                }
                onChange={(e) => handlePersonalFieldChange("affiliatedUniversity", e.target.value)}
              />

              <input
                type="text"
                name="city"
                placeholder="City"
                className="clg-profile-input"
                onBlur={campusManagerEditFormik.handleBlur}
                value={
                  !initialPersonalValuesSet.city
                    ? myProfieData?.city || ""
                    : campusManagerEditFormik.values.city
                }
                onChange={(e) => handlePersonalFieldChange("city", e.target.value)}
              />{" "}


              <input
                type="text"
                name="country"
                placeholder="Country"
                className="clg-profile-input"
                onBlur={campusManagerEditFormik.handleBlur}
                value={
                  !initialPersonalValuesSet.country
                    ? myProfieData?.country || ""
                    : campusManagerEditFormik.values.country
                }
                onChange={(e) => handlePersonalFieldChange("country", e.target.value)}
              />{" "}

              <input
                type="email"
                name="emailAddress"
                placeholder="Email Address"
                className="clg-profile-input"
                value={myProfieData?.college_email || ""}
                style={{ cursor: "not-allowed" }}
              />{" "}
            </div>
          </div>

          <div className="pt-2">
            <input
              type="text"
              name="websiteUrl"
              placeholder="Website Url"
              className="clg-profile-input"
              onBlur={campusManagerEditFormik.handleBlur}
              value={
                !initialPersonalValuesSet.websiteUrl
                  ? myProfieData?.college_website || ""
                  : campusManagerEditFormik.values.websiteUrl
              }
              onChange={(e) => handlePersonalFieldChange("websiteUrl", e.target.value)}
            />{" "}
            {campusManagerEditFormik.touched.websiteUrl && campusManagerEditFormik.errors.websiteUrl && (
              <div className="error-message pt-4">{campusManagerEditFormik.errors.websiteUrl}</div>
            )}
          </div>

          <div className="d-flex gap-4 align-items-center">
            {loading ?
              (<button
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>) :
              (<button
                type="button"
                className="profile-creation-save-continue-button"
                onClick={loading ? null : campusManagerEditFormik.handleSubmit}
                disabled={loading}
              >
                {loading ? <div className="spinner"></div> : "Save"}
              </button>
              )
            }
            <button
              type="button"
              onClick={handleEditProfileCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>

        </div>)}

      </form>
    </div>
  );

  const renderAdministrativeDetailsForm = () => (
    <div className="clg-profile-creation-left-section">
      {isAlreadyAdminDetails === false && isAdminEdit === false && (
        <form onSubmit={administrativeDetailsFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="principalDeanName"
                placeholder="Principal/Dean Name"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.principalDeanName}
                onChange={administrativeDetailsFormik.handleChange}
              />
              {administrativeDetailsFormik.touched.principalDeanName &&
                administrativeDetailsFormik.errors.principalDeanName ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.principalDeanName}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="designation"
                placeholder="Designation"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.designation}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.designation &&
                administrativeDetailsFormik.errors.designation ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.designation}{" "}
                </div>
              ) : null}
              <input
                type="email"
                name="adminEmail"
                placeholder="Email Address"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.adminEmail}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.adminEmail &&
                administrativeDetailsFormik.errors.adminEmail ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.adminEmail}{" "}
                </div>
              ) : null}
            </div>
            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="adminContactName"
                placeholder="Administrative Contact Person"
                className="clg-profile-input"
                onBlur={administrativeDetailsFormik.handleBlur}
                value={administrativeDetailsFormik.values.adminContactName}
                onChange={administrativeDetailsFormik.handleChange}
              />{" "}
              {administrativeDetailsFormik.touched.adminContactName &&
                administrativeDetailsFormik.errors.adminContactName ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.adminContactName}{" "}
                </div>
              ) : null}
              <input
                type="text"
                name="admincontactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                value={administrativeDetailsFormik.values.admincontactNumber}
                onBlur={administrativeDetailsFormik.handleBlur}
                onChange={administrativeDetailsFormik.handleChange}
              />
              {administrativeDetailsFormik.touched.admincontactNumber &&
                administrativeDetailsFormik.errors.admincontactNumber ? (
                <div className="errorprofile">
                  {administrativeDetailsFormik.errors.admincontactNumber}{" "}
                </div>
              ) : null}
            </div>
          </div>
          {loading ?
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
            >
              <div className="spinner"></div>
            </button>)
            :
            (<button
              type="submit"
              className="profile-creation-save-continue-button"
            >
              Save
            </button>)}
        </form>
      )}
      {isAdminEdit === true && (
        <form onSubmit={administrativeEditFormik.handleSubmit}>
          <b>Edit Administrative Details</b>
          <div className="clg-profile-creation-inputs">
            <div className="clg-profile-creation-left-inputs">
              <input
                type="text"
                name="principalDeanName"
                placeholder="Principal/Dean Name"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={
                  !initialAdminValuesSet.principalDeanName
                    ? myProfieData?.principal_name || ""
                    : administrativeEditFormik.values.principalDeanName
                }
                onChange={(e) => handleAdminEditChange("principalDeanName", e.target.value)}
              />
              {administrativeEditFormik.touched.principalDeanName &&
                administrativeEditFormik.errors.principalDeanName && (
                  <div className="errorprofile">
                    {administrativeEditFormik.errors.principalDeanName}
                  </div>
                )}

              <input
                type="text"
                name="designation"
                placeholder="Designation"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={
                  !initialAdminValuesSet.designation
                    ? myProfieData?.administrative_designation || ""
                    : administrativeEditFormik.values.designation
                }
                onChange={(e) => handleAdminEditChange("designation", e.target.value)}
              />
              {administrativeEditFormik.touched.designation &&
                administrativeEditFormik.errors.designation && (
                  <div className="errorprofile">{administrativeEditFormik.errors.designation}</div>
                )}

              <input
                type="email"
                name="adminEmail"
                placeholder="Email Address"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={
                  !initialAdminValuesSet.adminEmail
                    ? myProfieData?.administrative_email || ""
                    : administrativeEditFormik.values.adminEmail
                }
                onChange={(e) => handleAdminEditChange("adminEmail", e.target.value)}
              />
              {administrativeEditFormik.touched.adminEmail &&
                administrativeEditFormik.errors.adminEmail && (
                  <div className="errorprofile">{administrativeEditFormik.errors.adminEmail}</div>
                )}
            </div>

            <div className="clg-profile-creation-right-inputs">
              <input
                type="text"
                name="adminContactName"
                placeholder="Administrative Contact Person"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={
                  !initialAdminValuesSet.adminContactName
                    ? myProfieData?.administrative_name || ""
                    : administrativeEditFormik.values.adminContactName
                }
                onChange={(e) => handleAdminEditChange("adminContactName", e.target.value)}
              />
              {administrativeEditFormik.touched.adminContactName &&
                administrativeEditFormik.errors.adminContactName && (
                  <div className="errorprofile">{administrativeEditFormik.errors.adminContactName}</div>
                )}

              <input
                type="text"
                name="admincontactNumber"
                placeholder="Contact Number"
                className="clg-profile-input"
                onBlur={administrativeEditFormik.handleBlur}
                value={
                  !initialAdminValuesSet.admincontactNumber
                    ? myProfieData?.administrative_contact || ""
                    : administrativeEditFormik.values.admincontactNumber
                }
                onChange={(e) => handleAdminEditChange("admincontactNumber", e.target.value)}
              />
              {administrativeEditFormik.touched.admincontactNumber &&
                administrativeEditFormik.errors.admincontactNumber && (
                  <div className="errorprofile">{administrativeEditFormik.errors.admincontactNumber}</div>
                )}
            </div>
          </div>

          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                Save
              </button>
            )}
            <button
              onClick={handleEditAdminCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>

      )}
      {isAlreadyAdminDetails === true && isAdminEdit === false && (
        <div style={{ position: 'relative' }}>
          <div className="epf_edit_Details_button student_epf_details_button pt-4 d-flex gap-4 align-items-center" >
            <b onClick={handleEditAdminClick}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                  fill="#F38D00"
                />
              </svg>
              <span className="pl-4"> Edit Details</span>{" "}
            </b>
            <span onClick={handleAdminDelete.handleSubmit}>
              <img src={orange_delete_icon} alt="Edit" style={{ width: "16px", cursor: "pointer" }} /> Delete
            </span>
          </div>
          <div className="pt-4">
            <div className="submitted-data-container student_submitted_data_contianer">

              <div className="submitted-data-column">
                <p>
                  <b>Principal Name:</b> {myProfieData?.principal_name || "N/A"}
                </p>
                <p>
                  <b>Designation:</b> {myProfieData?.administrative_designation || "N/A"}
                </p>
                <p>
                  <b>Email Address:</b> {myProfieData?.administrative_email || "N/A"}
                </p>
              </div>
              <div className="submitted-data-column">
                <p>
                  <b>Administrative Contact Person:</b> {myProfieData?.administrative_name || "N/A"}
                </p>
                <p>
                  <b>Contact Number:</b> {myProfieData?.administrative_contact || "N/A"}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );

  // Placement Cell Details Form
  const renderPlacementCellDetailsForm = () => (
    <div style={{ width: "75%" }}>
      {/* First Form section */}
      {isAlreadyPlacementDetails === false && isPlacementEdit === false && (<div className="placement-cell-student-information-section">
        <h5> Placement Cell Detail</h5>
        <form onSubmit={placementCellUpFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementOfficerName"
                  placeholder="Placement Officer Name"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.placementOfficerName}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.placementOfficerName &&
                  placementCellUpFormik.errors.placementOfficerName && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.placementOfficerName}
                    </span>
                  )}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.contactNumber}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.contactNumber &&
                  placementCellUpFormik.errors.contactNumber && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.contactNumber}
                    </span>
                  )}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  className="clg-profile-input"
                  value={placementCellUpFormik.values.emailAddress}
                  onChange={placementCellUpFormik.handleChange}
                  onBlur={placementCellUpFormik.handleBlur}
                />
                {placementCellUpFormik.touched.emailAddress &&
                  placementCellUpFormik.errors.emailAddress && (
                    <span className="errorprofile">
                      {placementCellUpFormik.errors.emailAddress}
                    </span>
                  )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
          >
            Save
          </button>
        </form>
      </div>)}

      {isAlreadyPlacementDetails === true && isPlacementEdit === false && (
        <div className="placement-cell-student-information-section">
          <div style={{ position: 'relative' }}>
            <div className="epf_edit_Details_button student_epf_details_button pt-4 d-flex gap-4" >
              <b onClick={handleEditPlacementOfficerClick}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                    fill="#F38D00"
                  />
                </svg>
                <span className="pl-4"> Edit Details </span>{" "}
              </b>
              <span onClick={handlePlacementOfficerDelete.handleSubmit}>
                <img src={orange_delete_icon} alt="Edit" style={{ width: "16px", cursor: "pointer" }} /> Delete
              </span>
            </div>
            <div className="pt-4">
              <div className="submitted-data-container student_submitted_data_contianer">
                <div className="submitted-data-column">
                  <p>
                    <b>Placement Officer:</b> {myProfieData?.placement_officer || "N/A"}
                  </p>
                  <p>
                    <b>Placement Officer Contact:</b> {myProfieData?.officer_contact || "N/A"}
                  </p>
                  <p>
                    <b>Placement Email Address:</b> {myProfieData?.officer_email || "N/A"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPlacementEdit === true && (<div className="placement-cell-student-information-section">
        <h5>Edit Placement Cell Detail</h5>
        <form onSubmit={placementOfficerEditFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <div className="campus_manager_input_div">
                <input
                  type="text"
                  name="placementOfficerName"
                  placeholder="Placement Officer Name"
                  className="clg-profile-input"
                  value={
                    !initialValuesSet.placementOfficerName
                      ? myProfieData?.placement_officer || ""
                      : placementOfficerEditFormik.values.placementOfficerName
                  }
                  onChange={(e) =>
                    handleInputEditChange("placementOfficerName", e.target.value)
                  }
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
                {placementOfficerEditFormik.touched.placementOfficerName &&
                  placementOfficerEditFormik.errors.placementOfficerName && (
                    <span className="errorprofile">
                      {placementOfficerEditFormik.errors.placementOfficerName}
                    </span>
                  )}
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="contactNumber"
                  placeholder="Contact Number"
                  className="clg-profile-input"
                  value={
                    !initialValuesSet.contactNumber
                      ? myProfieData?.officer_contact || ""
                      : placementOfficerEditFormik.values.contactNumber
                  }
                  onChange={(e) =>
                    handleInputEditChange("contactNumber", e.target.value)
                  }
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
                {placementOfficerEditFormik.touched.contactNumber &&
                  placementOfficerEditFormik.errors.contactNumber && (
                    <span className="errorprofile">
                      {placementOfficerEditFormik.errors.contactNumber}
                    </span>
                  )}
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="email"
                  name="emailAddress"
                  placeholder="Email Address"
                  className="clg-profile-input"
                  value={
                    !initialValuesSet.emailAddress
                      ? myProfieData?.officer_email || ""
                      : placementOfficerEditFormik.values.emailAddress
                  }
                  onChange={(e) => handleInputEditChange("emailAddress", e.target.value)}
                  onBlur={placementOfficerEditFormik.handleBlur}
                />
                {placementOfficerEditFormik.touched.emailAddress &&
                  placementOfficerEditFormik.errors.emailAddress && (
                    <span className="errorprofile">
                      {placementOfficerEditFormik.errors.emailAddress}
                    </span>
                  )}
              </div>
            </div>
          </div>

          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                <div className="spinner"></div>
              </button>
            ) : (
              <button
                type="submit"
                className="profile-creation-save-continue-button"
              >
                Save
              </button>
            )}
            <button
              onClick={handleEditPlacementOfficerCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>)}

      {/* Second Form section */}
      {isAlreadyPlacementStudentDetails === false && isPlacementStudentEdit === false && (<div className="placement-cell-student-information-section">
        <h5>Student Information</h5>
        <form onSubmit={placementCellDownFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs pb-4">
            <div className="placement-cell-inputs">

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="placementRecord"
                  placeholder="Placement Record (Previous year data)"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.placementRecord}
                  onChange={placementCellDownFormik.handleChange}
                />
                {placementCellDownFormik.touched.placementRecord &&
                  placementCellDownFormik.errors.placementRecord ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.placementRecord}
                  </div>
                ) : null}
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="internshipRecord"
                  placeholder="Internship Record (Previous year data)"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.internshipRecord}
                  onChange={placementCellDownFormik.handleChange}
                />
                {placementCellDownFormik.touched.internshipRecord &&
                  placementCellDownFormik.errors.internshipRecord ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.internshipRecord}
                  </div>
                ) : null}
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="numberofStudent"
                  placeholder="Total Number of Students (Previous year data)"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.numberofStudent}
                  onChange={placementCellDownFormik.handleChange}
                  onBlur={placementCellDownFormik.handleBlur}
                />
                {placementCellDownFormik.touched.numberofStudent &&
                  placementCellDownFormik.errors.numberofStudent ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.numberofStudent}
                  </div>
                ) : null}
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="currentYearStudent"
                  placeholder="Total Number of Students (Current year data)"
                  className="clg-profile-input"
                  value={placementCellDownFormik.values.currentYearStudent}
                  onChange={placementCellDownFormik.handleChange}
                  onBlur={placementCellDownFormik.handleBlur}
                />
                {placementCellDownFormik.touched.currentYearStudent &&
                  placementCellDownFormik.errors.currentYearStudent ? (
                  <div className="errorprofile">
                    {placementCellDownFormik.errors.currentYearStudent}
                  </div>
                ) : null}
              </div>

            </div>
          </div>

          <button
            type="submit"
            className="placement-cell-profile-creation-save-button"
          >
            Save
          </button>
        </form>
      </div>)}

      {isAlreadyPlacementStudentDetails === true && isPlacementStudentEdit === false && (
        <div className="placement-cell-student-information-section">
          <div style={{ position: 'relative' }}>
            <div className="epf_edit_Details_button student_epf_details_button pt-4 d-flex gap-4" >
              <b onClick={handleEditPlacementStudentClick}>
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.23933 11.3489C1.80213 11.3489 1.40212 11.1908 1.10445 10.9024C0.750961 10.5489 0.592801 10.0466 0.676522 9.50703L0.936986 7.66517C0.9928 7.26517 1.24398 6.75354 1.53235 6.46517L6.36026 1.63726C8.01607 -0.0185494 9.45793 0.725636 10.3696 1.63726C11.0858 2.35354 11.4114 3.10703 11.337 3.86052C11.2812 4.47447 10.9556 5.05122 10.3696 5.64656L5.54165 10.4745C5.25328 10.7628 4.75094 11.014 4.34164 11.0791L2.4998 11.3396C2.41608 11.3396 2.32306 11.3489 2.23933 11.3489ZM8.36025 2.04657C8.01607 2.04657 7.7091 2.26982 7.35561 2.61401L2.5277 7.44191C2.45329 7.51633 2.34166 7.74889 2.32305 7.85122L2.06259 9.69308C2.05329 9.7861 2.06257 9.86982 2.09978 9.90703C2.13699 9.94424 2.22072 9.95354 2.31374 9.94424L4.15561 9.68377C4.26724 9.66517 4.4905 9.55354 4.56492 9.47912L9.39283 4.65122C9.74631 4.29773 9.93235 3.99075 9.96025 3.71168C9.98816 3.3954 9.80213 3.02331 9.39283 2.61401C8.98352 2.22331 8.65793 2.04657 8.36025 2.04657Z"
                    fill="#F38D00"
                  />
                </svg>
                <span className="pl-4"> Edit Details </span>{" "}
              </b>
              <span onClick={handlePlacementRecordDelete.handleSubmit}>
                <img src={orange_delete_icon} alt="Edit" style={{ width: "16px", cursor: "pointer" }} /> Delete
              </span>
            </div>
            <div className="pt-4">
              <div className="submitted-data-container student_submitted_data_contianer">
                <div className="submitted-data-column">
                  <p>
                    <b>{`Placement Record(Previous year data):`}</b> {myProfieData?.previous_placement_record || "N/A"}
                  </p>
                  <p>
                    <b>{`Internship Record(Previous year data):`}</b> {myProfieData?.previous_internship_record || "N/A"}
                  </p>
                  <p>
                    <b>{`Total Students(Previous Year):`}</b> {myProfieData?.previous_total_student || "N/A"}
                  </p>
                  <p>
                    <b>{`Total Students(Current Year):`}</b> {myProfieData?.current_total_student || "N/A"}
                  </p>
                  {/* <p>
                    <b>Males Students:</b> {genderValues?.male || "N/A"}
                  </p>
                  <p>
                    <b>Female Students:</b> {genderValues?.female || "N/A"}
                  </p>
                  <p>
                    <b>Others:</b> {genderValues?.others || "N/A"}
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isPlacementStudentEdit === true && (<div className="placement-cell-student-information-section">
        <h5>Edit Student Information</h5>
        <form onSubmit={placementCellDownEditFormik.handleSubmit}>
          <div className="clg-profile-creation-inputs">
            <div className="placement-cell-inputs">
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="placementRecord"
                  placeholder="Placement Record (Previous Year Data)"
                  className="clg-profile-input"
                  value={
                    !initialStudentValuesSet.placementRecord
                      ? myProfieData?.previous_placement_record || ""
                      : placementCellDownEditFormik.values.placementRecord
                  }
                  onChange={(e) =>
                    handleStudentInfoInputChange("placementRecord", e.target.value)
                  }
                />
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="internshipRecord"
                  placeholder="Internship Record (Previous Year Data)"
                  className="clg-profile-input"
                  value={
                    !initialStudentValuesSet.internshipRecord
                      ? myProfieData?.previous_internship_record || ""
                      : placementCellDownEditFormik.values.internshipRecord
                  }
                  onChange={(e) =>
                    handleStudentInfoInputChange("internshipRecord", e.target.value)
                  }
                />
              </div>
              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="numberofStudent"
                  placeholder="Total Number of Students (Previous Year Data)"
                  className="clg-profile-input"
                  value={
                    !initialStudentValuesSet.numberofStudent
                      ? myProfieData?.previous_total_student || ""
                      : placementCellDownEditFormik.values.numberofStudent
                  }
                  onChange={(e) =>
                    handleStudentInfoInputChange("numberofStudent", e.target.value)
                  }
                  onBlur={placementCellDownEditFormik.handleBlur}
                />
              </div>

              <div className="campus_manager_input_div">
                <input
                  type="number"
                  name="currentYearStudent"
                  placeholder="Total Number of Students (Current Year Data)"
                  className="clg-profile-input"
                  value={
                    !initialStudentValuesSet.currentYearStudent
                      ? myProfieData?.current_total_student || ""
                      : placementCellDownEditFormik.values.currentYearStudent
                  }
                  onChange={(e) =>
                    handleStudentInfoInputChange("currentYearStudent", e.target.value)
                  }
                  onBlur={placementCellDownEditFormik.handleBlur}
                />
              </div>

              {/* <div>
        <p>Gender Ratio</p>
        <div className="placement-cell-inputs">
          <div className="campus_manager_input_div">
            <input
              type="text"
              name="male"
              placeholder="Male Record"
              className="clg-profile-input"
              value={placementCellDownEditFormik.values.male || genderValues?.male}
              onChange={placementCellDownEditFormik.handleChange}
              onBlur={placementCellDownEditFormik.handleBlur}
            />
          </div>

          <div className="campus_manager_input_div">
            <input
              type="text"
              name="female"
              placeholder="Female Record"
              className="clg-profile-input"
              value={placementCellDownEditFormik.values.female || genderValues?.female}
              onChange={placementCellDownEditFormik.handleChange}
              onBlur={placementCellDownEditFormik.handleBlur}
            />
          </div>

          <div className="campus_manager_input_div">
            <input
              type="text"
              name="others"
              placeholder="Others Record"
              className="clg-profile-input"
              value={placementCellDownEditFormik.values.others || genderValues?.others}
              onChange={placementCellDownEditFormik.handleChange}
              onBlur={placementCellDownEditFormik.handleBlur}
            />
          </div>
        </div>
      </div> */}
            </div>
          </div>

          <div className="d-flex gap-4 align-items-center">
            {loading ? (
              <button type="submit" className="profile-creation-save-continue-button">
                <div className="spinner"></div>
              </button>
            ) : (
              <button type="submit" className="profile-creation-save-continue-button">
                Save
              </button>
            )}
            <button
              onClick={handleEditPlacementStudentCancel}
              className="epf-submit-btn mt-4"
              style={{
                background: "transparent",
                border: "1px solid rgba(243, 141, 0, 1)",
                color: "rgba(243, 141, 0, 1)",
                padding: "10px 15px 10px 15px",
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>)}

    </div>
  );

  // Shared right section in both pages
  const renderRightSection = () => (
    <div className="clg-profile-creation-right-section">
      <div className="clg-profile-creation-top-right">
        <p>Complete Your Profile</p>
        <div className="mr-4 epf-chart-wrapper">
          <div className="epf-chart-container student_campus_canvas_container">
            <Pie data={pieChartData} options={pieChartOptions} />
            <div className='student_campus_canvas_percentage_value'>{`${myProfilePercentage}%` || "0%"}</div>
          </div>
        </div>
      </div>
      <div className="clg-profile-creation-bottom-right">
        <div className=" new_student_campus_profile_media">
          <div className="">
            <div className="social_Sites_Innerdiv">
              <div className="sites_topView pb-4">
                <p>{isSocialMediaOpenModel ? `Edit Social Media Links` : `Social Media Links`}</p>
              </div>
              <div className="sites_downview">
                <div className="sites_down_innerview">

                  {isSocialMediaOpenModel === true && isAlreadySocialDataAvailable === true && showSocialMediaRedux === false && (<>

                    <div className="sites_icon campus_student_sites_icon">
                      <div className="images">
                        <img src={Instagram} alt="Icon" />
                      </div>
                      <div style={{ width: "100%", position: "relative" }}>
                        <span>Instagram</span>
                        <br />
                        <div className="d-grid">
                          <input
                            type="text"
                            name="instagram"
                            // value={"" || formData.instagram || editSocialMediaDataRedux?.instagram}
                            // onChange={handleInputChange}
                            value={
                              !initialSocialValuesSet.instagram
                                ? editSocialMediaDataRedux?.instagram || ""
                                : formData.instagram
                            }
                            onChange={(e) => handleSocialFieldChange("instagram", e.target.value)}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                          <br />
                          {socialErrors.instagram && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.instagram}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sites_icon campus_student_sites_icon mt-2">
                      <div className="images">
                        <img src={Twitter} alt="Twitter" />
                      </div>
                      <div style={{ width: "100%", position: "relative" }}>
                        <span>Twitter</span>
                        <br />
                        <div className="d-grid">
                          <input
                            type="text"
                            name="twitter"
                            // value={formData.twitter || editSocialMediaDataRedux?.twitter}
                            // onChange={handleInputChange}
                            value={
                              !initialSocialValuesSet.twitter
                                ? editSocialMediaDataRedux?.twitter || ""
                                : formData.twitter
                            }
                            onChange={(e) => handleSocialFieldChange("twitter", e.target.value)}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                          {socialErrors.twitter && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.twitter}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="sites_icon campus_student_sites_icon mt-2">
                      <div className="images">
                        <img src={LinkedIn} alt="LinkedIn" />
                      </div>
                      <div style={{ width: "100%", position: "relative" }}>
                        <span>LinkedIn</span>
                        <br />
                        <div className="d-grid">
                          <input
                            type="text"
                            name="linkedin"
                            // value={formData.linkedin || editSocialMediaDataRedux?.linkedin}
                            // onChange={handleInputChange}
                            value={
                              !initialSocialValuesSet.linkedin
                                ? editSocialMediaDataRedux?.linkedin || ""
                                : formData.linkedin
                            }
                            onChange={(e) => handleSocialFieldChange("linkedin", e.target.value)}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                          {socialErrors.linkedin && (
                            <span className="errorprofile new_error_profile">
                              {socialErrors.linkedin}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="pt-4 d-flex gap-4" style={{ paddingLeft: "2.5rem" }}>
                      <button onClick={handleEditSocialMediaSubmit} className="student_epf_submit_bttn mt-0">
                        Save
                      </button>
                      <button
                        onClick={handleEditSocialMediaCancel}
                        className="student_epf_submit_bttn mt-0"
                        style={{
                          background: "transparent",
                          border: "1px solid rgba(243, 141, 0, 1)",
                          color: "rgba(243, 141, 0, 1)",
                        }}
                      >
                        Cancel
                      </button>
                    </div>

                  </>)}

                  {(isSocialMediaOpenModel === false && isAlreadySocialDataAvailable === false) && (
                    <>
                      <div className="sites_icon campus_student_sites_icon">
                        <div className="images">
                          <img src={Instagram} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>Instagram</span>
                          <br />
                          <input
                            type="text"
                            name="instagram"
                            value={formData.instagram}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={Twitter} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>Twitter</span>
                          <br />
                          <input
                            type="text"
                            name="twitter"
                            value={formData.twitter}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="sites_icon campus_student_sites_icon mt-2">
                        <div className="images">
                          <img src={LinkedIn} alt="icon" />
                        </div>
                        <div style={{ width: "100%" }}>
                          <span>LinkedIn</span>
                          <br />
                          <input
                            type="text"
                            name="linkedin"
                            value={formData.linkedin}
                            onChange={handleInputChange}
                            className="epf-input-field student_epf_input_fields_new"
                          />
                        </div>
                      </div>
                      <div className="pt-4" style={{ paddingLeft: "33px" }}>
                        <button onClick={handleAddSocialMedia} className=" student_epf_submit_bttn mt-0">
                          Save
                        </button>
                      </div>
                    </>
                  )}

                  {(showSocialMediaRedux === true && isSocialMediaOpenModel === false && isAlreadySocialDataAvailable === true) && (
                    <>
                      {myProfieData?.social_media_links
                        ?.filter((link) => link.url)
                        .map((link) => (
                          <div key={link.id} className="sites_icon student_sites_icon" style={{ width: "100%" }}>
                            <div className="d-flex align-items-start">
                              <div className="images">
                                <img
                                  src={
                                    link.platform === "instagram"
                                      ? Instagram
                                      : link.platform === "twitter"
                                        ? Twitter
                                        : link.platform === "linkedin"
                                          ? LinkedIn
                                          : ""
                                  }
                                  alt='icon'
                                />
                              </div>
                              <div>
                                <span>
                                  <span>{link.platform.charAt(0).toUpperCase() + link.platform.slice(1)}</span>
                                  <span style={{ paddingLeft: "1.5rem" }}><img onClick={() => handleEditSocialMedia(link)} src={editIcon} alt="Edit" style={{ width: "16px", cursor: "pointer", marginRight: "6px" }} /> <img onClick={() => handleDeleteSocialMedia(link.id)} src={orange_delete_icon} alt="Edit" style={{ width: "16px", cursor: "pointer" }} /> </span>
                                </span>
                                <br />
                                <a href={link.url} target="_blank" rel="noopener noreferrer">
                                  {link.url}
                                </a>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="clg-profile-navigation">
          <div className="d-flex clg-profile-new-navigate">
            <button
              className={
                activeForm === "campusManager" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("campusManager")}
            >
              <h5>Profile Creation</h5>
            </button>
            <button
              className={
                activeForm === "administrativeDetails"
                  ? "clg_active_button"
                  : ""
              }
              onClick={() => handleFormSwitch("administrativeDetails")}
            >
              <h5>Administrative Details</h5>
            </button>
            <button
              className={
                activeForm === "placementCell" ? "clg_active_button" : ""
              }
              onClick={() => handleFormSwitch("placementCell")}
            >
              <h5>Placement Cell Details</h5>
            </button>
          </div>
        </div>

        <div className="clg-profile-creation">
          {activeForm === "campusManager" && renderCampusManagerForm()}
          {activeForm === "administrativeDetails" &&
            renderAdministrativeDetailsForm()}
          {activeForm === "placementCell" && renderPlacementCellDetailsForm()}

          {renderRightSection()}
        </div>
      </div>
    </div>
  );
}

export default CampusManagerProfile;
