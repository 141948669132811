import { useNavigate, useParams } from "react-router-dom";
import leftArrow from "../../../../utils/dashboards/leftarrow.svg";
import "../../../../styles/dashboards/hrdashboard/postjobs.css";
import begicon from "../../../../utils/dashboards/begicon.svg";
import CreatableSelect from "react-select/creatable";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import "../../../../styles/dashboards/hrdashboard/assessment.css";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { toast } from "react-toastify";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function EditJob() {
  const [assessmentCategories, setAssessmentCategories] = useState([]);
  const { jobId, jobType } = useParams();
  const [loading, setLoading] = useState(false);
  const [assessmentCategory, setAssessmentCategory] = useState(null);
  const [jobCategory, setJobCategory] = useState(null);
  const [jobCategories, setJobCategories] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [selectedJobType, setSelectedJobType] = useState("Full-Time");
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skills, setSkills] = useState([]);
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [currentStep, setCurrentStep] = useState(1);
  const navigate = useNavigate();
  const [formErrors, setFormErrors] = useState({});

  const [formData, setFormData] = useState({
    title: "",
    degree_type: "",
    description: "",
    min_experience: "",
    max_experience: "",
    min_salary: "",
    max_salary: "",
    vacancy: "",
    job_location: "",
    no_of_interview_round: "",
    responsibility: "",
    qualification: "",
    perks_benefits: "",
    job_audience: "",
    assessment_required: "",
    end_date: null,
    job_skills: [],
    job_category: "",
  });

  const handleSkillChange = (newValue) => {
    setSelectedSkills(newValue || []);
  };

  const fetchAssessmentCategories = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/assessment-categories/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      setAssessmentCategories(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching assessment categories:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchJobCategories = async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/job-categories/`);
      const categories = response.data.map((category) => ({
        id: category.id,
        job_category: category.job_category,
      }));
      console.log("Fetched categories:", categories);
      setJobCategories(categories);
      return categories;
    } catch (error) {
      console.error("Error fetching job categories:", error);
      return [];
    }
  };

  const fetchJobDetails = async (availableCategories) => {
    try {
      setLoading(true);
      const endpoint =
        jobType === "lateral"
          ? `/api/jobs/lateral-jobs/${jobId}/`
          : `/api/jobs/campus-jobs/${jobId}/`;

      const response = await axios.get(`${baseUrl}${endpoint}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const jobData = response.data;
      console.log("Job data:", jobData);
      console.log("Available categories:", availableCategories);

      const matchingCategory = availableCategories.find(
        (cat) =>
          String(cat.id) === String(jobData.job_category) ||
          cat.job_category.toLowerCase() ===
            String(jobData.job_category).toLowerCase()
      );

      console.log("Matching category:", matchingCategory);

      if (matchingCategory) {
        setJobCategory(matchingCategory);
      } else {
        console.warn("No matching category found for:", jobData.job_category);
      }

      const formattedSkills =
        jobData.job_skills?.map((skill) => ({
          label: skill.skill_name,
          value: skill.id,
        })) || [];
      setSelectedSkills(formattedSkills);

      setFormData((prevData) => ({
        ...prevData,
        ...jobData,
        job_category: matchingCategory
          ? matchingCategory.id
          : jobData.job_category,
      }));

      sessionStorage.setItem(
        "formData",
        JSON.stringify({
          ...jobData,
          job_audience: jobData.job_audience || jobType,
        })
      );
    } catch (error) {
      console.error("Error fetching job details:", error);
      toast.error("Error loading job details");
    } finally {
      setLoading(false);
    }
  };

  const handleAssessmentChange = (e) => {
    const { value } = e.target;
    console.log("Assessment Change:", value); // Log the change
    setFormData((prevData) => ({
      ...prevData,
      assessment_required: value === "yes",
    }));
  };

  const formatSalary = (value) => {
    if (value >= 10000000) {
      return (value / 10000000).toFixed(2) + " Cr"; // Crores
    } else if (value >= 100000) {
      return (value / 100000).toFixed(2) + " L"; // Lakhs
    } else if (value >= 1000) {
      return (value / 1000).toFixed(2) + " K"; // Thousands
    }
    return value;
  };

  const handleSelectedJobType = (event) => {
    const { value } = event.target;
    setSelectedJobType(value);
  };

  const handleCategoryChange = (event, newValue) => {
    console.log("Category changed to:", newValue?.id); // Debug log
    setJobCategory(newValue);
    setFormData((prevData) => ({
      ...prevData,
      job_category: newValue?.id || "",
    }));
  };

  const handleGoToBack = () => {
    navigate(-1);
  };

  const handleNextStep = () => {
    let errors = {};
    if (formData.job_audience === "campus") {
      formData.assessment_required = false;
    }
    if (currentStep === 1) {
      errors = validateStep1();
    } else if (currentStep === 2) {
      errors = validateStep2();
    } else if (currentStep === 3) {
      errors = validateStep3();
    } else if (currentStep === 4 && formData.job_audience === "lateral") {
      errors = validateStep4();
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }
    if (formData.job_audience === "lateral") {
      if (currentStep < 4) {
        setCurrentStep(currentStep + 1);
      }
    } else {
      if (currentStep < 3) {
        setCurrentStep(currentStep + 1);
      }
    }
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const getProgressWidth = () => {
    let totalSteps = 2;

    if (formData.job_audience === "lateral") {
      totalSteps = 3;
    }

    return `${((currentStep - 1) / totalSteps) * 100}%`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (
      ["min_salary", "max_salary", "min_experience", "max_experience"].includes(
        name
      )
    ) {
      if (name === "min_salary" || name === "max_salary") {
        const salaryRegex = /^\d*\.?\d{0,2}$/;
        if (salaryRegex.test(value) || value === "") {
          setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
        }
      }
      if (name === "min_experience" || name === "max_experience") {
        const numericValue = value.replace(/[^0-9]/g, "");
        setFormData((prevData) => ({
          ...prevData,
          [name]: numericValue,
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const validateStep1 = () => {
    const errors = {};
    const today = dayjs();
    const endDate = dayjs(formData.end_date);

    if (!formData.end_date) {
      errors.end_date = "End date is required.";
    } else if (endDate.isBefore(today)) {
      errors.end_date = "Selected date is in the past.";
    }

    if (!formData.degree_type) {
      errors.degree_type = "Degree type is required.";
    }

    if (!formData.job_audience) {
      errors.job_audience = "Job audience is required.";
    }

    if (!formData.title) errors.title = "Title is required.";

    if (!formData.vacancy || formData.vacancy < 1)
      errors.vacancy = "Vacancy must be at least 1.";

    if (!formData.min_salary || parseFloat(formData.min_salary) < 0) {
      errors.min_salary = "Required";
    }
    if (
      !formData.max_salary ||
      parseFloat(formData.max_salary) <= parseFloat(formData.min_salary)
    ) {
      errors.max_salary = "Should be greater 0.";
    }

    if (!formData.min_experience) {
      errors.min_experience = "Required.";
    } else if (parseInt(formData.min_experience) < 0) {
      errors.min_experience = "Should be greater than 0.";
    }

    if (
      !formData.max_experience ||
      parseInt(formData.max_experience) <= parseInt(formData.min_experience)
    ) {
      errors.max_experience = "Should be greater than minimum experience.";
    }

    if (!formData.job_category) {
      errors.job_category = "Job category is required.";
    }

    if (!formData.job_location) {
      errors.job_location = "Job location is required.";
    }
    if (!formData.no_of_interview_round) {
      errors.no_of_interview_round = "Number of interview rounds is required.";
    } else if (
      parseInt(formData.no_of_interview_round) < 1 ||
      parseInt(formData.no_of_interview_round) > 4
    ) {
      errors.no_of_interview_round =
        "Number of interview rounds must be between 1 and 4.";
    }

    return errors;
  };

  const validateStep2 = () => {
    const errors = {};

    if (!formData.description) {
      errors.description = "*";
    }
    if (!formData.responsibility) {
      errors.rensponsibility = "*";
    }
    if (!formData.qualification) {
      errors.qualification = "*";
    }

    return errors;
  };

  const validateStep3 = () => {
    const errors = {};
    if (!formData.perks_benefits) {
      errors.perks_benefits = "*";
    }

    return errors;
  };

  const validateStep4 = () => {
    const errors = {};
    if (formData.assessment_required === true) {
      if (!assessmentCategory) {
        errors.assessmentCategory = "Assessment category is required.";
      }
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let errors = {};
    if (currentStep === 1) {
      errors = validateStep1();
    } else if (currentStep === 2) {
      errors = validateStep2();
    } else if (currentStep === 3) {
      errors = validateStep3();
    } else if (formData.job_audience === "lateral") {
      if (currentStep === 4) {
        errors = validateStep4();
      }
    }

    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      console.log(errors);
      return;
    }

    console.log("Form Data:", formData);
    const data = {
      id: jobId,
      job_audience: jobType,
      title: formData.title,
      description: formData.description,
      type: selectedJobType,
      category: assessmentCategory || "",
      job_category: formData.job_category,
      assessment_required: formData.assessment_required,
      min_experience: formData.min_experience || 0,
      max_experience: formData.max_experience || 0,
      min_salary: formData.min_salary,
      max_salary: formData.max_salary,
      job_skills: selectedSkills.map((skill) => ({
        id: skill.value,
        skill_name: skill.label,
      })),
      degree_type: formData.degree_type,
      vacancy: formData.vacancy,
      job_location: formData.job_location,
      company: userData.id,
      no_of_interview_round: formData.no_of_interview_round,
      responsibility: formData.responsibility,
      qualification: formData.qualification,
      perks_benefits: formData.perks_benefits,
      end_date: dayjs(formData.end_date),
    };

    setLoading(true);
    try {
      const endpoint =
        jobType === "lateral"
          ? `/api/jobs/company_lateral_jobs/`
          : `/api/jobs/company_campus_jobs/`;

      const response = await axios.put(`${baseUrl}${endpoint}`, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        await Swal.fire({
          title: "Success!",
          text: "Job Updated successfully!",
          icon: "success",
          confirmButtonText: "OK",
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });

        setLoading(true);
        navigate(
          `/dashboard/sidebar-page/:roleName/${
            jobType === "lateral" ? "lateral-job-list" : "campus-job-list"
          }`
        );
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
      if (error.response && error.response.status === 403) {
        toast.error(error.response.data.detail);
      } else {
        toast.error("An error occurred while updating the job.");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleTabSteps = (step) => {
    let errors = {};

    if (step > currentStep) {
      if (currentStep === 1) {
        errors = validateStep1();
      } else if (currentStep === 2) {
        errors = validateStep2();
      } else if (currentStep === 3) {
        errors = validateStep3();
      } else if (currentStep === 4 && formData.job_audience === "lateral") {
        errors = validateStep4();
      }

      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }
    }

    setCurrentStep(step);
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const categories = await fetchJobCategories();
        console.log("Categories loaded:", categories);
        await fetchJobDetails(categories);
      } catch (error) {
        console.error("Error during initialization:", error);
      }
    };

    initialize();
  }, []);

  const formatDateForInput = (dateString) => {
    if (!dateString) return "";
    return dayjs(dateString).format("YYYY-MM-DD");
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("formData");
    };
  }, []);

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          {loading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          <section className="post_jobs_step_form">
            <div className="d-flex align-items-center back_to_page_arrow">
              <div className="d-flex align-items-center ">
                <img
                  src={leftArrow}
                  style={{
                    paddingRight: "1rem",
                    height: "18px",
                    cursor: "pointer",
                  }}
                  alt=".."
                  onClick={handleGoToBack}
                />
                <h5 className="pt-1">Post a Job</h5>
              </div>
              {formData.job_audience && (
                <div className="">
                  {" "}
                  <span style={{ color: "#F38D00" }}>
                    {formData.job_audience.charAt(0).toUpperCase() +
                      formData.job_audience.slice(1).toLowerCase()}
                    &nbsp; Hiring
                  </span>
                </div>
              )}
            </div>

            <>
              <div className="post_job_steps_container">
                <div className="progress-bar-container">
                  {/* Step indicators */}
                  <div
                    style={{ borderTopLeftRadius: "1rem", cursor: "pointer" }}
                    className={`step ${
                      currentStep >= 1 ? "active" : ""
                    } pointer`}
                    onClick={() => handleTabSteps(1)}
                  >
                    <img src={begicon} alt="" />
                    <div className="step-label">
                      Step 1<br />
                      <p>Job Information</p>
                    </div>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    className={`step ${currentStep >= 2 ? "active" : ""}`}
                    onClick={() => handleTabSteps(2)}
                  >
                    <img src={begicon} alt="" />
                    <div className="step-label">
                      Step 2<br />
                      <p>Job Description</p>
                    </div>
                  </div>
                  <div
                    style={{
                      borderTopRightRadius: "1rem",
                      cursor: "pointer",
                    }}
                    className={`step ${currentStep >= 3 ? "active" : ""}`}
                    onClick={() => handleTabSteps(3)}
                  >
                    <img src={begicon} alt="" />
                    <div className="step-label">
                      Step 3<br />
                      <p>Perk & Benefits</p>
                    </div>
                  </div>
                  {formData.job_audience === "lateral" && (
                    <div
                      style={{
                        borderTopRightRadius: "1rem",
                        cursor: "pointer",
                      }}
                      className={`step ${currentStep >= 4 ? "active" : ""}`}
                      onClick={() => handleTabSteps(4)}
                    >
                      <img src={begicon} alt="" />
                      <div className="step-label">
                        Step 4 <br />
                        <p>Assessment</p>
                      </div>
                    </div>
                  )}
                </div>

                <div className="progress-line">
                  <div
                    className="progress"
                    style={{ width: getProgressWidth() }}
                  ></div>
                </div>
              </div>

              {/* Form content for each step */}

              <div className="post_job_form_container">
                <form onSubmit={handleSubmit}>
                  {currentStep === 1 && (
                    <div className="step-content">
                      <div className="post_job_info_container">
                        <div className="post_job_info_section">
                          <div className="post_job_info_label">
                            <h6>Job Title</h6>
                            <label htmlFor="job-title">
                              Job titles must be required one postions.
                            </label>
                          </div>
                          <div className="post_job_info_field job_title">
                            <div>
                              <input
                                type="text"
                                name="title"
                                id="jobTitle"
                                placeholder="Job Title"
                                value={formData.title}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                className={
                                  formErrors.title
                                    ? "invalid"
                                    : formData.title
                                    ? "valid"
                                    : ""
                                }
                              />
                              {formErrors.title && (
                                <div className="error-post-job">
                                  {formErrors.title}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div className="post_job_info_label">
                            <h6>Type Of Employment</h6>
                            <label htmlFor="job-title">
                              Job titles must be required one postions.
                            </label>
                          </div>
                          <div className="post_job_info_field">
                            <div className="post_job_info_field_emp_type_checkbox">
                              <div>
                                <input
                                  type="checkbox"
                                  name="jobType"
                                  value="Full-Time"
                                  checked={selectedJobType === "Full-Time"}
                                  defaultValue={"checked"}
                                  onChange={handleSelectedJobType}
                                  id="fullTime"
                                />{" "}
                                <label htmlFor="fullTime">Full-Time</label>
                                {/* </div> */}
                                {/* <div> */}
                                <input
                                  type="checkbox"
                                  name="jobType"
                                  value="Part-Time"
                                  checked={selectedJobType === "Part-Time"}
                                  onChange={handleSelectedJobType}
                                  id="partTime"
                                />
                                <label htmlFor="partTime">Part-Time</label>
                                {/* </div> */}
                                {/* <div> */}
                                <input
                                  type="checkbox"
                                  name="jobType"
                                  value="Remote"
                                  checked={selectedJobType === "Remote"}
                                  onChange={handleSelectedJobType}
                                  id="remote"
                                />
                                <label htmlFor="fullTime">Remote</label>
                              </div>
                              <div>
                                <input
                                  type="checkbox"
                                  name="jobType"
                                  value="Internship"
                                  checked={selectedJobType === "Internship"}
                                  onChange={handleSelectedJobType}
                                  id="internship"
                                />
                                <label htmlFor="internship">Internship</label>
                                {/* </div> */}
                                {/* <div> */}
                                <input
                                  type="checkbox"
                                  name="jobType"
                                  value="Contract"
                                  checked={selectedJobType === "Contract"}
                                  onChange={handleSelectedJobType}
                                  id="contract"
                                />
                                <label htmlFor="contract">Contract</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div
                            className="post_job_info_label"
                            style={{ padding: "1.5rem 1.5rem" }}
                          >
                            <h6>Number of vacancy</h6>
                          </div>
                          <div className="post_job_info_field">
                            <div>
                              <input
                                type="text"
                                name="vacancy"
                                id="vacancy"
                                placeholder="2"
                                value={formData.vacancy}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (!/^\d$/.test(e.key)) {
                                    e.preventDefault(); // Block non-numeric characters
                                  }
                                }}
                                onInput={(e) => {
                                  e.target.value = e.target.value.replace(
                                    /[^0-9]/g,
                                    ""
                                  ); // Remove non-numeric characters
                                }}
                                className={
                                  formErrors.vacancy
                                    ? "invalid"
                                    : formData.vacancy
                                    ? "valid"
                                    : ""
                                }
                                min={1}
                                maxLength={4}
                              />
                              {formErrors.vacancy && (
                                <div className="error-post-job">
                                  {formErrors.vacancy}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div
                            className="post_job_info_label"
                            style={{ padding: "1.5rem 1.5rem" }}
                          >
                            <h6>Degree Type</h6>
                          </div>
                          <div className="post_job_info_field">
                            <div>
                              <input
                                type="text"
                                name="degree_type"
                                id="vacancy"
                                placeholder="Enter degree type"
                                value={formData.degree_type}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (/[0-9]/.test(e.key)) {
                                    e.preventDefault();
                                  }
                                }}
                                className={
                                  formErrors.degree_type
                                    ? "invalid"
                                    : formData.degree_type
                                    ? "valid"
                                    : ""
                                }
                                min={1}
                              />
                              {formErrors.degree_type && (
                                <div className="error-post-job">
                                  {formErrors.degree_type}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div className="post_job_info_label">
                            <h6>Salary</h6>
                            <label htmlFor="job-title">
                              Please specify the estimated salary range for the
                              role.
                            </label>
                          </div>
                          <div className="post_job_info_field">
                            <div className="post_job_info_field">
                              <div className="d-flex flex-column">
                                <input
                                  type="text"
                                  name="min_salary"
                                  id="minSalary"
                                  placeholder="Enter salary"
                                  value={formData.min_salary}
                                  onChange={handleChange}
                                  className={
                                    formErrors.min_salary
                                      ? "invalid"
                                      : formData.min_salary
                                      ? "valid"
                                      : ""
                                  }
                                  min="0"
                                  maxLength={4}
                                  step="0.1" // Allows decimal input
                                />
                                {/* Display salary in LPA and formatted with K, L, Cr */}
                                <p style={{ color: "green", fontSize: "12px" }}>
                                  {formData.min_salary
                                    ? `${
                                        formData.min_salary
                                      } LPA (${formatSalary(
                                        formData.min_salary * 100000
                                      )})`
                                    : ""}
                                </p>
                                {formErrors.min_salary && (
                                  <div className="error-post-job">
                                    {formErrors.min_salary}
                                  </div>
                                )}
                              </div>

                              <span style={{ padding: "0px 15px" }}> to </span>

                              <div className="d-flex flex-column">
                                <input
                                  type="text"
                                  name="max_salary"
                                  id="maxSalary"
                                  placeholder="Enter salary"
                                  value={formData.max_salary}
                                  maxLength={4}
                                  onChange={handleChange}
                                  className={
                                    formErrors.max_salary
                                      ? "invalid"
                                      : formData.max_salary
                                      ? "valid"
                                      : ""
                                  }
                                  min={formData.min_salary || "0"} // Ensures max_salary is not less than min_salary
                                  step="0.1" // Allows decimal input
                                />
                                {/* Display salary in LPA and formatted with K, L, Cr */}
                                <p style={{ color: "green", fontSize: "12px" }}>
                                  {formData.max_salary
                                    ? `${
                                        formData.max_salary
                                      } LPA (${formatSalary(
                                        formData.max_salary * 100000
                                      )})`
                                    : ""}
                                </p>
                                {formErrors.max_salary && (
                                  <div className="error-post-job">
                                    {formErrors.max_salary}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div className="post_job_info_label">
                            <h6>Experience</h6>
                            <label htmlFor="job-title">
                              Please specify the estimated Experience for the
                              role.
                            </label>
                          </div>
                          <div className="post_job_info_field">
                            <div>
                              {" "}
                              <input
                                type="text"
                                name="min_experience"
                                id="minExperience"
                                placeholder="Min"
                                maxLength={2}
                                value={formData.min_experience}
                                onChange={handleChange}
                                className={
                                  formErrors.min_experience
                                    ? "invalid"
                                    : formData.min_experience
                                    ? "valid"
                                    : ""
                                }
                              />
                              {formErrors.min_experience && (
                                <div className="error-post-job">
                                  {formErrors.min_experience}
                                </div>
                              )}
                            </div>
                            <span style={{ padding: "0px 15px" }}> to </span>
                            {"   "}{" "}
                            <div>
                              <input
                                type="text"
                                name="max_experience"
                                id="maxExperience"
                                maxLength={2}
                                placeholder="Max"
                                value={formData.max_experience}
                                onChange={handleChange}
                                className={
                                  formErrors.max_experience
                                    ? "invalid"
                                    : formData.max_experience
                                    ? "valid"
                                    : ""
                                }
                              />
                              {formErrors.max_experience && (
                                <div className="error-post-job">
                                  {formErrors.max_experience}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div className="post_job_info_label">
                            <h6>Job Categories</h6>
                            <label htmlFor="job-title">
                              You can select One job categories and degree type.
                            </label>
                          </div>
                          <div className="post_job_info_field">
                            <Autocomplete
                              value={jobCategory}
                              onChange={handleCategoryChange}
                              options={jobCategories}
                              getOptionLabel={(option) =>
                                option?.job_category || ""
                              }
                              isOptionEqualToValue={(option, value) =>
                                option?.id === value?.id
                              }
                              filterOptions={(options, params) => {
                                const filtered = options.filter((option) =>
                                  option.job_category
                                    .toLowerCase()
                                    .includes(params.inputValue.toLowerCase())
                                );
                                return filtered;
                              }}
                              selectOnFocus
                              clearOnBlur
                              handleHomeEndKeys
                              id="job-category-autocomplete"
                              renderOption={(props, option) => (
                                <li {...props}>
                                  {option.job_category.toUpperCase()}
                                </li>
                              )}
                              sx={{
                                width: 300,
                                "& .MuiOutlinedInput-root": {
                                  fontFamily:
                                    "'Poppins', sans-serif !important",
                                  "&::-webkit-scrollbar": {
                                    width: "8px",
                                  },
                                  "&::-webkit-scrollbar-track": {
                                    background: "#f4f4f4",
                                  },
                                  "&::-webkit-scrollbar-thumb": {
                                    backgroundColor: "orange",
                                    borderRadius: "4px",
                                  },
                                  fontSize: "16px",
                                  fontWeight: 400,
                                },
                                "& .MuiSvgIcon-root": {
                                  width: "12px",
                                  height: "12px",
                                  fontFamily:
                                    "'Poppins', sans-serif !important",
                                },
                              }}
                              renderInput={(params) => (
                                <div>
                                  <TextField
                                    {...params}
                                    sx={{
                                      width: "300px",
                                      height: "36px",
                                      padding: "0 5px",
                                      fontFamily: "'Poppins', sans-serif",
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px",
                                        height: "36px",
                                        padding: "0 5px",
                                      },
                                    }}
                                    name="jobCategory"
                                    id="jobCategory"
                                    placeholder="Search job Category"
                                  />
                                  {formErrors.job_category && (
                                    <div className="error-post-job">
                                      {formErrors.job_category}
                                    </div>
                                  )}
                                </div>
                              )}
                            />
                          </div>
                        </div>

                        <div className="post_job_info_section ">
                          <div className="post_job_info_label">
                            <h6>Required Skills</h6>
                            <label htmlFor="job-title">
                              Add required skills for the job
                            </label>
                          </div>
                          <div className="post_job_info_field pt-2">
                            <div>
                              <SkillSelector
                                skills={skills}
                                selectedSkills={selectedSkills}
                                onSkillChange={handleSkillChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="post_job_info_section">
                          <div
                            className="post_job_info_label"
                            style={{ padding: "1.5rem 1.5rem" }}
                          >
                            <h6>Number of Interview Round</h6>
                          </div>
                          <div className="post_job_info_field">
                            <div>
                              <input
                                type="text"
                                name="no_of_interview_round"
                                id="interviewRound"
                                value={formData.no_of_interview_round}
                                onChange={(e) => {
                                  const value = e.target.value;
                                  if (/^[1-4]?$/.test(value)) {
                                    handleChange(e);
                                  }
                                }}
                                maxLength={1}
                              />
                              {formErrors.no_of_interview_round && (
                                <div className="error-post-job">
                                  {formErrors.no_of_interview_round}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="post_job_info_section">
                          <div
                            className="post_job_info_label"
                            style={{ padding: "1.5rem 1.5rem" }}
                          >
                            <h6>Job Location & Due Date</h6>
                          </div>
                          <div className="post_job_info_field">
                            <div>
                              <input
                                type="text"
                                name="job_location"
                                id="interviewRound"
                                placeholder="Job Location"
                                value={formData.job_location}
                                onChange={handleChange}
                                onKeyPress={(e) => {
                                  if (/[0-9]/.test(e.key)) {
                                    e.preventDefault(); // Prevent numeric characters
                                  }
                                }}
                              />
                              {formErrors.job_location && (
                                <div className="error-post-job">
                                  {formErrors.job_location}
                                </div>
                              )}
                            </div>
                            <span style={{ padding: "0px 15px" }}> and </span>
                            <div>
                              <TextField
                                label="Fill Due Date"
                                type="date"
                                name="end_date"
                                value={formatDateForInput(formData.end_date)}
                                onChange={handleChange}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                variant="outlined"
                                sx={{
                                  borderRadius: "10px",
                                  "& .MuiOutlinedInput-root": {
                                    borderRadius: "10px",
                                    height: "36px",
                                    "& input": {
                                      height: "36px",
                                      padding: "14px",
                                    },
                                  },
                                }}
                              />
                              {formErrors.job_location && (
                                <div className="error-post-job">
                                  {formErrors.end_date}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 2 && (
                    <div className="step-content">
                      {/*  */}
                      <div className="post_job_info_container_step_second">
                        <div className="post_job_info_section_step_second">
                          {" "}
                          <h6>Job Description</h6>
                          <label htmlFor="job-title">
                            Job description must be describe one position.
                          </label>
                          <div className="post_job_info_field_step_second">
                            <textarea
                              type="text"
                              name="description"
                              placeholder="Job Description"
                              id="jobDescription"
                              rows={7}
                              value={formData.description}
                              onChange={handleChange}
                            />
                            {formErrors.description && (
                              <span className="error">
                                {formErrors.description}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="post_job_info_section_step_second">
                          <h6>Responsibilities</h6>
                          <label htmlFor="job-title">
                            Enter your responsibilities of this position
                          </label>
                          <div className="post_job_info_field_step_second">
                            <textarea
                              type="text"
                              name="responsibility"
                              placeholder="Enter Job Responsibilities"
                              id="JobResponsibilities"
                              value={formData.responsibility}
                              rows={7}
                              onChange={handleChange}
                            />
                            {formErrors.responsibility && (
                              <span className="error">
                                {formErrors.responsibility}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="post_job_info_section_step_second">
                          <h6>Qualifications</h6>
                          <label htmlFor="job-title">
                            Add your preferred candidates qualifications
                          </label>
                          <div className="post_job_info_field_step_second">
                            <textarea
                              type="text"
                              name="qualification"
                              placeholder="Enter Qualifications"
                              id="qualifications"
                              value={formData.qualification}
                              onChange={handleChange}
                              rows={7}
                            />
                            {formErrors.qualification && (
                              <span className="error">
                                {formErrors.qualification}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 3 && (
                    <div className="step-content">
                      <div className="post_job_info_container_third">
                        <div className="post_job_info_section_third">
                          <h6>Perks & Benefits</h6>
                          <label htmlFor="job-title">
                            Encourage more people to apply by sharing the
                            attractive rewards and benefits yo offer your
                            employees,
                          </label>
                          <div className="post_job_info_field_step_second">
                            <textarea
                              type="text"
                              name="perks_benefits"
                              placeholder="Enter Perks & Benefits"
                              id="perksAndBenefits"
                              value={formData.perks_benefits}
                              onChange={handleChange}
                              rows={7}
                            />
                            {formErrors.perks_benefits && (
                              <span className="error">
                                {formErrors.perks_benefits}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {currentStep === 4 && (
                    <div className="step-content">
                      <div className="post_job_info_container_fourth">
                        <div className="post_job_info_section_fourth main_section">
                          <div>
                            <div className="post_job_info_label">
                              Do you want to take an assessment? (Yes or No)
                            </div>
                            <div className="assessment_btn_container">
                              <div className="radio-input">
                                <label>
                                  <input
                                    value="no"
                                    name="assessment_required"
                                    type="radio"
                                    checked={
                                      formData.assessment_required === false
                                    }
                                    onChange={handleAssessmentChange}
                                  />
                                  <span>No</span>
                                </label>
                                <label>
                                  <input
                                    value="yes"
                                    name="assessment_required"
                                    type="radio"
                                    checked={
                                      formData.assessment_required === true
                                    }
                                    onChange={handleAssessmentChange}
                                  />
                                  <span>Yes</span>
                                </label>
                                <span class="selection"></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Conditionally render assessment category */}
                        {formData.assessment_required && (
                          <div className="post_job_info_section_fourth">
                            {/* <ToastContainer /> */}
                            {loading && (
                              <div className="loader-container">
                                <div className="loader"></div>
                              </div>
                            )}

                            <div className="assesment_category_conatiner">
                              <div>
                                <h6 className="assesment_info_label">
                                  Select an Assessment Category
                                </h6>
                                <div className="assessment_btn_container">
                                  <FormControl
                                    sx={{
                                      m: 1,
                                      width: 300,
                                      borderRadius: "20px", // Add border radius to FormControl
                                    }}
                                  >
                                    <InputLabel
                                      id="demo-multiple-name-label"
                                      sx={{ borderRadius: "20px" }}
                                    >
                                      Assessment Category
                                    </InputLabel>
                                    <Select
                                      labelId="demo-multiple-name-label"
                                      id="demo-multiple-name"
                                      value={assessmentCategory}
                                      onChange={(e) =>
                                        setAssessmentCategory(e.target.value)
                                      }
                                      input={
                                        <OutlinedInput
                                          label="Assessment Category"
                                          sx={{ borderRadius: "20px" }}
                                        />
                                      }
                                      MenuProps={MenuProps}
                                      sx={{
                                        borderRadius: "20px",
                                      }}
                                    >
                                      {assessmentCategories.length > 0 ? (
                                        assessmentCategories.map((category) => (
                                          <MenuItem
                                            key={category.id}
                                            value={category.id}
                                          >
                                            {category.name}
                                          </MenuItem>
                                        ))
                                      ) : (
                                        <MenuItem disabled>
                                          No assessment categories available
                                        </MenuItem>
                                      )}
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* Navigation Buttons */}
                  <div
                    className={`p-1 modal-buttons d-flex ${
                      currentStep === 1
                        ? "justify-content-end"
                        : "justify-content-between"
                    } `}
                  >
                    {currentStep !== 1 && !loading && (
                      <button onClick={handlePreviousStep} type="button">
                        Previous
                      </button>
                    )}
                    {formData.job_audience === "lateral" ? (
                      currentStep < 4 ? (
                        <label
                          style={{ cursor: "pointer" }}
                          onClick={handleNextStep}
                        >
                          Next Step
                        </label>
                      ) : (
                        <button
                          type="submit"
                          style={{ cursor: "pointer" }}
                          disabled={loading}
                        >
                          {loading ? (
                            <div className="spinner"></div>
                          ) : (
                            "Edit Job"
                          )}
                        </button>
                      )
                    ) : currentStep < 3 ? (
                      <label
                        style={{ cursor: "pointer" }}
                        onClick={handleNextStep}
                      >
                        Next Step
                      </label>
                    ) : (
                      <button
                        type="submit"
                        disabled={loading}
                        style={{ cursor: "pointer" }}
                      >
                        {loading ? (
                          <span>
                            <span className="loader"></span>
                          </span>
                        ) : (
                          "Edit Job"
                        )}
                      </button>
                    )}
                  </div>
                </form>{" "}
              </div>
            </>
          </section>
        </div>
      </div>
    </>
  );
}

const SkillSelector = ({ skills, selectedSkills, onSkillChange }) => {
  const formattedSkills = (skills || []).map((skill) => ({
    label: skill.skill_name,
    value: skill.id,
  }));

  return (
    <CreatableSelect
      isMulti
      value={selectedSkills}
      onChange={onSkillChange}
      options={formattedSkills}
      placeholder="Enter skills..."
      getNewOptionData={(inputValue) => ({
        label: inputValue,
        value: inputValue,
      })}
      styles={{
        control: (provided) => ({
          ...provided,
          minHeight: "40px",
          border: "none",
          boxShadow: "none",
        }),
        input: (provided) => ({
          ...provided,
          padding: "0",
        }),
        multiValue: (provided) => ({
          ...provided,
          backgroundColor: "orange",
          borderRadius: "8px",
        }),
        multiValueLabel: (provided) => ({
          ...provided,
          color: "white",
        }),
        multiValueRemove: (provided) => ({
          ...provided,
          color: "white",
          ":hover": {
            backgroundColor: "darkorange",
            color: "white",
          },
        }),
      }}
    />
  );
};
