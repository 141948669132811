import React, { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import "../../../styles/dashboards/hrdashboard/viewcandidateapplication.css";
import copyIcon from "../../../utils/auth/images/copyicon.svg";
import axios from "axios";
import { Button, Drawer } from "@mui/material";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

export default function ViewLateralCandidateApplication() {
  const { id, application_id } = useParams();
  const [interviewScheduleData, setInterviewScheduleData] = useState(null);
  const token = sessionStorage.getItem("accessToken");
  const [candidateData, setCandidateData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("ApplicationProfile");
  const navigate = useNavigate();

  const getInterviewDetails = async () => {
    const payload = { application_ids: [application_id] };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/schedule_interview/retrieve_interview_details/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInterviewScheduleData(response.data.interview_details[0]);
    } catch (error) {
      console.error("Error fetching interview details:", error);
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "ApplicationProfile":
        return (
          <ApplicationProfile
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
      case "Resume":
        return (
          <Resume
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
      case "HiringProcess":
        return (
          <HiringProcess
            candidateData={candidateData}
            applicationId={application_id}
            interviewScheduleData={interviewScheduleData}
            getInterviewDetails={getInterviewDetails}
          />
        );
      case "InterviewSchedule":
        return interviewScheduleData ? (
          <InterviewSchedule
            candidateData={candidateData}
            interviewScheduleData={interviewScheduleData}
            applicationId={application_id}
          />
        ) : (
          <p className="text-center">No interview scheduled</p>
        );

      default:
        return (
          <ApplicationProfile
            candidateData={candidateData}
            applicationId={application_id}
          />
        );
    }
  };

  async function getCandidateDetails() {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/studentlateral_profile/${id}/`
      );
      setCandidateData(response.data);
    } catch (error) {
      console.error("Error fetching candidate details:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getCandidateDetails();
    getInterviewDetails();
  }, [id]);
  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <h6>
              {" "}
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>{" "}
              &nbsp; Applicant Details
            </h6>
            {/* main container start */}
            <div className="view_candidate_application_container">
              <div className="view_candidate_application_profile_sub_details">
                <div className="view_candidate_application_profile_first">
                  <img
                    height={80}
                    width={80}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                    alt={`${
                      candidateData?.first_name || "Candidate"
                    }'s profile`}
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
                <div className="view_candidate_application_profile_second">
                  <div className="job_type">
                    <p id="applied_jobs">Applied Jobs</p>
                    <p>{candidateData?.applied_date || "N/A"}</p>
                  </div>
                  <hr />
                  <div className="job_category_title">
                    <p className="category">
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "N/A"}
                    </p>
                    <p className="title">
                      {candidateData?.employments?.[0]?.employment_type ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
                <div className="view_candidate_application_profile_third">
                  <h6>Contact</h6>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Email"
                    />
                    <div className="social-details-container">
                      <label>Email</label>
                      <br />
                      <a
                        href={`mailto:${candidateData?.email}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.email || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Phone"
                    />
                    <div className="social-details-container">
                      <label>Phone</label>
                      <br />
                      <a
                        href={`tel:${candidateData?.phone_no}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.phone_no || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="Twitter"
                    />
                    <div className="social-details-container">
                      <label>Twitter</label>
                      <br />
                      <a
                        href={candidateData?.twitter || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.twitter || "Not specified"}
                      </a>
                    </div>
                  </div>
                  <div className="social-container">
                    <img
                      src="https://via.placeholder.com/150"
                      className="icon-social"
                      alt="LinkedIn"
                    />
                    <div className="social-details-container">
                      <label>LinkedIn</label>
                      <br />
                      <a
                        href={candidateData?.linkedin || "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {candidateData?.linkedin || "Not specified"}
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              {/* =============== */}

              <div className="view_candidate_application_process_container">
                <div className="application_tab">
                  <p onClick={() => setActiveTab("ApplicationProfile")}>
                    <span
                      className={
                        activeTab === "ApplicationProfile" ? "active" : ""
                      }
                    >
                      Applicant Profile
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("Resume")}>
                    <span className={activeTab === "Resume" ? "active" : ""}>
                      Resume
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("HiringProcess")}>
                    <span
                      className={activeTab === "HiringProcess" ? "active" : ""}
                    >
                      Hiring Process
                    </span>
                  </p>
                  <p onClick={() => setActiveTab("InterviewSchedule")}>
                    <span
                      className={
                        activeTab === "InterviewSchedule" ? "active" : ""
                      }
                    >
                      Interview Schedule
                    </span>
                  </p>
                </div>

                <hr />
                <div>{renderTabContent()}</div>
              </div>
            </div>
            {/* main container end */}
          </div>
        )}
      </div>
    </div>
  );
}

const ApplicationProfile = ({ candidateData }) => {
  return (
    <div className="candidate_application-profile">
      {/* Personal Info Section */}
      <div className="candidate_personal-info-section">
        <p className="personal_info">Personal Info</p>
        <div className="candidate_personal-info">
          <div className="detail-item">
            <p>Full Name</p>
            <p>
              {candidateData?.first_name} {candidateData?.last_name}
            </p>
          </div>
          <div className="detail-item">
            <p>Gender</p>
            <p>{candidateData?.gender || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Date of Birth</p>
            <p>
              {candidateData?.dob
                ? new Date(candidateData.dob).toLocaleDateString()
                : "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Language</p>
            <p>{candidateData?.languages || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Address</p>
            <p>{candidateData?.current_location || "Not specified"}</p>
          </div>
        </div>
      </div>
      <hr />
      <div className="candidate_professional_info_section">
        <div className="candidate_about_me">
          <h6>About Me</h6>
          <p>{candidateData?.bio || "No information provided."}</p>
        </div>

        <div className="candidate_professional_details">
          <div className="detail-item">
            <p>Current Job</p>
            <p>
              {candidateData?.employments?.[0]?.current_job_title ||
                "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Experience in years</p>
            <p>{candidateData?.experience || "Not specified"}</p>
          </div>
          <div className="detail-item">
            <p>Highest Qualification Held</p>
            <p>
              {candidateData?.qualifications?.[0]?.degree || "Not specified"}
            </p>
          </div>
          <div className="detail-item">
            <p>Skills Set</p>
            <p>
              {candidateData?.skill_name
                ?.map((skill) => skill.skill_name)
                .join(", ") || "No skills specified"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Resume = ({ candidateData }) => {
  const handleViewPDF = () => {
    window.open(candidateData.studentlateral_resume_url, "_blank");
  };

  return (
    <div className="hr_application_resume_body">
      <div className="hr_application_resume_container">
        {candidateData?.studentlateral_resume_url ? (
          <>
            <div className="pdf-preview-container">
              <svg
                className="resume-icon"
                width="64"
                height="64"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#666"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                />
              </svg>
              <p className="resume-message">
                Resume is available but cannot be previewed directly.
              </p>

              <div className="resume-actions">
                <button
                  onClick={handleViewPDF}
                  className="resume-action-button view-button"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path>
                    <polyline points="15 3 21 3 21 9"></polyline>
                    <line x1="10" y1="14" x2="21" y2="3"></line>
                  </svg>
                  View Resume
                </button>

                <button
                  onClick={() =>
                    window.open(
                      candidateData.studentlateral_resume_url,
                      "_blank"
                    )
                  }
                  className="resume-action-button"
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="7 10 12 15 17 10"></polyline>
                    <line x1="12" y1="15" x2="12" y2="3"></line>
                  </svg>
                  Download Resume
                </button>
              </div>
            </div>
          </>
        ) : (
          <div className="no-resume-message">
            <svg
              width="64"
              height="64"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#666"
              className="resume-icon"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
            <p>No resume available for preview or download.</p>
          </div>
        )}
      </div>
    </div>
  );
};

const HiringProcess = ({ candidateData, applicationId }) => {
  const [currentStatus, setCurrentStatus] = useState("in_review");
  const [offerLetter, setOfferLetter] = useState(null);
  const [error, setError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [statusLoading, setStatusLoading] = useState({
    in_review: false,
    shortlisted: false,
    interviewed: false,
    discarded: false,
    offered: false,
  });
  const token = sessionStorage.getItem("accessToken");

  const getApplicationStatus = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/get_application_status/`,
        {
          application_id: applicationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrentStatus(response.data.status);
    } catch (error) {
      console.log(error);
    }
  }, [applicationId, token]);

  const isButtonDisabled = (buttonStatus) => {
    if (currentStatus === "discarded" || currentStatus === "offered") {
      return true;
    }

    switch (currentStatus) {
      case "in_review":
        return !["shortlisted", "discarded"].includes(buttonStatus);

      case "shortlisted":
        return !["interviewed", "discarded"].includes(buttonStatus);

      case "interviewed":
        return !["offered", "discarded"].includes(buttonStatus);

      default:
        return true;
    }
  };

  const changeStatus = async (newStatus) => {
    if (currentStatus === "discarded" || currentStatus === "offered") {
      return;
    }

    try {
      setStatusLoading((prevState) => ({
        ...prevState,
        [newStatus]: true,
      }));

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        {
          application_ids: [applicationId],
          status: newStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCurrentStatus(newStatus);

      toast.success("Status Updated Successfully");

      setStatusLoading((prevState) => ({
        ...prevState,
        [newStatus]: false,
      }));
    } catch (error) {
      console.error("Error updating status:", error);
      toast.error("Failed to update status");
      setStatusLoading((prevState) => ({
        ...prevState,
        [newStatus]: false,
      }));
    }
  };

  useEffect(() => {
    getApplicationStatus();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Capture the selected file
    if (file) {
      setOfferLetter(file); // Store the file in state
      setError(""); // Clear any previous error message
    }
  };

  const uploadApplication = async () => {
    if (!offerLetter) {
      setError("Please select an offer letter to upload");
      return;
    }

    try {
      const formData = new FormData();
      formData.append("application_ids", [applicationId]);
      formData.append("status", "offered");
      formData.append("offer_letter", offerLetter);
      setIsUploading(true);
      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/update_application_status/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("upload Successfully");
    } catch (error) {
      console.error("Error uploading offer letter", error);
    } finally {
      setIsUploading(false);
    }
  };

  return (
    <>
      <div className="hiring_process_container">
        <p className="hiring_process_heading">Current Stage</p>
        <div className="hiring_process_btn_status">
          <button
            className={currentStatus === "in_review" ? "active" : ""}
            onClick={() => changeStatus("in_review")}
            disabled={isButtonDisabled("in_review")}
          >
            {statusLoading["in_review"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "In-Review"
            )}
          </button>

          <button
            className={currentStatus === "shortlisted" ? "active" : ""}
            onClick={() => changeStatus("shortlisted")}
            disabled={isButtonDisabled("shortlisted")}
          >
            {statusLoading["shortlisted"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Shortlisted"
            )}
          </button>

          <button
            className={currentStatus === "interviewed" ? "active" : ""}
            onClick={() => changeStatus("interviewed")}
            disabled={isButtonDisabled("interviewed")}
          >
            {statusLoading["interviewed"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Interviewed"
            )}
          </button>

          <button
            onClick={() => changeStatus("discarded")}
            className={currentStatus === "discarded" ? "active" : ""}
            disabled={isButtonDisabled("discarded")}
          >
            {statusLoading["discarded"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Declined"
            )}{" "}
          </button>
          <button
            onClick={() => changeStatus("offered")}
            className={currentStatus === "offered" ? "active" : ""}
            disabled={isButtonDisabled("offered")}
          >
            {" "}
            {statusLoading["offered"] ? (
              <span>
                <span className="loader"></span>
              </span>
            ) : (
              "Offered"
            )}{" "}
          </button>
        </div>
      </div>

      {currentStatus === "offered" && (
        <div className="offer_letter_upload_container">
          <p className="offer_letter_upload_heading">Upload Offer Letter</p>
          <div className="upload-wrapper">
            <input
              type="file"
              id="offer-letter"
              name="offer-letter"
              className="offer-letter-input"
              onChange={handleFileChange}
            />
            <label htmlFor="offer-letter" className="offer-letter-label">
              Upload PDF
            </label>
            {error && (
              <p style={{ marginTop: "0", color: "red", fontSize: "12px" }}>
                {error}
              </p>
            )}
          </div>
          <br />
          <button
            className="offer_letter_upload_button"
            onClick={uploadApplication}
          >
            {isUploading ? <div className="loader"></div> : "Submit"}
          </button>
        </div>
      )}
    </>
  );
};

const InterviewSchedule = ({
  candidateData,
  applicationId,
  interviewScheduleData,
}) => {
  const [copied, setCopied] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const token = sessionStorage.getItem("accessToken");

  console.log("applicationId", applicationId);

  const getApplicationStatus = useCallback(async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/api/jobs/get_application_status/`,
        {
          application_id: applicationId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCurrentStatus(response.data.status);
    } catch (error) {
      console.log(error);
    }
  }, [applicationId, token]);

  useEffect(() => {
    getApplicationStatus();
  }, [getApplicationStatus]);

  const handleCopyLink = () => {
    navigator.clipboard
      .writeText(interviewScheduleData?.zoom_meeting_link)
      .then(() => {
        setCopied(true);
        setShowTooltip(true);
        setTimeout(() => setShowTooltip(false), 2000);
      })
      .catch((err) => console.error("Failed to copy the link: ", err));
  };

  if (currentStatus === "offered") {
    return (
      <div className="mt-3 p-4">
        <div className="interview_details">
          <div className="status-message">
            <span role="img" aria-label="celebration">
              🎉
            </span>
            <h6>Candidate has been offered the position!</h6>
          </div>
        </div>
      </div>
    );
  }

  if (currentStatus === "discarded") {
    return (
      <div className="mt-3 p-4">
        <div className="interview_details">
          <div className="status-message">
            <span role="img" aria-label="declined">
              ❌
            </span>
            <h6>This candidate has been declined</h6>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {!interviewScheduleData?.zoom_meeting_link ? (
        <div className="mt-3 p-4">
          <div className="interview_details">
            <h6>Interview</h6>
            <div className="interview_schedule_details_lateral_container">
              <div className="interview_schedule_details_lateral">
                <div className="interview_schedule_details_lateral_candidate_details">
                  <img
                    height={60}
                    width={60}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                    alt={`${
                      candidateData?.first_name || "Candidate"
                    }'s profile`}
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="interview_schedule_lateral_date">
                <p>Date & Time</p>
                <p>{interviewScheduleData?.interview_date}</p>
              </div>
              <div className="interview_schedule_lateral_link">
                <p>Location</p>
                <p className="interview_schedule_lateral_link_copy">
                  {interviewScheduleData?.interview_location}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="mt-3 p-4">
          <div className="interview_details">
            <h6>Interview</h6>
            <div className="interview_schedule_details_lateral_container">
              <div className="interview_schedule_details_lateral">
                <div className="interview_schedule_details_lateral_candidate_details">
                  <img
                    height={60}
                    width={60}
                    style={{ borderRadius: "50%" }}
                    src={
                      candidateData?.student_photo_url ||
                      "https://via.placeholder.com/150"
                    }
                    alt={`${
                      candidateData?.first_name || "Candidate"
                    }'s profile`}
                  />
                  <div className="name_and_designation">
                    <p>
                      {candidateData?.first_name} {candidateData?.last_name}
                    </p>
                    <p>
                      {candidateData?.employments?.[0]?.current_job_title ||
                        "Not specified"}
                    </p>
                  </div>
                </div>
              </div>
              <div className="interview_schedule_lateral_date">
                <p>Date & Time</p>
                <p>{interviewScheduleData?.interview_date}</p>
              </div>
              <div className="interview_schedule_lateral_link">
                <p> Zoom Link </p>
                <p className="interview_schedule_lateral_link_copy">
                  <a
                    href={interviewScheduleData?.zoom_meeting_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Join Meeting
                  </a>
                  &nbsp;&nbsp;
                  <button onClick={handleCopyLink} className="copy-link-button">
                    <img src={copyIcon} alt="." />
                  </button>
                  {showTooltip && (
                    <span className="tooltip">
                      {copied ? "Copied!" : "Copy"}
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
