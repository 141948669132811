import { React, useEffect, useState } from "react";
import * as Yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import "../../../styles/dashboards/clgdashboard/campusmanagersettings.css";

function CampusManagerSettings() {
  const [notificationToggle, setNotificationToggle] = useState(false);
  const [inputFields, setInputFields] = useState({
    old_password: "",
    password: "",
    password2: "",
  });
  const [error, setError] = useState({});
  const [message, setMessage] = useState("");
  const [passwordLoading, setPasswordLoading] = useState(false);

  const handleToggleChange = () => {
    setNotificationToggle(!notificationToggle);
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setInputFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const validate = () => {
    const errors = {};
    if (!inputFields.old_password) {
      errors.old_password = "Old password is required";
    }
    if (!inputFields.password) {
      errors.password = "New password is required";
    } else if (inputFields.password.length < 5) {
      errors.password = "Password must be at least 5 characters";
    }
    if (inputFields.password2 !== inputFields.password) {
      errors.password2 = "Passwords must match";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validate();
    setError(validationErrors);

    if (inputFields.old_password === inputFields.password) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Old password and new password should be different.",
        customClass: {
          confirmButton: "custom-swal-button",
        },
        buttonsStyling: false,
      });
      return;
    }

    if (Object.keys(validationErrors).length === 0) {
      const accessToken = sessionStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      };

      setPasswordLoading(true);

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/changepassword/`,
          inputFields,
          config
        );

        Swal.fire({
          icon: "success",
          title: "Success",
          text: response.data.msg || "Password changed successfully!",
          customClass: {
            confirmButton: "custom-swal-button",
          },
          buttonsStyling: false,
        });

        setInputFields({
          old_password: "",
          password: "",
          password2: "",
        });
        setError({});
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            error.response?.data?.non_field_errors?.[0] ||
            error.response?.data?.msg ||
            "An error occurred.",
        });
      } finally {
        setPasswordLoading(false);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="student_campus_setting_page">
          <div className="student_campus_login_page">
            <div className="student_campus_login_detail">
              <h2 className="student_campus_login_heading">Login Details</h2>
            </div>
            <div className="setting_Bas_Div">
              <h6>Basic Information</h6>
              <span>Change your password.</span>
            </div>

            <div className="student_setting_box setting_sqbox-1">
              <div className="setting_info">
                <h6>Password</h6>
                <span>
                  Enter Your Current Password and New Password.
                </span>
              </div>

              <form onSubmit={handleSubmit}>
                <div className="student_setting_Input setting_Input">
                  <input
                    type="password"
                    name="old_password"
                    className="setting_textarea"
                    placeholder="Enter your current password"
                    value={inputFields.old_password}
                    onChange={handlePasswordChange}
                  />
                  {error.old_password && (
                    <div className="student_error error">{error.old_password}</div>
                  )}

                  <div className="setting_new_pass">
                    <div>
                      <input
                        type="password"
                        name="password"
                        className="setting_pass"
                        placeholder="Enter a new password"
                        value={inputFields.password}
                        onChange={handlePasswordChange}
                      />
                      {error.password && (
                        <div
                          className="student_error student_new_error error"
                          style={{ marginTop: "10px" }}
                        >
                          {error.password}
                        </div>
                      )}
                    </div>

                    <div>
                      <input
                        type="password"
                        name="password2"
                        className="setting_pass"
                        placeholder="Confirm password"
                        value={inputFields.password2}
                        onChange={handlePasswordChange}
                      />
                      {error.password2 && (
                        <div
                          className="student_error student_new_error error"
                          style={{ marginTop: "10px" }}
                        >
                          {error.password2}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="setting_btn">
                  <button
                    type="submit"
                    className="setting_custom-btn setting_btn-1 setting_otp_btn"
                  >
                    {passwordLoading ? (
                      <div className="spinner"></div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>

                {message && <p>{message}</p>}
              </form>
            </div>

            <div className="setting_sqbox-1 setting_Notification">
              <div className="setting_info">
                <h6>Notification</h6>
                <span>
                  This is your personal information that you can update anytime.
                </span>
              </div>
              <div className="setting_toggle">
                <input
                  type="checkbox"
                  id="mode-toggle"
                  className="setting_toggle__input"
                  checked={notificationToggle}
                  onChange={handleToggleChange}
                />
                <label
                  htmlFor="mode-toggle"
                  className="setting_toggle__label"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampusManagerSettings;