import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

const PlacementTrendGraph = ({ data = {} }) => {

    const placementTrends = Array.isArray(data.placement_trends) ? data.placement_trends : [];

    const companyData = placementTrends?.map((item) => ({
        name: item.company_name,
        offers: item.offers_count,
    }));

    const offers = companyData?.map((item) => item.offers); 
    const companyNames = companyData.map((item) => item.name); 

    const maxOffers = offers?.length > 0 ? Math.max(...offers) : 0; 

    const [chartData] = useState({
        series: [
            {
                name: "Offers",
                data: offers, 
            },
        ],
        options: {
            chart: {
                type: 'bar', 
                height: 350,
                zoom: {
                    enabled: false,
                },
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '50%',
                    endingShape: 'rounded',
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent'],
            },
            colors: ['#FFC26F'], 
            xaxis: {
                categories: companyNames, 
                labels: {
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            yaxis: {
                min: 0,
                max: maxOffers, 
                tickAmount: 5, 
                title: {
                    text: 'Number of Offers',
                },
            },
            fill: {
                opacity: 1,
            },
            tooltip: {
                y: {
                    formatter: (val) => `${val} offers`,
                },
            },
            legend: {
                horizontalAlign: 'left',
            },
            grid: {
                padding: {
                    left: 10,
                    right: 10,
                },
            },
        },
    });

    return (
        <div style={{ width: '100%' }}>
            <div id="chart">
                <ReactApexChart
                    options={chartData.options}
                    series={chartData.series}
                    type="bar" // Bar graph type
                    height={350}
                />
            </div>
            <div className='campus_placement_graph'>
                <div className='campus_placement_graph__title'>
                    <b>{data?.total_offered || 0}</b>
                    <p>Total Offered <span>Students</span></p>
                </div>
                <hr />
                <div className='campus_placement_graph__title'>
                    <b>{data?.total_companies || 0}</b>
                    <p>Total Companies</p>
                </div>

            </div>
        </div>
    );
};

export default PlacementTrendGraph;