import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import "../../styles/auth/auth.css";
import logoimg from "../../utils/landingpage/images/logo_img.svg";
import candidate from "../../utils/auth/images/candidate.svg";
import college from "../../utils/auth/images/college.svg";
import company from "../../utils/auth/images/company.svg";
import candidateWhite from "../../utils/auth/images/candidatewhite.svg";
import collegeWhite from "../../utils/auth/images/collegewhite.svg";
import companyWhite from "../../utils/auth/images/companywhite.svg";
import starnaukri from "../../utils/auth/images/start-nuakry.svg";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import API from "../../Api";
import { TextField, Autocomplete, CircularProgress } from "@mui/material";

const publicDomains = [
  "gmail.com",
  "yahoo.com",
  "outlook.com",
  "hotmail.com",
  "aol.com",
  "icloud.com",
];

const validationSchemas = {
  candidate: Yup.object().shape({
    name: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    student_last_name: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    student_campus_clg: Yup.string()
      .min(2, "College Name must be at least 2 characters")
      .required("Required"),
    enrollment_no: Yup.string()
      .min(2, "Enrollement Number must be at least 2 characters")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .required("Required"),
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    all_mobno: Yup.string()
      .required("Required")
      .test("valid-contact", "Invalid phone number format", (value) => {
        value = "+".concat(value);

        if (!value) return false;

        if (value.startsWith("+91")) {
          return /^\+91[6-9]\d{9}$/.test(value);
        } else {
          return /^\+\d{1,4}\d{10,}$/.test(value);
        }
      }),
    message: Yup.string().required("Required"),
  }),
  company: Yup.object().shape({
    name: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    company_last_name: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    company_name: Yup.string()
      .min(2, "Company Name must be at least 2 characters")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )
      .test(
        "business-email",
        "Only company/business emails are allowed",
        (value) => {
          if (!value) return false;
          const domain = value.split("@")[1];
          return !publicDomains.includes(domain);
        }
      )
      .required("Required"),
    company_city: Yup.string()
      .min(2, "City must be at least 2 characters")
      .matches(/^[A-Za-z]+$/, "Only alphabetic characters are allowed")
      .required("Required"),
    all_mobno: Yup.string()
      .required("Required")
      .test("valid-contact", "Invalid phone number format", (value) => {
        value = "+".concat(value);
        if (!value) return false;

        if (value.startsWith("+91")) {
          return /^\+91[6-9]\d{9}$/.test(value);
        } else {
          return /^\+\d{1,4}\d{10,}$/.test(value);
        }
      }),
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
  }),
  college: Yup.object().shape({
    name: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    college_lastname: Yup.string()
      .min(2, "First Name must be at least 2 characters")
      .matches(
        /^[A-Za-z\s]+$/,
        "Only alphabetic characters and spaces are allowed"
      )
      .required("Required"),
    collegename: Yup.string()
      .min(2, "College Name must be at least 2 characters")
      .required("Required"),
    email: Yup.string()
      .email("Invalid email")
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        "Invalid email format"
      )

      .required("Required"),
    password: Yup.string()
      .min(5, "Password must be at least 5 characters")
      .required("Required"),
    password2: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Required"),
    all_mobno: Yup.string()
      .required("Required")
      .test("valid-contact", "Invalid phone number format", (value) => {
        value = "+".concat(value);
        if (!value) return false;

        if (value.startsWith("+91")) {
          return /^\+91[6-9]\d{9}$/.test(value);
        } else {
          return /^\+\d{1,4}\d{10,}$/.test(value);
        }
      }),
    message: Yup.string(),
  }),
};

const initialValues = {
  candidate: {
    name: "",
    enrollment_no: "",
    student_last_name: "",
    student_campus_clg: "",
    email: "",
    password: "",
    password2: "",
    all_mobno: "",
    message: "",
  },
  company: {
    name: "",
    company_last_name: "",
    company_name: "",
    email: "",
    company_city: "",
    all_mobno: "",
    password: "",
    password2: "",
  },
  college: {
    name: "",
    college_lastname: "",
    collegename: "",
    email: "",
    password: "",
    password2: "",
    all_mobno: "",
    message: "",
  },
};

const Register = () => {
  const [activeForm, setActiveForm] = useState("candidate");
  // set clg id
  const [isSigning, SetIsSigning] = useState(false);
  const [collegeList, setCollegeList] = useState([]);
  const navigate = useNavigate();
  const [collegeOptions, setCollegeOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const fetchColleges = async (searchQuery) => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/college-data/?search=${searchQuery}`
      );
      const colleges = response.data.results || [];
      setCollegeOptions(colleges);
    } catch (error) {
      console.error("Error fetching colleges:", error);
      setCollegeOptions([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCollegeSearch = (event, value) => {
    const searchQuery = value ? value.toLowerCase() : "";
    console.log(searchQuery);
    formik.setFieldValue("collegename", value);

    if (searchQuery) {
      fetchColleges(searchQuery);
    } else {
      setCollegeOptions([]);
    }
  };

  const handleInput = (e) => {
    e.target.value = e.target.value.replace(/[0-9]/g, "");
  };

  const formik = useFormik({
    initialValues: initialValues[activeForm],
    validationSchema: validationSchemas[activeForm],
    onSubmit: (values, { resetForm }) => {
      SetIsSigning(true);

      if (values.all_mobno && !values.all_mobno.startsWith("+")) {
        values.all_mobno = "+" + values.all_mobno;
      }

      const formData = new FormData();

      for (const key in values) {
        if (Object.prototype.hasOwnProperty.call(values, key)) {
          formData.append(key, values[key]);
        }
      }

      let apiURL;

      if (activeForm == "candidate") {
        apiURL = `${API.candidateSignUp}`;
        console.log("------API role", apiURL);
      } else if (activeForm == "company") {
        apiURL = `${API.companySignUp}`;
        console.log("------ API role", apiURL);
      } else if (activeForm == "college") {
        apiURL = `${API.collegeSignUp}`;
        console.log("------API role", apiURL);
      }

      console.log("------activeForm", activeForm);

      axios
        .post(apiURL, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          toast.success(response.data.msg);
          resetForm();
          sessionStorage.setItem(
            "user-email",
            JSON.stringify(response.data.user_details.email)
          );
          setTimeout(() => {
            navigate("/auth/userMessage");
          }, 2000);
        })
        .catch((error) => {
          console.error("Error submitting data", error);
          if (
            error.response &&
            error.response.data &&
            error.response.data.email
          ) {
            toast.error(error.response.data.email[0]);
            SetIsSigning(false);
          } else {
            toast.error("There was an error submitting the form");
          }
          formik.setSubmitting(false);
          SetIsSigning(false);
        })
        .finally(() => {
          SetIsSigning(false);
        });

    },
    enableReinitialize: true,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    axios
      .get(`${process.env.REACT_APP_BASE_URL}/api/approved-colleges-list/`)
      .then((response) => {
        setCollegeList(response.data);
      })
      .catch((error) => {
        console.error("Error fetching college list:", error);
      });
  }, []);

  useEffect(() => {
    console.log("Button is disabled:", isSigning || formik.isSubmitting);
  }, [isSigning, formik.isSubmitting]);

  return (
    <div className="register_user_container">
      {/* <ToastContainer /> */}
      <div className="register_main_parent">
        <div className="register_user_main">
          <div className="register_background_first">
            <div className="register_background_first_inner">
              <div>
                <Link to="/lateral-hiring">
                  <img src={logoimg} alt="..." />
                </Link>
              </div>
              <div className="register_background_heading">
                <h1> Get Started With Start Naukri! </h1>
                <p>To keep connected with us please enter your personal info</p>
              </div>
            </div>
          </div>
          <div className="register_content_second">
            <div className="register_content_second_container">
              <div className="register-mobile_logo">
                <img src={starnaukri} alt="start-naukri" />
              </div>
              <h2 className="register_heading">Create a new account</h2>
              <div className="register_header_line"></div>
              <div className="register_account_options">
                <p>Create account as</p>
                <div className="register_account_options_child">
                  <button
                    className={activeForm === "candidate" ? "active" : ""}
                    onClick={() => setActiveForm("candidate")}
                  >
                    <img
                      src={
                        activeForm === "candidate" ? candidateWhite : candidate
                      }
                      alt="candidate"
                    />{" "}
                    Candidate
                  </button>
                  <button
                    className={activeForm === "company" ? "active" : ""}
                    onClick={() => setActiveForm("company")}
                  >
                    <img
                      src={activeForm === "company" ? companyWhite : company}
                      alt="company"
                    />{" "}
                    Company
                  </button>
                  <button
                    className={activeForm === "college" ? "active" : ""}
                    onClick={() => setActiveForm("college")}
                  >
                    <img
                      src={activeForm === "college" ? collegeWhite : college}
                      alt="college"
                    />{" "}
                    College
                  </button>
                </div>
                <form
                  onSubmit={formik.handleSubmit}
                  className="register_form_container register_form_container_new"
                >
                  {activeForm === "candidate" && (
                    <div className="register_form_section_candidate">
                      <div className="candidate_input_group_container">
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="firstName"
                            name="name"
                            placeholder="First Name"
                            onInput={handleInput}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="register_error_msg">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="lastName"
                            name="student_last_name"
                            placeholder="Last Name"
                            onChange={formik.handleChange}
                            onInput={handleInput}
                            onBlur={formik.handleBlur}
                            value={formik.values.student_last_name}
                          />
                          {formik.touched.student_last_name &&
                            formik.errors.student_last_name ? (
                            <div className="register_error_msg">
                              {formik.errors.student_last_name}
                            </div>
                          ) : null}
                        </div>
                        {/* collegeName search bar drop down  */}
                        <div className="candidate_input_group">
                          <Autocomplete
                            options={collegeList}
                            getOptionLabel={(option) => option.college_name}
                            onChange={(event, newValue) => {
                              formik.setFieldValue(
                                "student_campus_clg",
                                newValue ? newValue.id : ""
                              );
                            }}
                            onBlur={formik.handleBlur}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Search your college"
                                variant="outlined"
                                error={
                                  formik.touched.student_campus_clg &&
                                  Boolean(formik.errors.student_campus_clg)
                                }
                                helperText={
                                  formik.touched.student_campus_clg &&
                                  formik.errors.student_campus_clg
                                }
                                sx={{
                                  "& .MuiOutlinedInput-root .MuiAutocomplete-input":
                                  {
                                    border: "none",
                                  },
                                  height: "46px", // Set your desired height here
                                  "& .MuiOutlinedInput-root": {
                                    height: "100%", // Make sure the input takes full height
                                  },
                                }}
                              />
                            )}
                            FormHelperTextProps={{
                              sx: { display: "none" },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                                "& fieldset": {},
                              },
                            }}
                          />
                        </div>

                        {/* drop down end */}
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="register_error_msg">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>

                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="enrollment_no"
                            name="enrollment_no"
                            placeholder="Enrollment Number"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.enrollment_no}
                          />
                          {formik.touched.enrollment_no && formik.errors.enrollment_no ? (
                            <div className="register_error_msg">
                              {formik.errors.enrollment_no}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group candidate_phone_number">
                          <PhoneInput
                            country={"in"} // Set to India
                            id="allmobno"
                            name="all_mobno"
                            placeholder="Phone Number"
                            value={formik.values.all_mobno}
                            onChange={(value) =>
                              formik.setFieldValue("all_mobno", value)
                            }
                            onBlur={formik.handleBlur}
                            disableDropdown={true}
                            onlyCountries={["in"]}
                            countryCodeEditable={false}
                          />
                          {formik.errors.all_mobno && formik.touched.all_mobno ? (
                            <div className="register_error_msg w-100 d-flex">
                              {formik.errors.all_mobno}
                            </div>
                          ) : null}
                        </div>

                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="Password"
                            name="password"
                            placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="register_error_msg">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="confirmPassword"
                            name="password2"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password2}
                          />
                          {formik.touched.password2 &&
                            formik.errors.password2 ? (
                            <div className="register_error_msg">
                              {formik.errors.password2}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-12 candidate_msg">
                        <textarea
                          rows={2}
                          id="message"
                          name="message"
                          placeholder="Type your message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        />
                        {formik.touched.message && formik.errors.message ? (
                          <div className="register_error_msg">
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  {activeForm === "company" && (
                    <div className="register_form_section_company">
                      <div className="candidate_input_group_container">
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="firstName"
                            name="name"
                            placeholder="First Name"
                            onInput={handleInput}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="register_error_msg">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="lastName"
                            name="company_last_name"
                            onInput={handleInput}
                            placeholder="Last Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company_last_name}
                          />
                          {formik.touched.company_last_name &&
                            formik.errors.company_last_name ? (
                            <div className="register_error_msg">
                              {formik.errors.company_last_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="companyName"
                            name="company_name"
                            placeholder="Company Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company_name}
                          />
                          {formik.touched.company_name &&
                            formik.errors.company_name ? (
                            <div className="register_error_msg">
                              {formik.errors.company_name}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="email"
                            id="businessEmail"
                            name="email"
                            placeholder="Business Email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="register_error_msg">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="city"
                            name="company_city"
                            placeholder="City"
                            onInput={handleInput}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.company_city}
                          />
                          {formik.touched.company_city &&
                            formik.errors.company_city ? (
                            <div className="register_error_msg">
                              {formik.errors.company_city}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 candidate_phone_number">
                          <PhoneInput
                            country={"in"} // Set to India
                            id="allmobno"
                            name="all_mobno"
                            placeholder="Phone Number"
                            value={formik.values.all_mobno}
                            onChange={(value) =>
                              formik.setFieldValue("all_mobno", value)
                            }
                            onBlur={formik.handleBlur}
                            disableDropdown={true} // Disable the dropdown
                            onlyCountries={["in"]} // Restrict to only India
                            countryCodeEditable={false} // Prevent country code from being editable
                          />
                          {formik.errors.all_mobno &&
                            formik.touched.all_mobno ? (
                            <div className="register_error_msg w-100 d-flex">
                              {formik.errors.all_mobno}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="Password"
                            name="password"
                            placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="register_error_msg">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="confirmPassword"
                            name="password2"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password2}
                          />
                          {formik.touched.password2 &&
                            formik.errors.password2 ? (
                            <div className="register_error_msg">
                              {formik.errors.password2}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                  {activeForm === "college" && (
                    <div className="register_form_section_college">
                      <div className="candidate_input_group_container">
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="firstName"
                            name="name"
                            placeholder="First Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <div className="register_error_msg">
                              {formik.errors.name}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="lastName"
                            name="college_lastname"
                            placeholder="Last Name"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.college_lastname}
                          />
                          {formik.touched.college_lastname &&
                            formik.errors.college_lastname ? (
                            <div className="register_error_msg">
                              {formik.errors.college_lastname}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <Autocomplete
                            id="collegeName"
                            options={collegeOptions}
                            getOptionLabel={(option) =>
                              option.college_name || ""
                            }
                            loading={loading}
                            onInputChange={handleCollegeSearch}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="College Name"
                                name="collegename"
                                value={formik.values.collegename}
                                onBlur={formik.handleBlur}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <>
                                      {loading ? (
                                        <CircularProgress
                                          color="inherit"
                                          size={20}
                                        />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </>
                                  ),
                                }}
                                FormHelperTextProps={{
                                  sx: { display: "none" },
                                }}
                                sx={{
                                  "& .MuiOutlinedInput-root .MuiAutocomplete-input":
                                  {
                                    border: "none",
                                  },
                                  height: "46px", // Set your desired height here
                                  "& .MuiOutlinedInput-root": {
                                    height: "100%", // Make sure the input takes full height
                                  },
                                }}
                              />
                            )}
                            sx={{
                              "& .MuiOutlinedInput-root": {
                                borderRadius: "30px",
                              },
                            }}
                          />

                          {formik.touched.collegename &&
                            formik.errors.collegename ? (
                            <div className="register_error_msg">
                              {formik.errors.collegename}
                            </div>
                          ) : null}
                        </div>

                        <div className="candidate_input_group">
                          <input
                            type="text"
                            id="email"
                            name="email"
                            placeholder="Email Address"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                          {formik.touched.email && formik.errors.email ? (
                            <div className="register_error_msg">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="Password"
                            name="password"
                            placeholder="Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          {formik.touched.password && formik.errors.password ? (
                            <div className="register_error_msg">
                              {formik.errors.password}
                            </div>
                          ) : null}
                        </div>
                        <div className="candidate_input_group">
                          <input
                            type="password"
                            id="confirmPassword"
                            name="password2"
                            placeholder="Confirm Password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password2}
                          />
                          {formik.touched.password2 &&
                            formik.errors.password2 ? (
                            <div className="register_error_msg">
                              {formik.errors.password2}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-12 candidate_phone_number">
                        <PhoneInput
                          country={"in"} // Set to India
                          id="allmobno"
                          name="all_mobno"
                          placeholder="Phone Number"
                          value={formik.values.all_mobno}
                          onChange={(value) =>
                            formik.setFieldValue("all_mobno", value)
                          }
                          onBlur={formik.handleBlur}
                          disableDropdown={true} // Disable the dropdown
                          onlyCountries={["in"]} // Restrict to only India
                          countryCodeEditable={false} // Prevent country code from being editable
                        />
                        {formik.errors.all_mobno && formik.touched.all_mobno ? (
                          <div className="register_error_msg d-flex w-100">
                            {formik.errors.all_mobno}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-12 candidate_msg">
                        <textarea
                          rows={2}
                          id="message"
                          name="message"
                          placeholder="Type your message"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.message}
                        />
                        {formik.touched.message && formik.errors.message ? (
                          <div className="register_error_msg">
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  )}
                  <div className="register_send_btn_container pt-4">
                    <button
                      type="submit"
                      className="register_send_btn"
                      disabled={isSigning || formik.isSubmitting}
                    >
                      {isSigning ? <div className="spinner"></div> : "Send"}
                    </button>
                    <div className="pt-3">
                      Already have an account?{" "}
                      <Link
                        to={"/login"}
                        style={{ textDecoration: "none", color: "#F38D00" }}
                      >
                        Login
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
