import React, { useEffect, useState } from "react";
import { Pagination, PaginationItem } from "@mui/material";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightArrowIcon.svg";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import jsPDF from "jspdf";
import "jspdf-autotable";
import EnrolledStudent from "./ViewAllEntrolled";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setShowEntrolledStudentList } from "../../../redux/reducers/collage_dashboard_reducer";

export default function CampusManagerJobs() {
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [jobs, setJobs] = useState([]);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [enrolledJobId, setEnrolledJobId] = useState(null);

  const dispatch = useDispatch()

  const isShowEntrolledStudentList = useSelector((state) => state.CollageDashboard.showEntrolledStudentList);

  const jobsPerPage = 6;
  const navigate = useNavigate();
  const token = sessionStorage.getItem("accessToken");

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/companies_for_college/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const allJobs = response.data.flatMap((company) => company.jobs);
        setJobs(allJobs);
        setFilteredJobs(allJobs);
      } catch (error) {
        toast.error("Failed to fetch jobs. Please try again later.");
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
  }, [token]);

  const handleSearch = (e) => {
    setSearch(e.target.value);
    const filtered = jobs.filter((job) =>
      job.title.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setFilteredJobs(filtered);
    setPage(1);
  };

  const indexOfLastJob = page * jobsPerPage;
  const indexOfFirstJob = indexOfLastJob - jobsPerPage;
  const currentJobs = filteredJobs.slice(indexOfFirstJob, indexOfLastJob);

  const handleJobClick = (job) => {
    navigate(`/dashboard/sidebar-page/clg-manager/job-details/${job.id}/`);
  };

  const handleEnrolledStudentClick = (jobId) => {
    setEnrolledJobId(jobId)
    dispatch(setShowEntrolledStudentList(true))
  }

  const handleDownload = () => {
    if (jobs.length === 0) {
      toast.warn("No data available to download.");
      return;
    }

    const doc = new jsPDF();
    const tableColumn = ["Job Title", "Company Name", "Enrolled Students"];
    const tableRows = [];

    jobs.forEach((job) => {
      const jobData = [
        job.title || "N/A",
        job.company || "N/A",
        job.total_applicants || "0",
      ];
      tableRows.push(jobData);
    });

    doc.text("Jobs Data", 14, 10);
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 20,
    });

    doc.save("jobs-data.pdf");
  };

  return (
    isShowEntrolledStudentList ? <EnrolledStudent data={enrolledJobId} /> : (
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="all-job-header">
            <h5>{`All Jobs[${jobs?.length || 0}]`}</h5>
            <div className="all-job-header-right">
              <div className="all-jobs-search-input-div">
                <img src={searchIconDash} alt="Search Icon" />
                <input
                  type="text"
                  placeholder="Search"
                  value={search}
                  onChange={handleSearch}
                  className="all-job-search-input"
                />
              </div>
              <button onClick={handleDownload} className="download-button">
                Download Data
              </button>
            </div>
          </div>

          {loading ? (
            <div className="custom_loader">
              <Loader color="#F38D00" size={50} />
            </div>
          ) : currentJobs.length === 0 ? (
            <h3 className="nojobsfound">No Jobs Found</h3>
          ) : (
            <div className="job-list">
              {currentJobs.map((job, index) => (
                <div key={index} className="job-item">
                  <div
                    onClick={() => handleJobClick(job)}
                    className="job-left"
                  >
                    <img
                      src={job.company_profile?.company_logo_url || dummy_logo}
                      alt="logo"
                      className="company-logo"
                    />
                    <div className="job-info">
                      <h6>{job.title || "N/A"}</h6>
                      <p>{job.company || "N/A"}</p>
                    </div>
                  </div>
                  <div className="job-right" onClick={() => handleEnrolledStudentClick(job.id)} style={{cursor: "pointer"}}>
                    <span>{job.total_applicants} Enrolled Students</span>
                  </div>
                </div>
              ))}
            </div>
          )}

          <div className="pagination-container pagination_custom">
            <Pagination
              count={Math.ceil(filteredJobs.length / jobsPerPage)}
              page={page}
              onChange={(e, value) => setPage(value)}
              color="primary"
              className="mt-3"
              renderItem={(item) => (
                <PaginationItem
                  components={{
                    previous: () => <img src={leftArrowIcon} alt="prev" />,
                    next: () => <img src={rightIconArrow} alt="next" />,
                  }}
                  {...item}
                />
              )}
            />
          </div>
        </div>
      </div>
    )
  );
}