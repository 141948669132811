import axios from "axios";
import "../../../styles/dashboards/hrdashboard/hrsettings.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function HRManagerSettings() {
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const base_Url = process.env.REACT_APP_BASE_URL;

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [inputError, setInputError] = useState({});
  const [errors, setErrors] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const update = async (e) => {
    e.preventDefault();
    setInputError({});
    setErrors({
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    let formErrors = {};
    let errorMessages = {};

    // Validation checks with UI messages
    if (!oldPassword) {
      formErrors.oldPassword = true;
      errorMessages.oldPassword = "Current password is required";
    }

    if (!newPassword) {
      formErrors.newPassword = true;
      errorMessages.newPassword = "New password is required";
    } else {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(newPassword)) {
        formErrors.newPassword = true;
        errorMessages.newPassword =
          "Password must be at least 8 characters with uppercase, lowercase, number and special character";
      }
    }

    if (oldPassword === newPassword) {
      formErrors.newPassword = true;
      errorMessages.newPassword =
        "New password cannot be the same as current password";
    }

    if (!confirmPassword) {
      formErrors.confirmPassword = true;
      errorMessages.confirmPassword = "Please confirm your new password";
    } else if (newPassword !== confirmPassword) {
      formErrors.confirmPassword = true;
      errorMessages.confirmPassword = "Passwords do not match";
    }

    if (Object.keys(formErrors).length > 0) {
      setInputError(formErrors);
      setErrors(errorMessages);
      return;
    }

    try {
      const response = await axios.post(
        `${base_Url}/api/changepassword/`,
        {
          old_password: oldPassword,
          password: newPassword,
          password2: confirmPassword,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200) {
        toast.success("Password changed successfully!");
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setErrors({});
      }
    } catch (error) {
      toast.error("Failed to change password. Please try again.");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="hr_settings_container">
            <div className="hr_settings_headings">
              <h6>Login Details</h6>
            </div>
            <hr />
            <div className="hr_settings_main_sections_heading">
              <div className="hr_settings_basic_info_container">
                <h6>Basic Information</h6>
                <p>
                  This is your personal information that you can update anytime
                </p>
              </div>
              <div className="hr_settings_basic_form_container">
                <div className="hr_settings_basic_form_heading">
                  <h6>Password</h6>
                  <p>
                    If you wish to change your password, you can change it here.
                  </p>
                </div>
                <form className="hr_settings_basic_form" onSubmit={update}>
                  <div className="hr_settings_basic_form_details">
                    <div className="input_group">
                      <input
                        placeholder="Enter your current password"
                        type="password"
                        value={oldPassword}
                        onChange={(e) => setOldPassword(e.target.value)}
                        className={inputError.oldPassword ? "error" : ""}
                      />
                      {errors.oldPassword && (
                        <p className="error_message">{errors.oldPassword}</p>
                      )}
                    </div>

                    <div className="input_group">
                      <input
                        placeholder="Enter new password"
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className={inputError.newPassword ? "error" : ""}
                      />
                      {errors.newPassword && (
                        <p className="error_message">{errors.newPassword}</p>
                      )}
                    </div>

                    <div className="input_group">
                      <input
                        placeholder="Confirm password"
                        type="password"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        className={inputError.confirmPassword ? "error" : ""}
                      />
                      {errors.confirmPassword && (
                        <p className="error_message">
                          {errors.confirmPassword}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="hr_settings_form_submit_btn">
                    <button type="submit">Submit</button>
                  </div>
                </form>
              </div>
            </div>
            <div className="hr_notifications">
              <div className="hr_notification_one">
                <h6>Notifications</h6>
                <p>
                  This is your personal information that you can update anytime
                </p>
              </div>
              <div className="hr_notification_one checkbox-wrapper">
                <input
                  className="tgl tgl-light"
                  id="hr_toggle"
                  type="checkbox"
                />
                <label className="tgl-btn" htmlFor="hr_toggle"></label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
