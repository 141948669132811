import React, { useState } from "react";
import "../../../../../styles/admin/contentmanagement.css";
import { IconButton, Menu, MenuItem, Rating } from "@mui/material";
import threeDot from "../../../../../utils/landingpage/images/three-dots-svgrepo-com.svg";
import axios from "axios";
import { toast } from "react-toastify";

function TestimonialCard({ testimonials, limit, onDelete, onEdit }) {
  const [anchorEls, setAnchorEls] = useState({});
  const ITEM_HEIGHT = 36;

  const handleClick = (event, testimonialId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [testimonialId]: event.currentTarget,
    }));
  };

  const handleClose = (testimonialId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [testimonialId]: null,
    }));
  };

  const handleEdit = (testimonial) => {
    if (onEdit) {
      onEdit(testimonial);
    }
    handleClose(testimonial.id);
  };

  const handleDelete = async (id) => {
    try {
      const token = sessionStorage.getItem("accessToken");
      await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/api/testimonials/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Testimonial deleted successfully");
      if (onDelete) {
        onDelete();
      }
    } catch (error) {
      console.error("Error deleting testimonial:", error);
      toast.error("Failed to delete testimonial");
    }
    handleClose(id);
  };

  const displayedTestimonials = limit ? testimonials?.slice(0, limit) : testimonials;

  return (
    <>
      {displayedTestimonials?.map((testimonial) => (
        <div key={testimonial.id} className="testimonial_card">
          <div className="testimonial_header">
            <img
              src={testimonial.client_photo || "https://via.placeholder.com/50"}
              alt={testimonial.client_name}
              className="testimonial_avatar"
            />
            <div className="testimonial_info">
              <h4 className="testimonial_name">{testimonial.client_name}</h4>
              <p className="testimonial_position">{testimonial.company_name}</p>
            </div>
            <div className="testimonial_rating">
              <Rating
                name={`rating-${testimonial.id}`}
                value={Number(testimonial.rating)}
                precision={0.5}
                readOnly
                sx={{
                  fontSize: "24px",
                }}
              />
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={
                    anchorEls[testimonial.id] ? "long-menu" : undefined
                  }
                  aria-expanded={anchorEls[testimonial.id] ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={(event) => handleClick(event, testimonial.id)}
                >
                  <img src={threeDot} height={18} width={18} alt=".." />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEls[testimonial.id]}
                  open={Boolean(anchorEls[testimonial.id])}
                  onClose={() => handleClose(testimonial.id)}
                  PaperProps={{
                    style: {
                      maxHeight: ITEM_HEIGHT * 3.5,
                      width: "12ch",
                      borderRadius: "10px",
                    },
                  }}
                >
                  <MenuItem onClick={() => handleEdit(testimonial)}>
                    Edit
                  </MenuItem>
                  <MenuItem onClick={() => handleDelete(testimonial.id)}>
                    Delete
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </div>
          <p className="testimonial_content">{testimonial.testimonial_description}</p>
          {testimonial.testimonial_video && (
            <video
              src={testimonial.testimonial_video}
              controls
              className="testimonial_video"
              style={{ width: "100%", marginTop: "1rem" }}
            />
          )}
        </div>
      ))}
    </>
  );
}

export default TestimonialCard;
