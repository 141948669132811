import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/campusstudent/EnrolledStudent.css";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem } from "@mui/material";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import axios from "axios";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import { useDispatch } from "react-redux";
import { setShowEntrolledStudentList } from "../../../redux/reducers/collage_dashboard_reducer";

export default function EnrolledStudent({ data }) {
    const token = sessionStorage.getItem("accessToken");
    const [students, setStudents] = useState([]);
    const [loading, setLoading] = useState(false);

    const getUserDetails = async () => {
        try {
            setLoading(true)
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/jobs/colleges/applications/?job_id=${data}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setStudents(response.data.applications);
                console.log(response.data.applications);
            } else {
                toast.error("Something went wrong");
            }
        } catch (error) { }
        finally {
            setLoading(false)
        }
    };

    useEffect(() => {
        getUserDetails();
    }, []);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    // const [yearSearch, setYearSearch] = useState("");

    const dispatch = useDispatch()

    const itemsPerPage = 6;
    const label = { inputProps: { "aria-label": "Switch demo" } };

    const filteredData = (Array.isArray(students) ? students : []).filter(
        (candidate) => {
            const fullName = `${candidate.student_details?.name} ${candidate.student_details?.student_last_name}`;
            const uniqueStudentId =
                candidate.student_details?.unique_student_id || "";
            return (
                fullName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                uniqueStudentId.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }
    );

    const getDegreeAndYears = (qualifications) => {
        if (!qualifications || qualifications.length === 0) return "";

        const degrees = qualifications
            .map((qualification) => qualification.course_name)
            .join("/");

        const years = qualifications
            .map((qualification) =>
                qualification.to_date ? qualification.to_date.slice(0, 4) : ""
            )
            .join("/");

        return { degrees, years };
    };

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const currentItems = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    return (
        loading ? <div className="custom_loader">
            <Loader color="#F38D00" size={50} />
        </div> : (<div className="_main_content_inner_screen">
            <div className="_main_content_inner_screen_conatiner">
                <div className="candidate-page">
                    <div className="candidate-page-header">
                        <h5>
                            <button onClick={() => dispatch(setShowEntrolledStudentList(false))}> ← </button> Enrolled Students[{filteredData.length}]
                        </h5>
                        <div className="candidate-header-right">
                            <div className="candidate-search-input">
                                <img src={searchIconDash} alt="Search Icon" />
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="candidate-search-input-field"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="candidate-container">
                        <table className="candidate-table">
                            <thead>
                                <tr>
                                    <th>Student Name</th>
                                    <th>Student ID</th>
                                    <th>Degree/Diploma</th>
                                    <th>Date Applied</th>
                                    <th className="table_data">Status</th>
                                    {/* <th>Actions</th> */}
                                </tr>
                            </thead>

                            <tbody>
                                {currentItems?.length > 0 ? (
                                    currentItems?.map((candidate) => {
                                        const Name =
                                            candidate.student_details?.name +
                                            " " +
                                            candidate.student_details?.student_last_name;

                                        const qualifications =
                                            candidate.student_details?.studentcampus_profile
                                                ?.qualifications || [];
                                        const student_id =
                                            candidate.student_details?.unique_student_id;

                                        const student_status = candidate.status;
                                        console.log("Status----", student_status);

                                        return (
                                            <tr key={candidate.id}>
                                                {/* Access name and last name properly from student_details */}
                                                <td>{Name}</td>
                                                <td>{student_id}</td>
                                                <td>
                                                    {qualifications
                                                        .map((qualification) => qualification.course_name)
                                                        .join("/")}
                                                </td>
                                                {/* <td>
                          {qualifications
                            .map((qualification) =>
                              new Date(
                                qualification.applied_at
                              )
                              .toLocaleDateString("en-GB", {
                                day: "2-digit",
                                month: "long",
                                year: "numeric",
                              })
                            )
                            .join(" / ")}
                        </td> */}
                                                <td>
                                                    {candidate.applied_at.slice(0, 10)}
                                                </td>
                                                <td>
                                                    <div className="Status">
                                                        <div
                                                            className={
                                                                student_status === "interviewed"
                                                                    ? "shortlisted"
                                                                    : student_status === "in_review"
                                                                        ? "inreview"
                                                                        : student_status === "discarded"
                                                                            ? "declined"
                                                                            : ""
                                                            }
                                                        >
                                                            {console.log("---", student_status)}
                                                        </div>
                                                        <div>{student_status}</div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                ) : (
                                    <tr>
                                        <td colSpan="5" className="no-data">
                                            No candidates found
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>

                    <div className="pagination-controls">
                        <Stack spacing={2} alignItems="center">
                            <Pagination
                                count={totalPages}
                                page={currentPage}
                                onChange={handlePageChange}
                                color="primary"
                                siblingCount={1}
                                boundaryCount={1}
                                renderItem={(item) => (
                                    <PaginationItem
                                        components={{
                                            previous: () => <img src={leftArrowIcon} alt="prev" />,
                                            next: () => <img src={rightIconArrow} alt="next" />,
                                        }}
                                        {...item}
                                    />
                                )}
                            />
                        </Stack>
                    </div>
                </div>
            </div>
        </div>)
    );
}