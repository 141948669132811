import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import coursenameedit from "../../../utils/dashboards/coursenameedit.svg";
import delete_icon from "../../../utils/dashboards/delete_icon.svg";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import { useFormik } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";

function CampusManagerCourse() {
  const token = sessionStorage.getItem("accessToken");
  const [data, setData] = useState([]);
  const [new_datafetch, setNew_datafetch] = useState({
    name: "",
    duration: "",
    description: "",
    contact_info: "",
  });

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [editCourse, setEditCourse] = useState({
    id: null,
    name: "",
    description: "",
    duration: "",
    contact_info: "",
  });

  const showUserData = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/courses/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setCourses(response.data);
        console.log("Fetched Data:", response.data);
      } else {
        console.error("Something went wrong");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    showUserData();
  }, [token]);


  const validationSchema = Yup.object({
    name: Yup.string().required("Course name is required"),
    description: Yup.string().required("Course description is required"),
    duration: Yup.string().required("Course duration is required"),
    contact_info: Yup.string()
      .required("Contact information is required")
      .matches(
        /^[0-9]{10}$/,
        "Contact information must be a valid 10-digit phone number"
      ),
  });

  const handleEditClick = (courseId) => {
    const course = courses.find((course) => course.id === courseId.id);
    formik.setValues({ ...course });
    setIsEdit(true)
  };

  const handleCancelCourse = () => {
    setIsEdit(false)
    setEditCourse({
      id: null,
      name: "",
      description: "",
      duration: "",
      contact_info: "",
    });
    setNew_datafetch({
      name: "",
      duration: "",
      description: "",
      contact_info: "",
    });
    formik.resetForm()
  }

  const handleDeleteClick = async (courseId) => {
    try {
      console.log("Sending DELETE request for course ID:", courseId);
      const response = await axios.delete(
        `${process.env.REACT_APP_BASE_URL}/courses/${courseId}/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        setCourses((prevCourses) =>
          prevCourses.filter((course) => course.id !== courseId)
        );
        toast.success(`Course deleted successfully.`);
        formik.resetForm();
      } else {
        toast.error(`Failed to delete course `);
      }
    } catch (error) {
      toast.error(`Error deleting course with ID `);
    }
  };

  const handleEditSave = async (editCourse) => {
    if (!editCourse.id) {
      toast.error("No course ID provided for updating.");
      return;
    }

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASE_URL}/courses/${editCourse.id}/`,
        {
          name: editCourse.name,
          description: editCourse.description,
          duration: editCourse.duration,
          contact_info: editCourse.contact_info,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const updatedCourse = response.data;

        setCourses((prevCourses) =>
          prevCourses.map((course) =>
            course.id === updatedCourse.id ? updatedCourse : course
          )
        );
        toast.success('Course Added Successfully')
        formik.resetForm();
      } else {
        toast.error("Failed to update course:");
      }
    } catch (error) {
      toast.error("Error updating course:");
    }
  };

  const handleAddCourse = async (values) => {
    const newCourse = {
      name: values.name,
      description: values.description,
      duration: values.duration,
      contact_info: values.contact_info,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/courses/`,
        newCourse,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201) {
        toast.success("Cource Added Successfully")
        const createdCourse = response.data;
        setCourses((prevCourses) => [...prevCourses, createdCourse]);

        formik.resetForm();
      } else {
        console.error("Failed to add course:", response.data);
      }
    } catch (error) {
      console.error("Error adding course:", error);
    }
  };

  const formik = useFormik({
    initialValues: editCourse,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (values.id) {
        handleEditSave(values);
      } else {
        handleAddCourse(values);
      }
    },
  });

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="course-offered-text">
          <h5>Courses Offered</h5>
        </div>

        <form onSubmit={formik.handleSubmit}>
          {/* Form for adding/editing courses */}
          <div className="course-offered-page">
            <div className="course-offered-container">
              <div className="form-left">
                <div className="course-offered-form-group">
                  <input
                    type="text"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Course Name"
                    required
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  )}
                </div>
                <div className="course-offered-form-group">
                  <input
                    type="text"
                    name="description"
                    value={formik.values.description} // Use formik.values
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Description about the course (e.g., degree or diploma)"
                    required
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div style={{ color: "red" }}>
                      {formik.errors.description}
                    </div>
                  )}
                </div>
              </div>
              <div className="form-right">
                <div className="epf-input-wrapper epf-input-gap month_main_drop  ">
                  <select
                    name="duration"
                    value={formik.values.duration}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    required
                    className="epf-input-field Months_drop "
                  >
                    <option className="months_option" value="" disabled>
                      Select duration
                    </option>
                    <option value="6 Months">6 Months</option>
                    <option value="1 Year">1 Year</option>
                    <option value="2 Years">2 Years</option>
                    <option value="3 Years">3 Years</option>
                    <option value="4 Years">4 Years</option>
                    <option value="5 Years">5 Years</option>
                    <option value="6 Years">6 Years</option>
                  </select>
                </div>

                <div className="course-offered-form-group">
                  <input
                    type="text"
                    name="contact_info"
                    value={formik.values.contact_info} // Use formik.values
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder="Contact Information"
                    required
                  />
                  {formik.touched.contact_info && formik.errors.contact_info && (
                    <div style={{ color: "red" }}>{formik.errors.contact_info}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="course-offered-buttons">
              {/* Show Save button only if editing */}
              {formik.values.id ? (
                <button type="submit" className="course-offered-savebutton">
                  Save
                </button>
              ) : (
                <button type="submit" className="course-offered-add-course">
                  Add Course
                </button>
              )}
              {isEdit &&
                <button onClick={handleCancelCourse} className="course-offered-add-course">
                  Cancel
                </button>
              }
            </div>
          </div>
        </form>

        {/* Displaying the offered courses */}
        {loading ? (
          <div className="custom_loader">
            <ClipLoader color="#F38D00" size={50} />
          </div>
        ) : (<div className="offered-courses">
          {courses && courses.length > 0 && (<>
            <h5>Offered Courses</h5>
            <p>Add the courses you offer</p>
          </>)}
          <div className="courses-grid">
            {courses && courses.length > 0 ? (
              courses.map((course) => (
                <div key={course.id} className="course-card">
                  <div className="courses-offered-name">
                    <h5>
                      {course.name}{" "}
                      <h5 className="">
                        <img
                          src={coursenameedit}
                          alt="edit"
                          onClick={() => handleEditClick(course)}
                        />
                        <img
                          src={delete_icon}
                          alt="delete"
                          onClick={() => handleDeleteClick(course.id)}
                        />
                      </h5>
                    </h5>
                    <p>Duration: {course.duration}</p>
                    <p>Contact: {course.contact_info}</p>
                    <p>Description: {course.description}</p>
                  </div>
                </div>
              ))
            ) : (
              <p>No courses available</p>
            )}

          </div>
        </div>)}

      </div>
    </div>
  );
}

export default CampusManagerCourse;