import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../../../styles/dashboards/hrdashboard/assessment-question.css";
import { Alert, AlertTitle } from "@mui/material";

const INITIAL_QUESTION_STATE = {
  question_text: "",
  option_1: "",
  option_2: "",
  option_3: "",
  option_4: "",
  correct_option: "",
};

export default function AssessmentQuestion() {
  const { categoryId, categoryName } = useParams();
  const userData = JSON.parse(sessionStorage.getItem("userDetails"));
  const token = sessionStorage.getItem("accessToken");
  const navigate = useNavigate();
  const modalRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [newQuestion, setNewQuestion] = useState({
    ...INITIAL_QUESTION_STATE,
    hr: userData?.id,
    category: categoryName,
  });

  const getQuestionByCategory = useCallback(async () => {
    if (!categoryId || !categoryName) {
      Swal.fire({
        title: "Error!",
        text: "Invalid category information",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    try {
      setLoading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/assessment-questions/`,
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      const matchedCategory = response.data.categories.find(
        (cat) => cat.category.toLowerCase() === categoryName.toLowerCase()
      );

      if (matchedCategory) {
        setQuestions(matchedCategory.questions);
      } else {
        setQuestions([]);
      }
    } catch (error) {
      console.error("Error fetching questions:", error);
      Swal.fire({
        title: "Error!",
        text: "Failed to fetch questions",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
    } finally {
      setLoading(false);
    }
  }, [categoryId, categoryName, token]);

  useEffect(() => {
    getQuestionByCategory();
  }, [getQuestionByCategory]);

  const handleClickOutside = useCallback((event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      closeModal();
    }
  }, []);

  const closeModal = () => {
    setModalOpen(false);
    setEditMode(false);
    setSelectedQuestion(null);
    setNewQuestion({
      ...INITIAL_QUESTION_STATE,
      hr: userData?.id,
      category: categoryName,
    });
  };

  useEffect(() => {
    if (modalOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    }
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [modalOpen, handleClickOutside]);

  const handleInputChange = useCallback((e) => {
    const { name, value } = e.target;
    setNewQuestion(prev => ({
      ...prev,
      [name]: value
    }));
  }, []);

  const handleEditQuestion = (question) => {
    setSelectedQuestion(question);
    setNewQuestion({
      ...question,
      category: categoryName,
    });
    setEditMode(true);
    setModalOpen(true);
  };

  const handleDeleteQuestion = async (questionId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
        reverseButtons: true
      });

      if (result.isConfirmed) {
        await axios.delete(
          `${process.env.REACT_APP_BASE_URL}/assessment-questions/${questionId}/`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        await Swal.fire(
          "Deleted!",
          "Question has been deleted.",
          "success",
          {
            customClass: {
              confirmButton: "custom-swal-confirm-button",
            },
          }
        );

        getQuestionByCategory();
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "Failed to delete question",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
    }
  };

  const handleSubmit = async () => {
    const requiredFields = ['question_text', 'option_1', 'option_2', 'option_3', 'option_4', 'correct_option'];
    const missingFields = requiredFields.filter(field => !newQuestion[field]);

    if (missingFields.length > 0) {
      Swal.fire({
        title: "Error!",
        text: "Please fill in all fields",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
      return;
    }

    try {
      if (editMode) {
        console.log("update question", newQuestion);

        await axios.put(
          `${process.env.REACT_APP_BASE_URL}/assessment-questions/${selectedQuestion.id}/`,
          newQuestion,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        await Swal.fire({
          title: "Success!",
          text: "Question updated successfully!",
          icon: "success",
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });
      } else {

        console.log("add question", newQuestion);


        await axios.post(
          `${process.env.REACT_APP_BASE_URL}/assessment-questions/`,
          newQuestion,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        await Swal.fire({
          title: "Success!",
          text: "Question added successfully!",
          icon: "success",
          customClass: {
            confirmButton: "custom-swal-confirm-button",
          },
        });
      }

      closeModal();
      await getQuestionByCategory();

    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.response?.data?.message || "Operation failed",
        icon: "error",
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "custom-swal-confirm-button",
        },
      });
    }
  };

  if (!userData?.id || !token) {
    return <div>Please login to continue</div>;
  }

  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading && (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        )}
        <div className="assessment_question_container">
          {questions.length < 10 && (
            <Alert severity="error" sx={{ mb: 2 }}>
              <AlertTitle>Attention Required</AlertTitle>
              Please add at least {10 - questions.length} more {10 - questions.length === 1 ? 'question' : 'questions'} to complete the assessment.
              Currently you have {questions.length} {questions.length === 1 ? 'question' : 'questions'}.
            </Alert>
          )}
          <div className="assessment_question_title_con">
            <h5 className="mt-2">
              <svg
                width="14"
                height="10"
                viewBox="0 0 14 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ cursor: "pointer" }}
                onClick={() => navigate(-1)}
              >
                <path
                  d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                  fill="#222222"
                />
                <path
                  d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                  fill="#222222"
                />
              </svg>
              &nbsp; {categoryName} Questions
            </h5>
            <button
              onClick={() => setModalOpen(true)}
              className="add-question-btn"
            >
              Add Question
            </button>
          </div>
          {questions.length > 0 ? (
            questions.map((question, index) => (
              <div
                className="assessment_question_sub_container"
                key={question.id || index}
              >
                <div className="question-header">
                  <h6 className="assessment_question">
                    {index + 1}. {question.question_text}
                  </h6>
                  <div className="question-actions">
                    <button
                      className="edit-btn"
                      onClick={() => handleEditQuestion(question)}
                    >
                      Edit
                    </button>
                    <button
                      className="delete-btn"
                      onClick={() => handleDeleteQuestion(question.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <ul className="assessment_answer_list">
                  <li className="assessment_answer">(A) {question.option_1}</li>
                  <li className="assessment_answer">(B) {question.option_2}</li>
                  <li className="assessment_answer">(C) {question.option_3}</li>
                  <li className="assessment_answer">(D) {question.option_4}</li>
                  <li className="assessment_correct_answer">
                    Correct Answer:{" "}
                    {question.correct_option === question.option_1
                      ? "(A)"
                      : question.correct_option === question.option_2
                        ? "(B)"
                        : question.correct_option === question.option_3
                          ? "(C)"
                          : "(D)"}
                  </li>
                </ul>
              </div>
            ))
          ) : (
            <div className="no_question_post_yet">
              No questions available for this category.
            </div>
          )}
        </div>

        {modalOpen && (
          <div className="assessment_modal">
            <div className="assessment_modal_content" ref={modalRef}>
              <h3>{editMode ? "Edit Question" : "Add New Question"}</h3>
              <div className="form-group">
                <label>Question Text</label>
                <textarea
                  name="question_text"
                  placeholder="Enter your question"
                  value={newQuestion.question_text}
                  onChange={handleInputChange}
                />
              </div>

              <div className="options-grid">
                {[1, 2, 3, 4].map((num) => (
                  <div className="form-group" key={num}>
                    <label>Option {num}</label>
                    <input
                      type="text"
                      name={`option_${num}`}
                      placeholder={`Enter option ${num}`}
                      value={newQuestion[`option_${num}`]}
                      onChange={handleInputChange}
                    />
                  </div>
                ))}
              </div>

              <div className="form-group">
                <label>Correct Answer</label>
                <select
                  name="correct_option"
                  value={newQuestion.correct_option}
                  onChange={handleInputChange}
                >
                  <option value="">Select correct answer</option>
                  <option value={newQuestion.option_1}>Option 1</option>
                  <option value={newQuestion.option_2}>Option 2</option>
                  <option value={newQuestion.option_3}>Option 3</option>
                  <option value={newQuestion.option_4}>Option 4</option>
                </select>
              </div>

              <div className="modal-actions">
                <button
                  className="cancel-btn"
                  onClick={closeModal}
                >
                  Cancel
                </button>
                <button
                  className="submit-btn"
                  onClick={handleSubmit}
                >
                  {editMode ? "Update Question" : "Add Question"}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
