import React, { useEffect, useState } from "react";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import filterIcon from "../../../utils/dashboards/filterIcon.svg";
import { Link } from "react-router-dom";
import RightArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import ClipLoader from "react-spinners/ClipLoader";
import { Button, Form, Modal } from "react-bootstrap";
import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";

function Students() {
  const [showModal, setShowModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState("pending");
  const [lateralStudentTableData, setLateralStudentTableData] = useState([]);
  const [changeStudentTableData, setChangeStudentTableData] = useState([]);
  const [filterStudentTableData, setFilterStudentTableData] = useState([]);
  const [campusStudentTableData, setCampusStudentTableData] = useState([]);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const [currentItem, setCurrentItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemParPage = 10;

  const fetchAllStudentData = async () => {
    try {
      setLoading(true);
      const [lateralResponse, campusResponse] = await Promise.all([
        fetch(`${process.env.REACT_APP_BASE_URL}/api/show_student/`, {
          method: "GET",
        }),
        fetch(`${process.env.REACT_APP_BASE_URL}/api/show_student_campus/`, {
          method: "GET",
        }),
      ]);

      if (!lateralResponse.ok) {
        throw new Error(`Error: Lateral API(${lateralResponse.status})`);
      }
      if (!campusResponse.ok) {
        throw new Error(`Error: Campus API(${campusResponse.status})`);
      }

      const [lateralData, campusData] = await Promise.all([
        lateralResponse.json(),
        campusResponse.json(),
      ]);

      setLateralStudentTableData(lateralData);
      setFilterStudentTableData(lateralData);
      setChangeStudentTableData(lateralData);
      setCampusStudentTableData(campusData);
    } catch (error) {
      console.error("Failed to fetch student data:", error);
      toast.error("Failed to fetch student data.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllStudentData();
  }, []);

  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Lateral Wise Student");

  const handleSelectClick = () => {
    setIsActive(!isActive);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    const changeData =
      option === "Campus Wise Student"
        ? campusStudentTableData
        : lateralStudentTableData;
    setFilterStudentTableData(changeData);
    setChangeStudentTableData(changeData);
    setIsActive(false);
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const filteredRequests = (searchQuery) => {
    return changeStudentTableData.filter((student) =>
      student?.name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };
  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFilterStudentTableData(filteredRequests(value));
    setCurrentPage(1);
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemParPage;
    const endIndex = startIndex + itemParPage;
    setCurrentItem(filterStudentTableData.slice(startIndex, endIndex));
  }, [currentPage, filterStudentTableData]);

  const mockErrors = {
    firstName: "First name is required",
    lastName: "",
    collegeName: "",
    email: "Invalid email address",
    password: "",
    password2: "Passwords do not match",
    phoneNumber: "",
  };

  const mockColleges = [
    { college_name: "Harvard University" },
    { college_name: "Stanford University" },
    { college_name: "MIT" },
  ];

  return (
    <>
      <div className="_main_content_inner_screen">
        {loading ? (
          <div className="custom_loader">
            <ClipLoader color="#F38D00" size={50} />
          </div>
        ) : (
          <div className="_main_content_inner_screen_conatiner">
            <div className="student_dashboard_container">
              <div className="campuse_student_request_header">
                <h3>Students Management</h3>
                <div className="search_input_container">
                  <div className="campuse_student_request_search_bar">
                    <img src={searchLogo} alt="search" />
                    <input
                      type="text"
                      placeholder="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </div>

                  <div
                    className={`select_menu_dropdown ${
                      isActive ? "select_active" : ""
                    }`}
                  >
                    <div
                      className="select_btn_dropdown"
                      onClick={handleSelectClick}
                    >
                      <img src={filterIcon} alt="Filter Icon" />
                      <span className="select_dropdown_text">
                        {selectedOption}
                      </span>
                      {isActive ? "▲" : "▼"}
                    </div>

                    {isActive && (
                      <ul className="select_dropdown_options_list">
                        <li
                          className="select_dropdown_option"
                          onClick={() =>
                            handleOptionClick("Campus Wise Student")
                          }
                        >
                          Campus Wise Student
                        </li>
                        <li
                          className="select_dropdown_option"
                          onClick={() =>
                            handleOptionClick("Lateral Wise Student")
                          }
                        >
                          Lateral Wise Student
                        </li>
                      </ul>
                    )}
                  </div>
                  <button
                    className="add_college_managment"
                    onClick={() => setShowModal(true)}
                  >
                    Add Student
                  </button>
                </div>
              </div>
            </div>
            <div className="studente_container">
              {filterStudentTableData.length > 0 ? (
                <table className="candidate-table">
                  <thead>
                    <tr>
                      <th>Student Name</th>
                      <th>Email</th>
                      <th>{selectedOption === "Campus Wise Student"?"College Name":"Experience"}</th>
                      <th>Phone Number</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItem?.map((candidate, index) => (
                      <tr key={index}>
                        <td>{`${candidate.name} ${candidate.student_last_name}`}</td>
                        <td>{candidate.email || "--"}</td>
                        <td>
                          {selectedOption === "Campus Wise Student"
                            ? candidate?.student_campus_clg_name
                            : candidate.studentlateral_profile?.experience ||
                              "--"}
                        </td>
                        <td>{candidate.all_mobno || "--"}</td>
                        <td>
                          {" "}
                          <Link
                            to={`#`}
                            style={{ color: "#f38d00", textDecoration: "none" }}
                          >
                            View <img src={RightArrow} alt="arrow" />
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="no-requests-message">No Data Available</div>
              )}
            </div>
            {filterStudentTableData.length > 10 && (
              <div className="d-flex justify-content-center pagination_custom">
                <Pagination
                  count={Math.ceil(filterStudentTableData.length / itemParPage)}
                  page={currentPage}
                  color="primary"
                  className="mt-3"
                  onChange={handlePageChange}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
        className="modal_admin_request"
      >
        <div className="popup_form_container">
          <Modal.Body>
            <Form autoComplete="off" className="admin_dashboard_modal_form">
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicName"
              >
                <Form.Control
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  autoComplete="new-firstName"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicLastName"
              >
                <Form.Control
                  type="text"
                  name="lastName"
                  placeholder="Last Name"
                  autoComplete="new-lastName"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEntityName"
              >
                <Form.Control
                  type="text"
                  name="collegeName"
                  placeholder="College Name"
                  autoComplete="new-collegeName"
                />
              </Form.Group>
              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicEmail"
              >
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  autoComplete="new-email"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPassword"
              >
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  autoComplete="new-password"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicConfirmPassword"
              >
                <Form.Control
                  type="password"
                  name="password2"
                  placeholder="Confirm Password"
                  autoComplete="new-password2"
                />
              </Form.Group>

              <Form.Group
                className="mb-3 admin_dashboard_modal_field"
                controlId="formBasicPhoneNumber"
              >
                <div></div>
                <Form.Control
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                  className="input_field"
                  maxLength={10}
                  pattern="[0-9]{10}"
                  inputMode="numeric"
                  autoComplete="new-phoneNumber"
                />
              </Form.Group>

              {/* <Button variant="primary" type="submit">
              Submit
            </Button> */}
            </Form>
          </Modal.Body>

          <Modal.Footer>
            <div className="dashboard_request_actions_button_modal">
              <button className="dashborad_request_approve_button">Add</button>
              <button
                className="dashboard_request_reject_button"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            </div>
          </Modal.Footer>
        </div>
      </Modal>
    </>
  );
}

export default Students;
