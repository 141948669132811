import React, { useEffect, useState } from "react";
import "../../../styles/dashboards/campusstudent/campusstudentViewAll.css";
import searchIconDash from "../../../utils/dashboards/searchIconDash.svg";
import filter_icon from "../../../utils/dashboards/Filter.svg";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { PaginationItem, Switch } from "@mui/material";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import rightIconArrow from "../../../utils/landingpage/images/rightIconArrow.svg";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setShowCurrentStudentList } from "../../../redux/reducers/collage_dashboard_reducer";
import { Modal, Button, Form } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import dummy_logo from "../../../utils/landingpage/images/user-icon-dummy.png";

export default function ViewAll() {
  const token = sessionStorage.getItem("accessToken");

  const dispatch = useDispatch();
  const [students, setStudents] = useState([]);
  const [switchStates, setSwitchStates] = useState({});
  const [loadingState, setLoadingState] = useState({});
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [details, setDetails] = useState(null);
  const [error, setError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterYear, setFilterYear] = useState("");
  const [filterDegree, setFilterDegree] = useState("");
  const [loading, setLoading] = useState(false)

  const getUserDetails = async () => {
    try {
      setLoading(true)
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/show_student_campus_by_college/?is_approved=true`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        const fetchedData = response.data;
        setData(fetchedData);

        const initialSwitchStates = {};
        fetchedData.forEach((item) => {
          initialSwitchStates[item.id] = item.isstudent_active;
        });
        setSwitchStates(initialSwitchStates);
        setStudents(response.data);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) { }
    finally {
      setLoading(false)
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleSwitchChange = async (candidateId, event) => {
    console.log("----token", token);
    const isActivated = event.target.checked;
    setLoadingState((prevState) => ({ ...prevState, [candidateId]: true }));

    try {
      if (isActivated) {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/active_deactive_student/${candidateId}/`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success(response.data?.message);
        }
        else {
          toast.warning(response.data?.msg || "Something went wrong");
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/active_deactive_student/${candidateId}/`,
          {
            student_id: candidateId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.status === 200) {
          toast.success(response.data?.message);
        }
        else {
          toast.warning(response.data?.msg || "Something went wrong");
        }

      }

      setSwitchStates((prev) => ({ ...prev, [candidateId]: isActivated }));
      setLoadingState((prevState) => ({ ...prevState, [candidateId]: false }));

    } catch (error) {
      console.error("Error while changing status", error);
      toast.error("Failed to change status");

      setSwitchStates((prev) => ({ ...prev, [candidateId]: !isActivated }));
      setLoadingState((prevState) => ({ ...prevState, [candidateId]: false }));
    }
  };

  const itemsPerPage = 8;
  const label = { inputProps: { "aria-label": "Switch demo" } };

  const uniqueYears = [
    ...new Set(
      students
        .map((student) =>
          student.studentcampus_profile?.qualifications?.map((qualification) =>
            qualification.to_date?.slice(0, 4)
          )
        )
        .filter((year) => year)
        .flat()
    ),
  ];

  const uniqueDegree = [
    ...new Set(
      students
        .map(
          (student) =>
            student.studentcampus_profile?.qualifications
              ?.map((qualification) => qualification.course_name)
              .filter((degree) => degree)
        )
        .flat()
    ),
  ];
  const getDegreeAndYears = (qualifications) => {
    if (!qualifications || qualifications.length === 0) return "";

    const degrees = qualifications
      .map((qualification) => qualification.course_name)
      .join("/");

    const years = qualifications
      .map((qualification) =>
        qualification.to_date ? qualification.to_date?.slice(0, 4) : ""
      )
      .join("/");

    return { degrees, years };
  };

  // const filteredData = students.filter((candidate) => {
  //   const { degrees, years } = getDegreeAndYears(
  //     candidate.studentcampus_profile?.qualifications
  //   );

  //   const courseName = degrees || "";
  //   const toDate = years || "";

  //   return (
  //     ((candidate.name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       (candidate.student_last_name || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
  //       (candidate.unique_student_id || "").toLowerCase().includes(searchTerm.toLowerCase())) &&
  //     (filterYear ? toDate.includes(filterYear) : true) &&
  //     (filterDegree ? courseName.toLowerCase().includes(filterDegree.toLowerCase()) : true)
  //   );
  // });

  const filteredData = students.filter((candidate) => {
    const { degrees, years } = getDegreeAndYears(
      candidate.studentcampus_profile?.qualifications
    );

    const courseName = degrees || "";
    const toDate = years || "";

    const fullName = `${candidate.name || ""} ${candidate.student_last_name || ""}`.toLowerCase();
    const enrolledNumber = (candidate.enrollment_no || "").toLowerCase();

    return (
      (fullName.includes(searchTerm.toLowerCase()) ||
        (candidate.unique_student_id || "").toLowerCase().includes(searchTerm.toLowerCase()) ||
        enrolledNumber.includes(searchTerm.toLowerCase())) &&
      (filterYear ? toDate.includes(filterYear) : true) &&
      (filterDegree ? courseName.toLowerCase().includes(filterDegree.toLowerCase()) : true)
    );
  });

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);
  const currentItems = filteredData?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleViewDetails = async (data) => {
    setDetails(data);
    setShowModal(true);
  };

  return (<>
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        {loading ?
          <div className="custom_loader">
            <ClipLoader color="#F38D00" size={50} />
          </div>
          : (<div className="candidate-page">
            <div className="candidate-page-header">
              <h5>
                <button onClick={() => dispatch(setShowCurrentStudentList(false))}> ← </button> {`Students Data[${students?.length}]`}
              </h5>
              <div className="candidate-header-right gap-4">
                <div className="candidate-search-input">
                  <img src={searchIconDash} alt="Search Icon" />

                  <input
                    type="text"
                    placeholder="Search"
                    className="candidate-search-input-field"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className="filter-container">
                  <button className="filter_img ">
                    <img src={filter_icon} alt="" />
                  </button>
                  <select
                    value={filterYear}
                    onChange={(e) => setFilterYear(e.target.value)}
                    className="filter-dropdown"
                  >
                    <option value="">Year</option>
                    {uniqueYears.map((year) => (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    ))}
                  </select>

                  <select
                    value={filterDegree}
                    onChange={(e) => setFilterDegree(e.target.value)}
                    className="filter-dropdown"
                  >
                    <option value="">Degree</option>
                    {uniqueDegree.map((degree) => (
                      <option key={degree} value={degree}>
                        {degree}
                      </option>
                    ))}
                  </select>

                </div>
              </div>
            </div>

            <div className="candidate-container">
              <table className="candidate-table">
                <thead>
                  <tr>
                    <th>Row</th>
                    <th>Student Name</th>
                    <th>Student ID</th>
                    <th>Enrollement No</th>
                    <th>Degree/Diploma</th>
                    <th>Batch</th>
                    <th className="table_data">Activate/Deactivate</th>
                    <th>Action</th>
                  </tr>
                </thead>

                <tbody>
                  {currentItems?.length > 0 ? (
                    currentItems?.map((candidate, index) => {
                      const qualifications =
                        candidate.studentcampus_profile?.qualifications || [];
                      const { degrees, years } =
                        getDegreeAndYears(qualifications);
                      const courseName = degrees || "-";
                      const toDate = years || "-";

                      return (
                        <tr key={index}>
                          <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                          <td>
                            {candidate.name + " " + candidate.student_last_name}
                          </td>
                          <td>{candidate.unique_student_id}</td>
                          <td>{candidate.enrollment_no || "Not Added"}</td>
                          <td>{courseName}</td> <td>{toDate}</td>{" "}
                          <td key={candidate.id} className="table_data">
                            <Switch
                              {...label}
                              checked={switchStates[candidate.id] || false}
                              onChange={(event) =>
                                handleSwitchChange(candidate.id, event)}
                              // onChange={fetchData(candidate.id)}
                              name="toggle-status"
                            />
                            {loadingState[candidate.id] && (
                              <div className="spinner"></div>)}
                          </td>
                          <td onClick={() => handleViewDetails(candidate)}>
                            <svg style={{ cursor: "pointer" }} width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M12.3058 8.00447C12.3058 9.6794 10.9523 11.0329 9.27742 11.0329C7.6025 11.0329 6.24902 9.6794 6.24902 8.00447C6.24902 6.32955 7.6025 4.97607 9.27742 4.97607C10.9523 4.97607 12.3058 6.32955 12.3058 8.00447Z" stroke="#F38D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                              <path d="M9.27734 15C12.2634 15 15.0465 13.2405 16.9837 10.1952C17.745 9.00242 17.745 6.99758 16.9837 5.80483C15.0465 2.75952 12.2634 1 9.27734 1C6.29124 1 3.50816 2.75952 1.571 5.80483C0.809668 6.99758 0.809668 9.00242 1.571 10.1952C3.50816 13.2405 6.29124 15 9.27734 15Z" stroke="#F38D00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            </svg> View
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="7" className="no-data">
                        No candidates found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            <div className="pagination-controls">
              <Stack spacing={2} alignItems="center">
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                  siblingCount={1}
                  boundaryCount={1}
                  renderItem={(item) => (
                    <PaginationItem
                      components={{
                        previous: () => <img src={leftArrowIcon} alt="prev" />,
                        next: () => <img src={rightIconArrow} alt="next" />,
                      }}
                      {...item}
                    />
                  )}
                />
              </Stack>
            </div>
          </div>)}
      </div>
    </div>
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      centered
      className="modal_admin_request"
    >
      <div className="popup_form_container collage_popup_form_container">
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            zIndex: 1051,
            padding: "9px",
            border: "2px solid red",
            borderRadius: "50%"
          }}
          onClick={() => setShowModal(false)}
        ></button>

        <Modal.Body>
          {details ? (
            <>
              <div
                className="text-center"
                style={{ padding: "1rem", fontSize: "2rem" }}
              >
                Student Details
              </div>
              <div className="collage_popup_form_content_div">
                <img
                  alt="..."
                  src={details?.studentcampus_profile?.student_photo_url || dummy_logo}
                />
                <h6>
                  Student Name: <span>{details?.name} {details?.student_last_name}</span>
                </h6>
                <h6>
                  Enrollement No: <span>{details?.enrollment_no || "Not Added"}</span>
                </h6>
                <h6>
                  Student Id: <span>{details?.unique_student_id || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Is Active: <span>{details?.isstudent_active === true ? "Yes" : "No"}</span>
                </h6>
                <h6>
                  Email Address: <span>{details?.email}</span>
                </h6>
                <h6>
                  Phone No: <span>{details?.studentcampus_profile?.phone_no || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Gender: <span>{details?.studentcampus_profile?.gender || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Date of Birth: <span>{details?.studentcampus_profile?.dob || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Current Location: <span>{details?.studentcampus_profile?.current_location || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Preferred Location: <span>{details?.studentcampus_profile?.preferred_location || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Annual Salary: <span>{details?.studentcampus_profile?.annual_salary || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Skill Name:
                  <span>
                    {details?.studentcampus_profile?.skill_name &&
                      details?.studentcampus_profile?.skill_name.length > 0
                      ? details?.studentcampus_profile?.skill_name?.map((skill, index) => (
                        <span key={index}>
                          {skill.skill_name}
                          {index !== details.studentcampus_profile.skill_name.length - 1
                            ? ", "
                            : ""}
                        </span>
                      ))
                      : " Not Mentioned"}
                  </span>
                </h6>
                <h6>
                  About: <span>{details?.studentcampus_profile?.about || "Not Mentioned"}</span>
                </h6>
                <h6>
                  Resume:
                  <span>
                    {details.studentcampus_profile?.student_resume_url ? (
                      <a
                        href={`${details.studentcampus_profile.student_resume_url}`}
                        download
                        target=".."
                      >
                        <Button variant="warning">Downlaod</Button>
                      </a>
                    ) : (
                      " Not Updated"
                    )}
                  </span>
                </h6>
              </div>
            </>
          ) : (
            <p>Loading...</p>
          )}
        </Modal.Body>
      </div>
    </Modal>
  </>);
}