import "../../../styles/dashboards/campusecandidaterequest.css";
import "../../../styles/admin/adminRequest.css";
import React, { useState, useEffect } from "react";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "@mui/material";
import searchLogo from "../../../utils/dashboards/searchIconDash.svg";
import RightArrow from "../../../utils/landingpage/images/rightarroworange.svg";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

function HrManagerCampuses() {
  const [finalCardData, setFinalCardData] = useState([]);
  const [filterCardData, setFilterCardData] = useState([]);
  const [currentItem, setCurrentItem] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);

  const itemPerPage = 10;

  const fetchCollegeData = async () => {
    setLoading(true);

    try {
      const token = sessionStorage.getItem("accessToken");
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/api/approved-colleges-list/`,
        {
          method: "GET",
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (!response.ok) {
        throw new Error(`Error: ${response.status}`);
      }
      const result = await response.json();
      const sortedResult = result.sort((a, b) =>
        a.college_name.localeCompare(b.college_name)
      );
      setFilterCardData(sortedResult);
      setFinalCardData(sortedResult);
    } catch (error) {
      console.error("Failed to fetch college data:", error);
      toast.error("Failed to fetch requests.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCollegeData();
  }, []);

  useEffect(() => {
    const startIndex = (currentPage - 1) * itemPerPage;
    const endIndex = startIndex + itemPerPage;
    setCurrentItem(finalCardData.slice(startIndex, endIndex));
  }, [currentPage, finalCardData]);

  const filteredRequests = (searchQuery) => {
    return filterCardData.filter((user) =>
      user?.college_name.toLowerCase().startsWith(searchQuery.toLowerCase())
    );
  };

  const handleSearchChange = ({ target: { value } }) => {
    setSearchQuery(value);
    setFinalCardData(filteredRequests(value));
    setCurrentPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <>
      <div className="_main_content_inner_screen">
        <div className="_main_content_inner_screen_conatiner">
          <div className="campuse_student_request_container">
            <div className="campuse_student_request_header">
              <h3>College Management</h3>
              <div className="search_input_container">
                <div className="campuse_student_request_search_bar">
                  <img src={searchLogo} alt="search" />
                  <input
                    type="text"
                    placeholder="Search"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                </div>
              </div>
            </div>
            <div className="campuse_student_request_body">
              <div
                className="campuse_student_request_sub_container"
                style={{ marginTop: "13px" }}
              >
                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {finalCardData.length > 0 ? (
                      currentItem?.map((item, key) => (
                        <div key={key} className="dashboard_request_list">
                          <div className="dashboard_request_list_card">
                            <div className="dashborad_request_logo_title_container">
                              <img
                                src={"https://via.placeholder.com/100"}
                                alt="college_logo"
                                className="dashboard_request_company_logo"
                              />
                              <div>
                                <h4>{item?.college_name}</h4>
                                <p>{item?.email}</p>
                              </div>
                            </div>
                            <Link
                              to={`/dashboard/sidebar-page/:roleName/college-details/${item.id}`}
                              style={{
                                color: "#f38d00",
                                textDecoration: "none",
                              }}
                            >
                              View Details <img src={RightArrow} alt="arrow" />
                            </Link>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="no-requests-message">
                        No requests found.
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {finalCardData.length > 10 && (
          <div className="d-flex justify-content-center pagination_custom">
            <Pagination
              count={Math.ceil(finalCardData.length / itemPerPage)}
              page={currentPage}
              color="primary"
              className="mt-3"
              onChange={handlePageChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default HrManagerCampuses;
