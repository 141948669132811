import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "../../../styles/dashboards/clgdashboard/campusmanagerstudent.css";
import dummy_logo from "../../../utils/landingpage/images/dummy_logo.png";
import leftArrowIcon from "../../../utils/landingpage/images/leftArrowIcon.svg";
import jobsharingw from "../../../utils/dashboards/jobsharingw.svg";
import jobsharingm from "../../../utils/dashboards/jobsharingm.svg";
import jobsharinge from "../../../utils/dashboards/jobsharinge.svg";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Loader from "react-spinners/ClipLoader";
import { useNavigate } from "react-router-dom";
import jsPDF from "jspdf";
import "jspdf-autotable";

const CampusJobDetails = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [url, setUrl] = useState("");
  const [jobId, setJobId] = useState(null);
  const [isToken, setIsToken] = useState(false);
  const [token, setToken] = useState(null);
  const [isLogin, setIsLogin] = useState(null);
  const [jobDetails, setDetails] = useState([]);
  const [similarJobData, setSimilarJobData] = useState();
  const [profileCompletion, setProfileCompletion] = useState('90');
  const [loading, setLoading] = useState(false);
  const [globalLoading, setGlobalLoading] = useState(false)

  const [skills, setSkills] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const UserPercenatageValue = useSelector((state) => state.studentProfilePercentage.studentProfilePercentageValue)

  const handleSend = () => {
    setEmail("");
    setUrl("");
    closeModal();
  };

  useEffect(() => {
    setJobId(id);

    const newSession = sessionStorage.getItem("accessToken");
    setToken(newSession);
  }, [id]);

  useEffect(() => {
    if (jobId || token) {
      const userToken = token;

      setGlobalLoading(true)

      axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/campus-jobs/${jobId}/`,

          {
            headers: {
              Authorization: userToken && `Bearer ${userToken}`,
            },
          }
        )
        .then((response) => {
          setDetails(response.data);
          setSkills(response.data.job_skills);
          setGlobalLoading(false)
        })
        .catch((error) => {
          setGlobalLoading(false)
          toast.error("Job ID is not available");
        });
    } else {
      console.error("Job ID is not available, skipping API call.");
    }
  }, [jobId, token]);

  const handleApplyJob = async (jobId) => {

    if (UserPercenatageValue < 91) {
      return;
    }

    setLoading(true)

    if (UserPercenatageValue < 90) {
      Swal.fire({
        title: 'Profile Incomplete',
        text: 'Complete your profile up to 90% before applying.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Complete Now',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/dashboard/sidebar-page/student/student-profile');
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.close();
        }
      });
      return;
    }

    if (jobId) {
      try {
        const accessToken = sessionStorage.getItem('accessToken');

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/jobs/${jobId}/apply/`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${accessToken}`
            }
          }
        );
        setLoading(false)
        toast.success('Application submitted successfully!');
      } catch (error) {
        setLoading(false)
        const errorMessage = error.response
          ? error.response.data.detail || 'Application failed. Please try again.'
          : error.message;

        toast.error(errorMessage);
      }
    } else {
      toast.info('Job ID is missing.');
      setLoading(false)
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-US", options).format(date).toUpperCase();
  };

  const handleDownloadPDF = () => {
    if (!jobDetails) {
      toast.error("No job details available to download.");
      return;
    }

    const doc = new jsPDF();

    doc.setFontSize(18);
    doc.text(`Job Details ( ${jobDetails.title} )`, 14, 20);

    const details = [
      ["Title", jobDetails.title || "Not Mentioned"],
      ["Company Name", jobDetails.company_name || "Not Mentioned"],
      ["Description", jobDetails.description || "N/A"],
      ["Role", jobDetails.title || "N/A"],
      ["Status", jobDetails.status || "N/A"],
      ["Industry Type", jobDetails.job_category || "N/A"],
      ["Employment Type", jobDetails.type || "N/A"],
      ["Education", jobDetails.qualification || "N/A"],
      ["Vacancy", jobDetails.vacancy || "N/A"],
      ["Job Posted", formatDate(jobDetails.created_at) || "N/A"],
      ["Job Expiry", formatDate(jobDetails.end_date) || "N/A"],
      [
        "Salary Range",
        jobDetails.min_salary && jobDetails.max_salary
          ? `${parseFloat(jobDetails.min_salary)} - ${parseFloat(jobDetails.max_salary)} LPA`
          : "N/A",
      ],
      [
        "Experience",
        jobDetails.min_experience && jobDetails.max_experience
          ? `${parseFloat(jobDetails.min_experience)} - ${parseFloat(jobDetails.max_experience)} Years`
          : "N/A",
      ],
      ["Location", jobDetails.job_location || "N/A"],
      ["Key Skills", skills.length > 0 ? skills.map((s) => s.skill_name).join(", ") : "Not Mentioned"],
      ["Job Responsibility", jobDetails.rensponsibility || "Not Mentioned"],
      ["HR Email", jobDetails.company || "N/A"],
    ];

    doc.autoTable({
      startY: 30,
      head: [["Field", "Details"]],
      body: details,
    });

    doc.save("job-details.pdf");
  };


  return (
    <>
      <div className="_main_content_inner_screen">
        {globalLoading ? (
          <div className="custom_loader">
            <Loader color="#F38D00" size={50} />
          </div>
        ) : (<div className="_main_content_inner_screen_container">
          <div className="job-detail-page">
            <div className="job-detail-header">
              <button
                className="job-detail-back-button"
                onClick={() => window.history.back()}
              >
                <img src={leftArrowIcon} alt="Back" />
              </button>
              <h5>Job Details</h5>
              {!location.pathname.includes('student/job-details') && (<div className="job-detail-Download-Pdf">
                <button onClick={handleDownloadPDF}>Download Pdf</button>
              </div>)}
            </div>
            <div className="job-detail-summary">
              <div className="job-left">
                <img
                  src={jobDetails.company_profile?.company_logo_url || dummy_logo}
                  alt='logo'
                  className="company-logo"
                  style={{ marginRight: "13px" }}
                />
                <div className="job-info">
                  <div className="d-flex align-items-center gap-4">
                    <h6>{jobDetails.title || 'Not Mentioned'}</h6>
                    {jobDetails?.status === "LIVE" ? (<button className="job_list_job_active">Live</button>) : (<button className="job_list_job_active job_list_job_closed">Expired</button>)}
                  </div>
                  <p>{jobDetails.company_name || 'Not Mentioned'}</p>
                </div>
              </div>
              <div className="job-detail-right">
                <span>
                  <h6>Posted date</h6>{jobDetails?.created_at ? formatDate(jobDetails.created_at) : "N/A"}
                </span>
              </div>
              {location.pathname.includes('student/job-details') && (<div>
                <div className="job-detail-Download-Pdf">
                  <button disabled={jobDetails?.status !== "LIVE"} className={jobDetails?.status !== "LIVE" ? 'job_apply_disabled' : ""} onClick={() => handleApplyJob(jobDetails.id)}> {loading ? <div className="spinner"></div> : "Apply Job"} </button>
                </div>
              </div>
              )}
            </div>
            <div className="job-detail-description">
              <h5>Job Description</h5>
              <p>{jobDetails.description || "N/A"}</p>
              <h6>
                Role: <p>{jobDetails.title || "N/A"}</p>
              </h6>
              <h6>
                Status: <p>{jobDetails.status || "N/A"}</p>
              </h6>
              <h6>
                Job Category: <p>{jobDetails.job_category_name || "N/A"}</p>
              </h6>
              <h6>
                Employment Type: <p>{jobDetails.type || "N/A"}</p>
              </h6>
              <h6>
                Education: <p>{jobDetails.qualification || "N/A"}</p>
              </h6>
              <h6>
                Salary Range:
                <p>
                  {jobDetails.min_salary
                    ? `${parseFloat(jobDetails.min_salary)}`
                    : "N/A"}{" "}
                  -{" "}
                  {jobDetails.max_salary
                    ? `${parseFloat(jobDetails.max_salary)}`
                    : "N/A"}{" "}
                  LPA
                </p>
              </h6>
              <h6>
                Experience:
                <p>
                  {jobDetails.min_salary
                    ? `${parseFloat(jobDetails.min_experience)}`
                    : "N/A"}{" "}
                  -{" "}
                  {jobDetails.max_salary
                    ? `${parseFloat(jobDetails.max_experience)}`
                    : "N/A"}{" "}
                  Year's
                </p>
              </h6>
              <h6>
                Vacancy: <p>{jobDetails.vacancy || "N/A"}</p>
              </h6>
              <h6>
                Job Expiry: <p>{jobDetails?.end_date ? formatDate(jobDetails.end_date) : "N/A"}</p>
              </h6>
              <h6>
                Location: <p>{jobDetails.job_location || "N/A"}</p>
              </h6>
              <h6>
                Key Skills:
                {skills != "" ? skills?.map((skill) => (
                  <p key={skill.id}>{skill.skill_name || "N/A"},</p>
                )) : <p > Not Mentioned </p>}
              </h6>
              <h6>
              Job Responsibilities: <p>{jobDetails.rensponsibility || "Not Mentioned"}</p>
              </h6>
            </div>
            <div className="job-detail-company">
              <h5>About Company</h5>
              <p>
                {jobDetails.company_name || "N/A"}, our mission is to empower
                every person and every organization on the planet to achieve
                more. Our mission is grounded in both the world in which we live
                and the future we strive to create.
              </p>
              <p>HR Email - {jobDetails?.company} </p>
            </div>
            {/* {!location.pathname.includes('student/job-details') && (<div className="job-detail-share-button">
              <button onClick={openModal}>Share Job</button>
            </div>)} */}
          </div>
          {isModalOpen && (
            <div className="job-share-modal-overlay">
              <div className="job-share-modal-content">
                <h5>Job Sharing</h5>
                <div className="job-sharing-icon">
                  <img src={jobsharingw} alt="sharing" />
                  <img src={jobsharingm} alt="sharing" />
                  <img src={jobsharinge} alt="sharing" />
                </div>
                <p>Share Job Opportunity</p>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                />
                <input
                  type="text"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                  placeholder="Job URL"
                />
                <div>
                  <button onClick={handleSend}>Send Email</button>
                </div>
              </div>
            </div>
          )}
        </div>)}
      </div>
    </>
  );
};

export default CampusJobDetails;