import axios from "axios";
import React, { useEffect, useState, useCallback, useMemo, memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/dashboards/hrdashboard/subhr.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, ButtonGroup, IconButton, Menu, MenuItem, Pagination } from "@mui/material";
// import threedot from "../../../utils/landingpage/images/three-dots-svgrepo-com.svg";

const JobTable = memo(({ jobs, loading }) => {
  if (loading) return null;
  
  return (
    <table>
      <thead className="sub_hr_userlist_job_listing_table_head">
        <tr>
          <th>Job Title</th>
          <th>Created Date</th>
          <th>End Date</th>
          <th>Vacancy</th>
          <th>Job Type</th>
        </tr>
      </thead>
      <tbody>
        {jobs.map((job) => (
          <tr key={job.job_id}>
            <td>{job.title}</td>
            <td>{new Date(job.created_at).toLocaleDateString()}</td>
            <td>{new Date(job.end_date).toLocaleDateString()}</td>
            <td>{job.vacancy}</td>
            <td>{job.job_audience}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
});

const FilterButtons = memo(({ jobFilter, setJobFilter, totalJobs }) => {
  return (
    <div className="d-flex px-5 justify-content-between align-items-center mb-3">
      <h6>Job Listings ({totalJobs})</h6>
      <div>
        <ButtonGroup 
          variant="outlined" 
          size="small" 
          sx={{ 
            gap: '10px',
            '& .MuiButton-root': { 
              borderColor: '#f38d00',
              borderWidth: '2px',
              borderRadius: '25px !important',
              fontWeight: 'bold',
              transition: 'all 0.3s ease',
              '&:hover': {
                borderColor: '#f38d00',
                borderWidth: '2px'
              }
            },
            '& .MuiButtonGroup-grouped:not(:last-of-type)': {
              borderRight: 'none',
            },
          }}
        >
          <Button
            onClick={() => setJobFilter('all')}
            variant={jobFilter === 'all' ? 'contained' : 'outlined'}
            sx={{
              color: jobFilter === 'all' ? '#fff' : '#f38d00',
              fontWeight: 'bold',
              fontSize: '16px',
              padding: '5px 12px',
              backgroundColor: jobFilter === 'all' ? '#f38d00' : 'transparent',
              '&:hover': {
                backgroundColor: jobFilter === 'all' ? '#f38d00' : 'transparent',
                color: jobFilter === 'all' ? '#fff' : '#f38d00',
              },
            }}
          >
            All
          </Button>
          <Button
            onClick={() => setJobFilter('campus')}
            variant={jobFilter === 'campus' ? 'contained' : 'outlined'}
            sx={{
              color: jobFilter === 'campus' ? '#fff' : '#f38d00',
              fontWeight: 'bold',
              fontSize: '16px',
              padding: '5px 12px',
              backgroundColor: jobFilter === 'campus' ? '#f38d00' : 'transparent',
              '&:hover': {
                backgroundColor: jobFilter === 'campus' ? '#f38d00' : 'transparent',
                color: jobFilter === 'campus' ? '#fff' : '#f38d00',
              },
            }}
          >
            Campus
          </Button>
          <Button
            onClick={() => setJobFilter('lateral')}
            variant={jobFilter === 'lateral' ? 'contained' : 'outlined'}
            sx={{
              color: jobFilter === 'lateral' ? '#fff' : '#f38d00',
              fontWeight: 'bold',
              fontSize: '16px',
              padding: '5px 12px',
              backgroundColor: jobFilter === 'lateral' ? '#f38d00' : 'transparent',
              '&:hover': {
                backgroundColor: jobFilter === 'lateral' ? '#f38d00' : 'transparent',
                color: jobFilter === 'lateral' ? '#fff' : '#f38d00',
              },
            }}
          >
            Lateral
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
});

function ViewSubUser() {
  const ITEM_HEIGHT = 48;
  const [anchorEls, setAnchorEls] = useState({});
  const { subUserId } = useParams();
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const token = sessionStorage.getItem("accessToken");
  const base_Url = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [jobData, setJobData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const jobsPerPage = 4;
  const [jobFilter, setJobFilter] = useState('all');
  const [totalJobs, setTotalJobs] = useState(0);

  const handleClick = (event, userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: event.currentTarget,
    }));
  };

  useEffect(() => {
    getSubUserDetails();
  }, [subUserId]);

  const getSubUserDetails = async () => {
    try {
      const response = await fetch(
        `${base_Url}/api/details_subuser/${subUserId}/`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setUserData(data);
    } catch (error) {
      console.error("Error fetching sub-user details:", error);
    } finally {
      setLoading(false);
    }
  };

  const getSubUserJobs = useCallback(async (filter = 'all') => {
    try {
      setLoading(true);
      let url = `${base_Url}/api/subusers_jobs/?subuser_id=${subUserId}`;
      if (filter !== 'all') {
        url += `&&job_audience=${filter}`;
      }

      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      setJobData(data.jobs || []);
      setTotalJobs(data.total_jobs_posted || 0);
    } catch (error) {
      console.error("Error fetching sub-user jobs:", error);
    } finally {
      setLoading(false);
    }
  }, [base_Url, subUserId, token]);

  const currentJobs = useMemo(() => {
    if (!jobData) return [];
    const startIndex = (currentPage - 1) * jobsPerPage;
    const endIndex = startIndex + jobsPerPage;
    return jobData.slice(startIndex, endIndex);
  }, [jobData, currentPage, jobsPerPage]);

  const handleClose = (userId) => {
    setAnchorEls((prevState) => ({
      ...prevState,
      [userId]: null,
    }));
  };

  const formik = useFormik({
    initialValues: {
      name: userData?.name || "",
      subuser_lastname: userData?.subuser_lastname || "",
      subuser_branchname: userData?.subuser_branchname || "",
      email: userData?.email || "",
      all_mobno: userData?.all_mobno || "",
      password: "", // Add password and confirm password if needed
      password2: "",
    },
    enableReinitialize: true, // This ensures formik re-initializes when userData changes
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const res = await axios.put(
          `${base_Url}/api/update_subuser/${subUserId}/`,
          values,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );

        if (res.status === 200) toast.success("Sub-user updated successfully!");

        setIsEditing(false);
        getSubUserDetails();
      } catch (error) {
        console.error("Error updating sub-user:", error);
      } finally {
        setLoading(false);
      }
    },
  });

  useEffect(() => {
    getSubUserJobs(jobFilter);
  }, [jobFilter, subUserId]);


  if (loading) {
    return (
      <div className="_main_content_inner_screen">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </div>
    );
  }

  if (!userData) {
    return <div>No user details available.</div>;
  }

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };


  return (
    <div className="_main_content_inner_screen">
      <div className="_main_content_inner_screen_conatiner">
        <div className="sub_hr_container">
          <h6>
            <svg
              width="14"
              height="10"
              viewBox="0 0 14 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{ cursor: "pointer" }}
              onClick={() => navigate(-1)}
            >
              <path
                d="M4.99835 10C5.28547 10 5.57258 9.89422 5.79926 9.66755C6.23749 9.22932 6.23749 8.50397 5.79926 8.06573L2.73163 4.99811L5.79926 1.93049C6.23749 1.49225 6.23749 0.766906 5.79926 0.328674C5.36102 -0.109558 4.63567 -0.109558 4.19744 0.328674L0.328912 4.1972C-0.10932 4.63544 -0.10932 5.36079 0.328912 5.79902L4.19744 9.66755C4.409 9.89422 4.71123 10 4.99835 10Z"
                fill="#222222"
              />
              <path
                d="M2.35753 6.1322H12.4582C12.8654 6.1322 13.2031 5.61841 13.2031 4.99884C13.2031 4.37927 12.8654 3.86548 12.4582 3.86548H2.35753C1.95033 3.86548 1.61265 4.37927 1.61265 4.99884C1.61265 5.61841 1.95033 6.1322 2.35753 6.1322Z"
                fill="#222222"
              />
            </svg>{" "}
            &nbsp; Sub User Details
          </h6>

          {isEditing ? (
            <div>
              <div className="float-end p-3">
                <button
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  className="edit_btn"
                >
                  {isEditing ? "Cancel Edit" : "Edit"}
                </button>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="subuser_input_container">
                  <div className="subuser_both_input">
                    <div className="subuser_input_left">
                      <input
                        type="text"
                        name="name"
                        placeholder="First Name"
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <span className="error-message">
                          {formik.errors.name}
                        </span>
                      )}

                      <input
                        type="text"
                        name="subuser_branchname"
                        placeholder="Branch Name"
                        value={formik.values.subuser_branchname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.subuser_branchname &&
                        formik.errors.subuser_branchname && (
                          <span className="error-message">
                            {formik.errors.subuser_branchname}
                          </span>
                        )}
                      <input
                        type="text"
                        name="all_mobno"
                        placeholder="Contact Number"
                        value={formik.values.all_mobno}
                        maxLength={10}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.all_mobno && formik.errors.all_mobno && (
                        <span className="error-message">
                          {formik.errors.all_mobno}
                        </span>
                      )}
                    </div>
                    <div className="subuser_input_right">
                      <input
                        type="text"
                        name="subuser_lastname"
                        placeholder="Last Name"
                        value={formik.values.subuser_lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.subuser_lastname &&
                        formik.errors.subuser_lastname && (
                          <span className="error-message">
                            {formik.errors.subuser_lastname}
                          </span>
                        )}
                      <input
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <span className="error-message">
                          {formik.errors.email}
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="subuser_buttons">
                    <button type="submit" disabled={loading}>
                      {loading ? "Updating..." : "Update"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          ) : (
            <div className="subuser_input_container">
              <div className="float-end">
                <button
                  type="button"
                  onClick={() => setIsEditing(!isEditing)}
                  className="edit_btn"
                >
                  {isEditing ? "Cancel Edit" : "Edit"}
                </button>
              </div>
              <div className="subuser_both_input_card">
                <div>
                  <div>
                    <label>Full Name</label>
                    <input
                      type="text"
                      value={`${userData.name} ${userData.subuser_lastname}`}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                  <div>
                    <label>Email Address</label>
                    <input
                      type="email"
                      value={userData.email}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                </div>
                <div>
                  <div>
                    <label>Contact Number</label>
                    <input
                      type="text"
                      value={userData.all_mobno}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                  <div>
                    <label>Branch Name</label>
                    <input
                      type="text"
                      value={userData.subuser_branchname}
                      readOnly
                      className="view-mode"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="sub_hr_userlist_job_listing_table">
          <FilterButtons jobFilter={jobFilter} setJobFilter={setJobFilter} totalJobs={totalJobs} />
          <div>
            {jobData.length === 0 ? (
              <p>No job posts found</p>
            ) : (
              <JobTable jobs={currentJobs} loading={loading} />
            )}
          </div>
          <div style={{ background: "#fff" }}>
            {jobData.length > jobsPerPage && (
              <div className="d-flex justify-content-center pagination_custom p-2">
                <Pagination
                  count={Math.ceil(jobData.length / jobsPerPage)}
                  page={currentPage}
                  onChange={handlePageChange}
                  color="primary"
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewSubUser;
